import { SerializedStyles } from '@emotion/core'
import { ClassNamesArg, Interpolation } from 'emotion'

/**
 * Due to React 18 Upgrade and emotion upgrades,
 * We need to convert serialised styles to a string
 * for the purposes of classNames
 *
 * @param css
 * @param cx
 * @param serializedStyles
 * @returns
 */

export const convertSerializedStylesToString = (
  css: {
    (template: TemplateStringsArray, ...args: Interpolation[]): string
    (...args: Interpolation[]): string
  },
  cx: { (...args: ClassNamesArg[]): string; (arg0: unknown): unknown },
  serializedStyles: SerializedStyles | SerializedStyles[]
): string => {
  if (Array.isArray(serializedStyles)) {
    const styles = serializedStyles.reduce(
      (acc, cur) => `${`${acc}; \n ${cur.styles}`}`,
      ''
    )

    return cx(
      css`
        ${styles}
      `
    )
  }

  return cx(
    css`
      ${serializedStyles.styles}
    `
  )
}
