import React, { VoidFunctionComponent } from 'react'

import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg/Svg'

const Path: VoidFunctionComponent<{ strokeWidth?: number }> = ({
  strokeWidth,
}) => (
  <g fill="none" stroke="currentColor" strokeWidth={strokeWidth}>
    <path
      d="M2 18.4298L18.53 2.25977"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.87988 2.34961L18.6199 18.3496"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </g>
)

export const CrossIcon: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 20 20" {...props}>
    <Path />
  </Svg>
)

export const ThickCrossIcon: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 20 20" {...props}>
    <Path strokeWidth={1.5} />
  </Svg>
)
