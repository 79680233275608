import { Property } from 'csstype'
import { CSSPropMapper, ResponsiveProps } from '../types'

export type GridChildProps = ResponsiveProps<{
  gridRow?: Property.GridRow
  gridColumn?: Property.GridColumn
  gridArea?: Property.GridArea
  gridRowStart?: Property.GridRowStart
  gridRowEnd?: Property.GridRowEnd
}>

export const gridChild: CSSPropMapper<GridChildProps> = {
  gridRow: { property: 'gridRow' },
  gridColumn: { property: 'gridColumn' },
  gridArea: { property: 'gridArea' },
  gridRowStart: { property: 'gridRowStart' },
  gridRowEnd: { property: 'gridRowEnd' },
}
