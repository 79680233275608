import { MiddlewareStack } from 'spraypaint'
import { ApplicationRecord } from '@mindfulchefuk/api-client'
import errorHandler from '@mindfulchefuk/utils/errorHandler'

class APIError extends Error {
  constructor({ title, detail }) {
    super()

    let message = title
    if (detail) {
      // Sometimes errors have more detail
      // In this case we log it as a single error message
      message = `${title}\n${detail}`
    }

    this.message = message
  }
}

const middlewareStack = new MiddlewareStack()

middlewareStack.afterFilters.push(async (response, json) => {
  // Wrap in a try/catch because this middleware should never throw
  // If it throws then API requests will silently fail where they
  // are not handled in a try catch
  try {
    // Only log errors on a a bad request
    if (response.ok) {
      return
    }

    // json represents the fetch requests resolved json stream
    // The API will return ether an array of errors or an object
    // with an array of errors contained within the `errors` key
    const errors = Array.isArray(json) ? json : json?.errors || []

    // If we have a failed request with no errors in the response body just bail
    if (!errors.length) {
      return
    }

    errors.forEach((error) => {
      // Log each error individually within bugsnag
      // so they can be silenced where appropriate,
      // for example shop orders will 422 if created after 10am
      // We should log this but silence it

      const { code, status, title, detail } = error
      const { pathname, host } = new URL(response.url)
      const metaData = { pathname, host, code, status, title, detail }

      errorHandler(new APIError(error), {
        suppress: true,
        metaData,
      })
    })
  } catch (e) {
    // Just in case we have some kind of un-accounted for error
    // catch it so we don't harm the application and log it so we can fix it
    errorHandler(e, { suppress: true })
  }
})

ApplicationRecord.middlewareStack = middlewareStack
