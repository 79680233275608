import { Property } from 'csstype'
import { CSSPropMapper, ResponsiveProps } from '../types'

export type AlignmentProps = ResponsiveProps<{
  alignItems?: Property.AlignItems
  alignContent?: Property.AlignContent
  justifyItems?: Property.JustifyContent
  justifyContent?: Property.JustifyContent
  placeItems?: Property.PlaceItems

  align?: Property.AlignItems
  justify?: Property.JustifyContent
  place?: Property.PlaceItems
}>

const mapper: CSSPropMapper<AlignmentProps> = {
  alignItems: { property: 'alignItems' },
  alignContent: { property: 'alignContent' },
  justifyItems: { property: 'justifyItems' },
  justifyContent: { property: 'justifyContent' },
  placeItems: { property: 'placeItems' },
}

export const alignment: CSSPropMapper<AlignmentProps> = {
  ...mapper,
  align: mapper.alignItems,
  justify: mapper.justifyContent,
  place: mapper.placeItems,
}
