import React, { VoidFunctionComponent, InputHTMLAttributes } from 'react'
import { Box } from '@mindfulchefuk/design-system/layout/Box'
import { HiddenInput } from '@mindfulchefuk/design-system/Forms/form.styles'
import { CheckboxIcon } from './CheckboxIcon'

export const CheckboxControl: VoidFunctionComponent<
  InputHTMLAttributes<HTMLInputElement>
> = (props) => {
  const { checked } = props

  return (
    <Box position="relative">
      <HiddenInput {...props} type="checkbox" />
      <CheckboxIcon checked={checked} />
    </Box>
  )
}
