import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { getBasketShopOrder } from '@mindfulchefuk/query/ShopOrders/services'
import { ShopOrder } from '@mindfulchefuk/query/ShopOrders/interfaces'

export const useQueryBasketShopOrder = (
  customerId: string,
  options?: UseQueryOptions<ShopOrder>
) => {
  return useQuery({
    queryKey: ['shop-orders', 'basket', { customerId }],
    queryFn: () => getBasketShopOrder({ customerId }),
    ...options,
  })
}
