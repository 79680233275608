import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import {
  ShopOrder,
  ShopOrders,
  ShopOrderStatus,
} from '@mindfulchefuk/query/ShopOrders/interfaces'
import { getShopOrders } from '@mindfulchefuk/query/ShopOrders/services/getShopOrders'
import { JsonapiError } from 'spraypaint'
import { ISODate } from '@mindfulchefuk/lib/toISODateFormat'

export const useQueryShopOrders = (
  { customerId, fromDate, toDate }: Params,
  { enabled = true, ...options }: UseQueryOptions<ShopOrder[]> = {}
) => {
  return useQuery<ShopOrders, { response: JsonapiError }>({
    queryKey: ['shop-orders', 'list', { customerId, fromDate, toDate }],
    queryFn: () => getShopOrders({ customerId, fromDate, toDate }),
    ...options,
    enabled: enabled && !!fromDate && !!toDate && !!customerId,
  })
}

export const selectCompletedOrders = (data: ShopOrder[]) =>
  data.filter((order) => order.status === ShopOrderStatus.COMPLETED)

interface Params {
  customerId: string
  fromDate?: ISODate
  toDate?: ISODate
}
