import React, { VoidFunctionComponent, ReactElement } from 'react'

import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '@mindfulchefuk/design-system/Svg/Svg'

export const GiftBoxIcon: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 16 22" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        stroke="none"
        fill="currentColor"
        d="M15.1498 7.09977C11.0398 7.15977 4.82982 6.96977 0.659819 6.91977L1.11982 6.46977C1.06982 8.79977 0.939819 14.7398 0.889819 16.9898L0.849819 18.7398C0.819819 19.3498 0.829819 19.8698 1.07982 19.9598C1.33982 20.1098 2.07982 20.0698 2.61982 20.1098C6.67982 20.2798 10.7798 20.1898 14.8298 19.8098L14.4598 20.2198C14.4898 16.4698 14.7198 12.7298 14.6498 8.98977C14.6298 8.33977 15.6298 8.29977 15.6498 8.95977C15.5198 12.7398 15.2998 16.4798 15.2798 20.2198C15.2798 20.4298 15.1098 20.6098 14.9098 20.6298C10.8298 21.0098 6.68982 21.1098 2.58982 20.9498C1.95982 20.8698 1.31982 21.0198 0.669819 20.6898C-0.0701814 20.2798 -0.000181431 19.3898 -0.000181431 18.7298L0.0298186 16.9798C0.0698186 14.7498 0.159819 8.75977 0.199819 6.45977C0.199819 6.20977 0.409819 6.00977 0.659819 6.00977L7.90982 6.11977L11.5298 6.17977C12.7398 6.21977 13.9498 6.17977 15.1498 6.28977C15.3698 6.30977 15.5398 6.50977 15.5098 6.72977C15.4898 6.92977 15.3298 7.07977 15.1298 7.09977H15.1498Z"
      />
      <path
        stroke="currentColor"
        fill="none"
        d="M0.929688 11.8196L6.61969 11.8296L6.83969 7.05957"
      />
      <path
        stroke="currentColor"
        fill="none"
        d="M0.689453 14.8496H6.38945V20.4596"
      />
      <path
        stroke="currentColor"
        fill="none"
        d="M14.7402 15.0996L9.2002 14.8496V20.4596"
      />
      <path
        stroke="currentColor"
        fill="none"
        d="M14.8896 12.1896L9.38965 11.9796L9.66965 7.05957"
      />
      <path
        stroke="none"
        fill="currentColor"
        d="M7.60005 6.75999C5.59005 5.75999 3.20005 4.62999 2.62005 2.21999C2.35005 1.32999 2.89005 0.159987 3.88005 0.0399875C5.65005 -0.220013 7.14005 0.889987 7.93005 2.23999C8.84005 3.62999 8.69005 5.56999 7.62005 6.79999C7.47005 7.01999 7.17005 7.06999 6.96005 6.91999C6.72005 6.74999 6.69005 6.38999 6.92005 6.18999C8.65005 4.76999 7.56005 2.14999 5.89005 1.19999C3.20005 -0.180012 2.64005 1.90999 4.42005 3.72999C5.34005 4.73999 6.79005 5.25999 7.97005 6.09999C8.34005 6.34999 8.00005 6.93999 7.60005 6.75999Z"
      />
      <path
        stroke="none"
        fill="currentColor"
        d="M7.82983 5.89985C8.55983 3.85985 10.0898 1.76985 12.3398 1.30985C14.2698 1.00985 15.8998 2.60985 14.6298 4.39985C13.3298 6.37985 10.7498 6.60985 8.65983 6.73985C8.39983 6.76985 8.16983 6.57985 8.13983 6.31985C8.10983 6.02985 8.36983 5.76985 8.65983 5.80985C10.6198 5.99985 12.8398 5.64985 13.9798 3.97985C14.8798 2.67985 13.6098 1.92985 12.4598 2.11985C11.8698 2.21985 11.2998 2.53985 10.8098 2.95985C9.80983 3.79985 9.09983 4.97985 8.51983 6.17985C8.32983 6.59985 7.66983 6.32985 7.81983 5.89985H7.82983Z"
      />
    </g>
  </Svg>
)

export const GiftTickedIcon = (props: BoxProps): ReactElement => (
  <Svg viewBox="0 0 21 20" {...props} fill="none">
    <g clipPath="url(#a)">
      <path
        fill="currentColor"
        d="M16.553 6.414c-3.677.055-9.232-.117-12.962-.162l.411-.407-.206 9.505-.035 1.58c-.027.552-.018 1.022.205 1.103.233.136.895.1 1.378.136 3.632.153 7.3.072 10.923-.271l-.331.37c.026-3.388.232-6.767.17-10.146-.018-.587.876-.623.894-.027-.116 3.415-.313 6.794-.33 10.173 0 .19-.153.352-.332.37-3.65.344-7.353.434-11.021.29-.564-.073-1.136.063-1.718-.235-.662-.37-.599-1.175-.599-1.771l.027-1.581c.036-2.015.116-7.427.152-9.505a.41.41 0 0 1 .412-.406l6.485.1 3.239.053c1.082.036 2.164 0 3.238.1a.357.357 0 0 1 .322.397.374.374 0 0 1-.34.334h.018Z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m3.832 10.679 5.09.009.197-4.31M3.617 13.416h5.1v5.069M16.186 13.642l-4.956-.226v5.069M16.32 11.013l-4.92-.19.25-4.445"
      />
      <path
        fill="currentColor"
        d="M9.799 6.108C8 5.204 5.863 4.183 5.344 2.006c-.242-.804.241-1.861 1.127-1.97 1.583-.235 2.916.768 3.623 1.988.814 1.256.68 3.009-.277 4.12a.418.418 0 0 1-.59.108.424.424 0 0 1-.036-.66c1.547-1.282.572-3.65-.922-4.508-2.406-1.246-2.907.642-1.315 2.286.823.913 2.12 1.382 3.176 2.141.33.226.027.76-.331.597Z"
      />
      <path
        fill="currentColor"
        d="M10.004 5.33c.654-1.843 2.022-3.731 4.035-4.147 1.726-.27 3.185 1.175 2.048 2.792-1.162 1.789-3.47 1.997-5.34 2.114a.423.423 0 1 1 0-.84c1.753.172 3.74-.145 4.759-1.653.805-1.175-.331-1.853-1.36-1.68-.528.09-1.037.379-1.476.758-.894.759-1.53 1.825-2.048 2.91-.17.379-.76.135-.627-.254h.01Z"
      />
    </g>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.549 4.59c-.144-1.189-.66-2.2-1.523-3.033a3.963 3.963 0 0 0-1.738-.995c-.431-.117-.87-.13-1.313-.093-.724.06-1.415.242-2.049.598-1.157.65-1.862 1.599-1.922 2.957a4.24 4.24 0 0 0 .02.612 3.27 3.27 0 0 0 .039.253v.007l.026.123.01.078a1.161 1.161 0 0 0 .057.223l.013.032.001.011c0 .009 0 .017.002.025l.002.012c.168.617.45 1.177.853 1.675l.075.093c.02.024.039.048.059.07.058.068.12.132.194.185.003.004.004.01.006.015a.074.074 0 0 0 .005.011 3.661 3.661 0 0 0 1.019.714l.051.03c.052.026.108.046.164.064.1.048.203.088.31.117l.014.004c.037.01.075.018.113.025a.099.099 0 0 0 .02.01c.03.013.065.01.097.02a2.484 2.484 0 0 0 .632.089 6.31 6.31 0 0 0 1.377-.106c.251-.05.503-.096.742-.19.389-.11.753-.277 1.094-.49 1.169-.73 1.716-1.77 1.55-3.146"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M19.549 4.59c-.144-1.189-.66-2.2-1.523-3.033a3.963 3.963 0 0 0-1.738-.995c-.431-.117-.87-.13-1.313-.093-.724.06-1.415.242-2.049.598-1.157.65-1.862 1.599-1.922 2.957a4.24 4.24 0 0 0 .02.612 3.27 3.27 0 0 0 .039.253v.007l.026.123.01.078a1.161 1.161 0 0 0 .057.223l.013.032.001.011c0 .009 0 .017.002.025l.002.012c.168.617.45 1.177.853 1.675l.075.093c.02.024.039.048.059.07.058.068.12.132.194.185.003.004.004.01.006.015a.074.074 0 0 0 .005.011 3.661 3.661 0 0 0 1.019.714l.051.03c.052.026.108.046.164.064.1.048.203.088.31.117l.014.004c.037.01.075.018.113.025a.099.099 0 0 0 .02.01c.03.013.065.01.097.02a2.484 2.484 0 0 0 .632.089 6.31 6.31 0 0 0 1.377-.106c.251-.05.503-.096.742-.19.389-.11.753-.277 1.094-.49 1.169-.73 1.716-1.77 1.55-3.146"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M12.973 5.174a.27.27 0 0 1 .38-.035l1.206 1.005a.27.27 0 0 1-.346.415l-1.205-1.005a.27.27 0 0 1-.035-.38Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M14.252 6.538a.27.27 0 0 1-.042-.38l2.863-3.588a.27.27 0 0 1 .423.337l-2.864 3.588a.27.27 0 0 1-.38.043Z"
      clipRule="evenodd"
    />
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M3 0h14v19H3z" />
      </clipPath>
    </defs>
  </Svg>
)
