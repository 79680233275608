import Illustration from '@mindfulchefuk/design-system/Illustrations/Illustrations'
import { Box } from '@mindfulchefuk/design-system/layout/Box'
import React, { VoidFunctionComponent } from 'react'

// Layout for My Account (calendar, preferences etc.)
export const SectionBackground14: VoidFunctionComponent = () => (
  <>
    {/* Desktop illustrations */}
    <Box display={{ base: 'none', md: 'block' }}>
      <Illustration
        type="chopped-red-pepper-3"
        top={0}
        right={{ md: -80, lg: -128 }}
        transform="rotate(105deg)"
      />
      <Illustration
        type="chopped-squash-two"
        top={256}
        right={{ md: -80, lg: -128 }}
        transform="rotate(-20deg) scale(1.3)"
      />
      <Illustration
        type="cross-section-artichoke"
        top={896}
        right={{ md: -192, lg: -224 }}
        transform="rotate(-40deg)"
      />

      <Box position="absolute" top={512} left={-112}>
        <Illustration type="cross-section-beetroot-one" left={-64} top={0} />
        <Illustration type="cross-section-carrot" left={0} top={144} />
      </Box>
    </Box>

    <Box display={{ base: 'block', md: 'none' }}>
      <Illustration
        type="chopped-squash-two"
        top={0}
        right={-56}
        transform="rotate(20deg) scale(1.6)"
      />
    </Box>
  </>
)
