import {
  APIProductsData,
  Products,
} from '@mindfulchefuk/query/Products/interfaces'
import { ShopProduct } from '@mindfulchefuk/api-client'
import { transformProduct } from '@mindfulchefuk/query/Products/transforms'
import sortBy from '@mindfulchefuk/utils/sortBy'
import errorHandler from '@mindfulchefuk/utils/errorHandler'

export const getAllProducts = async (): Promise<Products> => {
  try {
    const { data } = (await ShopProduct.includes(['images', 'bundle_contents'])
      .where({ status: ['published', 'archived'] })
      .selectExtra(['sub_descriptions_html', 'description_html'])
      .order('name')
      // BE limits us to 100 products by default.
      // We need to update this to ensure all products are returned
      // For example, we can loop over requests using .page(1...etc) until we get no data back
      .per(1000)
      .all()) as APIProductsData

    return data
      .map(transformProduct)
      .sort((a, b) => sortBy(a.sortOrder, b.sortOrder, { asc: true }))
  } catch (error) {
    errorHandler(error)
    throw error
  }
}
