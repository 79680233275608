import type { ProductType } from '@mindfulchefuk/query'
import { CONTACT_US_URL } from '@mindfulchefuk/constants'

export interface RouteType {
  label: string
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  pathname: any
  as?: string
}

export const rootPages = ['/']

export const voucherPages = ['/GOO25X4', '/FB25X4', '/NEW25X4', '/NEWHP25X2']

export const basePages = [
  '/bbc-good-food',
  '/good-food',
  '/david-lloyd-partnership',
  '/family-recipe-boxes',
  '/food-boxes',
  '/healthy-meals',
  '/healthy-recipe-boxes',
  '/vegan-recipe-boxes',
  '/newbie',
]

const routesConfig: { [key: string]: RouteType } = {
  aboutYou: {
    label: 'About you',
    pathname: '/checkout/about-you',
    as: '/checkout/about-you',
  },
  yourRecipes: {
    label: 'Your recipes',
    pathname: '/my-account/recipes',
    as: '/my-account/recipes',
  },
  upcoming: {
    label: 'Your orders',
    pathname: '/my-account/orders/upcoming',
    as: '/my-account/orders/upcoming',
  },
  past: {
    label: 'Past orders',
    pathname: '/my-account/orders/past',
    as: '/my-account/orders/past',
  },
  calendar: {
    label: 'Calendar',
    pathname: '/my-account/orders/calendar',
    as: '/my-account/orders/calendar',
  },
  chooseRecipes: {
    label: 'Choose recipes',
    pathname: '/choose-recipes',
    as: '/choose-recipes',
  },
  contact: {
    label: 'Get in touch',
    pathname: CONTACT_US_URL,
    as: CONTACT_US_URL,
  },
  giftCard: {
    label: 'Gift cards',
    pathname: '/gift-cards',
    as: '/gift-cards',
  },
  custom404: {
    label: '404 - Not Found',
    pathname: '/404',
    as: '/404',
  },
  delivery: {
    label: 'Create or Edit a delivery',
    pathname: (deliveryDate: string) => {
      if (!deliveryDate) {
        throw new Error(
          `Invalid delivery date generated for /delivery/[deliveryDate],
          ${deliveryDate} was passed to routesConfig.delivery`
        )
      }
      return `/my-account/delivery/${deliveryDate}`
    },
    as: '/my-account/delivery/[deliveryDate]',
  },
  deliverySuccess: {
    label: 'Success',
    pathname: (deliveryDate: string) => {
      if (!deliveryDate) {
        throw new Error(
          `Invalid delivery date generated for /delivery/[deliveryDate]/success,
          ${deliveryDate} was passed to routesConfig.deliverySuccess`
        )
      }
      return `/my-account/delivery/${deliveryDate}/success`
    },
    as: '/my-account/delivery/[deliveryDate]/success',
  },
  details: {
    label: 'Details',
    pathname: '/my-account/details',
    as: '/my-account/details',
  },
  forgotPassword: {
    label: 'Forgot your password?',
    pathname: '/users/forgot-password',
    as: '/users/forgot-password',
  },
  home: {
    label: 'Home',
    pathname: '/',
    as: '/',
  },
  logout: {
    label: 'Log out',
    pathname: '/my-account/logout',
    as: '/my-account/logout',
  },
  giftCardsYourGiftCard: {
    label: 'Selection',
    pathname: '/gift-cards',
    as: '/gift-cards',
  },
  giftCardsPayment: {
    label: 'Payment',
    pathname: '/gift-cards/payment',
    as: '/gift-cards/payment',
  },
  giftCardsConfirmation: {
    label: 'Confirmation',
    pathname: '/gift-cards/confirmation',
    as: '/gift-cards/confirmation',
  },
  postLoginOnboardingShipping: {
    label: 'Shipping',
    pathname: '/my-account/onboarding/shipping',
    as: '/my-account/onboarding/shipping',
  },
  postLoginOnboardingPayment: {
    label: 'Payment',
    pathname: '/my-account/onboarding/payment',
    as: '/my-account/onboarding/payment',
  },
  order: {
    label: 'Order',
    pathname: (orderDate: string) => {
      if (!orderDate) {
        throw new Error(
          `Invalid order date generated for /order/[orderDate],
          ${orderDate} was passed to routesConfig.order`
        )
      }
      return `/my-account/order/${orderDate}`
    },
    as: '/my-account/order/[orderDate]',
  },
  myAccountShop: {
    label: 'Order ready meals',
    pathname: (deliveryDate: string, productType: ProductType = 'meal') => {
      if (!deliveryDate) {
        throw new Error(
          `Invalid delivery date generated for /delivery/[deliveryDate]/meal,
          ${deliveryDate} was passed to routesConfig.myAccountShop`
        )
      }

      return `/my-account/delivery/${deliveryDate}?page=${productType}`
    },
  },
  payment: {
    label: 'Payment',
    pathname: '/checkout/payment',
    as: '/checkout/payment',
  },
  privacyPolicy: {
    label: 'Privacy Policy',
    pathname: '/info/privacy-policy',
    as: '/info/privacy-policy',
  },
  recipes: {
    label: 'Recipes',
    pathname: '/healthy-recipes',
    as: '/healthy-recipes',
  },
  referral: {
    label: 'Get £10 off',
    pathname: '/my-account/referral',
    as: '/my-account/referral',
  },
  resetPassword: {
    label: 'Reset your password?',
    pathname: '/users/reset-password',
    as: '/users/reset-password',
  },
  deliveryDetails: {
    label: 'Delivery details',
    pathname: (deliveryDate: string) => {
      if (!deliveryDate) {
        throw new Error(
          `Invalid delivery date generated for /delivery/[deliveryDate]/details,
          ${deliveryDate} was passed to routesConfig.deliveryDetails`
        )
      }
      return `/my-account/delivery/${deliveryDate}/details`
    },
  },
  shipping: {
    label: 'Shipping',
    pathname: '/checkout/shipping',
    as: '/checkout/shipping',
  },
  signIn: {
    label: 'Log In to My Account',
    pathname: '/users/sign-in',
    as: '/users/sign-in',
  },
  signOut: {
    label: 'Sign Out',
    pathname: '/my-account/logout',
    as: '/my-account/logout',
  },
  subscription: {
    label: 'Preferences',
    pathname: '/my-account/subscription',
    as: '/my-account/subscription',
  },
  success: {
    label: 'Checkout Success',
    pathname: '/checkout/success',
    as: '/checkout/success',
  },
  vitalityPromotion: {
    label: 'Vitality Promotion',
    pathname: '/vitality-promotion',
    as: '/vitality-promotion',
  },
  vitalityAuthorization: {
    label: 'Vitality Authorization',
    pathname: '/vitality/authorization',
    as: '/vitality/authorization',
  },
  vitalitySuccess: {
    label: 'Vitality Sucess',
    pathname: '/vitality/success',
    as: '/vitality/success',
  },
  vitalitySignUp: {
    label: 'Vitality Sign up',
    pathname: (queryParmas: Record<string, string>) => {
      const query = new URLSearchParams(queryParmas).toString()
      return `/vitality-signup?${query}`
    },
    as: '/vitality-signup',
  },
  vitalitySignIn: {
    label: 'Vitality Sign in',
    pathname: (queryParmas: Record<string, string>) => {
      const query = new URLSearchParams(queryParmas).toString()

      return `/vitality-signin?${query}`
    },
    as: '/vitality-signin',
  },
}

export default routesConfig
