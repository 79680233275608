import React, { ReactElement } from 'react'

import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '@mindfulchefuk/design-system/Svg/Svg'

export const MeatballMenuIcon = (props: BoxProps): ReactElement => (
  <Svg viewBox="0 0 20 20" {...props}>
    <path
      fill="#392D6E"
      fillRule="evenodd"
      d="M4.99 9.538a1.878 1.878 0 0 0-.533-1.127 1.368 1.368 0 0 0-.607-.37 1.242 1.242 0 0 0-.46-.034c-.253.022-.495.09-.716.222-.405.242-.652.594-.673 1.099a1.658 1.658 0 0 0 .02.322l.001.002.01.046a.93.93 0 0 0 .015.087.306.306 0 0 0 .012.036v.005l.001.009v.004c.06.23.158.438.3.623l.025.034.021.027a.4.4 0 0 0 .068.068l.002.005.002.005.002.002a1.283 1.283 0 0 0 .43.298.623.623 0 0 0 .108.043l.004.002a.48.48 0 0 0 .04.01.034.034 0 0 0 .007.003c.01.005.023.004.034.007a.82.82 0 0 0 .221.033c.162.004.323-.006.482-.04.087-.018.175-.035.26-.07.135-.04.263-.102.382-.182.409-.27.6-.657.542-1.169ZM11.99 9.538a1.878 1.878 0 0 0-.533-1.127 1.369 1.369 0 0 0-.607-.37 1.242 1.242 0 0 0-.46-.034c-.253.022-.495.09-.716.222-.405.242-.652.594-.673 1.099a1.658 1.658 0 0 0 .02.322l.001.002.01.046a.93.93 0 0 0 .015.087.306.306 0 0 0 .012.04l.001.01v.004c.06.23.158.438.3.623l.025.034.021.027a.4.4 0 0 0 .068.068l.002.005.002.005.002.002a1.283 1.283 0 0 0 .43.298.624.624 0 0 0 .108.043l.004.002a.484.484 0 0 0 .04.01.033.033 0 0 0 .007.003c.01.005.023.004.034.007a.82.82 0 0 0 .221.033c.162.004.323-.006.482-.04.087-.018.175-.035.26-.07.135-.04.262-.102.382-.182.409-.27.6-.657.542-1.169ZM18.99 9.538a1.878 1.878 0 0 0-.533-1.127 1.369 1.369 0 0 0-.608-.37 1.242 1.242 0 0 0-.459-.034c-.253.022-.495.09-.716.222-.405.242-.652.594-.672 1.099a1.61 1.61 0 0 0 .006.227l.001.01a1.221 1.221 0 0 0 .013.085v.002l.01.046a.704.704 0 0 0 .023.112.492.492 0 0 0 .004.011v.005a.097.097 0 0 0 .002.013c.059.23.157.438.298.623l.026.034.021.027c.02.025.042.048.068.068l.002.005.002.005.002.002a1.283 1.283 0 0 0 .43.298.622.622 0 0 0 .108.043l.004.002a.484.484 0 0 0 .04.01.033.033 0 0 0 .007.003c.01.005.023.004.034.007a.82.82 0 0 0 .221.033c.162.004.323-.006.482-.04.087-.018.175-.035.26-.07.135-.04.262-.102.382-.182.409-.27.6-.657.542-1.169Z"
      clipRule="evenodd"
    />
  </Svg>
)
