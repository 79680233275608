import styled from '@emotion/styled'
import { css } from '@emotion/core'

const a11yHidden = css`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

export const VisuallyHidden = styled.span`
  ${a11yHidden}
`
