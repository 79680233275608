import React, { VoidFunctionComponent } from 'react'
import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg'

export const PorkIcon: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 20 20" {...props}>
    <g
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M2.64,8.08a10.5,10.5,0,0,1,4.76-4,7.58,7.58,0,0,1,6.36.22A8.36,8.36,0,0,1,17,7.63a10,10,0,0,1,1.18,4.8,7.24,7.24,0,0,1-4.72,6.25,9.06,9.06,0,0,1-8.44-1,6.27,6.27,0,0,1-2.31-7" />
      <path d="M3.44,6.19A5.37,5.37,0,0,1,2.38,3.08a1,1,0,0,1,.2-.72,1,1,0,0,1,1-.13A5.51,5.51,0,0,1,6.42,4.11" />
      <path d="M13.76,4.26a4.64,4.64,0,0,1,1.75-1.92A1.62,1.62,0,0,1,17.3,2.2a1.53,1.53,0,0,1,.43,1.44,3.7,3.7,0,0,1-1.59,2.59" />
      <path d="M8.83,13.69,9.1,15" />
      <path d="M10.81,13.55l.25,1.18" />
      <ellipse cx="10.03" cy="14.32" rx="2.83" ry="2.36" />
    </g>
  </Svg>
)
