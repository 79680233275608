export const CONTAINER_MIN_WIDTH = '320px'
export const CONTAINER_MAX_WIDTH = '1236px'
// Header max width is slightly higher than the container so it overhangs slightly
export const HEADER_MAX_WIDTH = '1440px'

export const HEADER_PADDING = {
  base: 24,
  lg: 48,
} as const

export const CONTAINER_PADDING = {
  base: 24,
  md: 48,
} as const
