import { useEffect } from 'react'
import * as amplitudeAdapter from '@mindfulchefuk/utils/analytics/amplitude/amplitudeAdapter'

export function useOptimizelyWithAmplitude() {
  useEffect(() => {
    window.optimizely = window.optimizely || []

    window.optimizely.push({
      type: 'addListener',
      filter: {
        type: 'lifecycle',
        name: 'campaignDecided',
      },
      handler: onCampaignDecided,
    })
  }, [])
}

interface OptimizelyCampaignDecision {
  data: { decision: { experimentId: string; variationId: string } }
}

export function onCampaignDecided({ data }: OptimizelyCampaignDecision) {
  const { decision } = data

  if (amplitudeAdapter.sdkInitialised()) {
    amplitudeAdapter.setExperiment(decision.experimentId, decision.variationId)
    return true
  }
  return false
}
