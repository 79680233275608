import { createReducer } from '@reduxjs/toolkit'
import {
  CHECKOUT_CARD_IS_VALID_SET,
  CHECKOUT_PAYMENT_TOKEN_IN_PROGRESS_SET,
  CHECKOUT_PAYMENT_TOKEN_SET,
  CHECKOUT_ERROR_SET,
  CHECKOUT_RESET,
  CHECKOUT_STEPS_SET,
  CHECKOUT_SUBMITTING_SET,
  CHECKOUT_VALUE_SET,
  CHECKOUT_VOUCHER_ERROR_SET,
  CHECKOUT_VOUCHER_ERROR_UNSET,
} from '@mindfulchefuk/actions/checkoutActions'
import { ERROR_MESSAGE, IS_PENDING } from '@mindfulchefuk/constants'

export const initialState = {
  activeCheckoutSteps: [],
  addressPickerActive: true,
  checkoutIsSubmitting: false,
  email: '',
  firstName: '',
  isCardValid: false,
  lastName: '',
  locality: '',
  marketingOptIn: false,
  password: '',
  paymentToken: '',
  paymentTokenRequestInProgress: false,
  postcode: '',
  recipientPhone: '',
  safePlace: '',
  safePlaceOther: '',
  street1: '',
  street2: '',
  voucherCode: '',
  partnershipName: '',
  [ERROR_MESSAGE]: undefined,
  [IS_PENDING]: false,
}

const checkoutReducer = createReducer(initialState, {
  [CHECKOUT_STEPS_SET]: (state, action) => ({
    ...state,
    [IS_PENDING]: false,
    [ERROR_MESSAGE]: '',
    activeCheckoutSteps: [
      ...new Set([...state.activeCheckoutSteps, ...action.payload]),
    ],
  }),

  [CHECKOUT_PAYMENT_TOKEN_IN_PROGRESS_SET]: (state, action) => ({
    ...state,
    paymentTokenRequestInProgress: action.payload,
  }),

  [CHECKOUT_PAYMENT_TOKEN_SET]: (state, action) => ({
    ...state,
    paymentToken: action.payload,
    paymentTokenRequestInProgress: false,
  }),

  [CHECKOUT_CARD_IS_VALID_SET]: (state, action) => ({
    ...state,
    isCardValid: action.payload,
  }),

  [CHECKOUT_ERROR_SET]: (state, action) => ({
    ...state,
    [IS_PENDING]: false,
    [ERROR_MESSAGE]: action.payload,
  }),

  [CHECKOUT_SUBMITTING_SET]: (state, action) => ({
    ...state,
    [IS_PENDING]: action.payload,
  }),

  [CHECKOUT_VALUE_SET]: (state, action) => ({
    ...state,
    ...action.payload,
  }),

  [CHECKOUT_VOUCHER_ERROR_SET]: (state, action) => ({
    ...state,
    voucherCode: '',
    [ERROR_MESSAGE]: action.payload,
  }),

  [CHECKOUT_VOUCHER_ERROR_UNSET]: (state) => ({
    ...state,
    [ERROR_MESSAGE]: undefined,
  }),

  [CHECKOUT_RESET]: () => ({
    ...initialState,
  }),
})

export default checkoutReducer
