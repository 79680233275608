import { Recipe } from '@mindfulchefuk/features/Recipes/interfaces'
import {
  GetRecipeRecommendationParams,
  getRecipeRecommendations,
} from '@mindfulchefuk/features/Recipes/services/getRecipeRecommendations'
import { getRecipes } from '@mindfulchefuk/features/Recipes/services/getRecipes'
import createActionHelper from '@mindfulchefuk/helpers/js/createActionHelper'
import { Dispatch } from 'redux'

export const RECIPES_EXCLUDED_FOOD_GROUPS_SET =
  'RECIPES_EXCLUDED_FOOD_GROUPS_SET'
export const setExcludedFoodGroups = createActionHelper(
  RECIPES_EXCLUDED_FOOD_GROUPS_SET
)

export const RECIPES_LOADING_SET = 'RECIPES_LOADING_SET'
export const setRecipesLoading = createActionHelper(RECIPES_LOADING_SET)

export const RECIPES_MEAL_PLAN_SET = 'RECIPES_MEAL_PLAN_SET'
export const setMealPlan = createActionHelper(RECIPES_MEAL_PLAN_SET)

export const RECIPES_RESET = 'RECIPES_RESET'
export const resetRecipes = createActionHelper(RECIPES_RESET)

export const RECIPES_SET = 'RECIPES_SET'
export const setRecipes = createActionHelper(RECIPES_SET)

export const RECIPES_SORT = 'RECIPES_SORT'
export const sortRecipes = createActionHelper(RECIPES_SORT)

export const getRecipesByDateAndPortion =
  ({
    date,
    portionCount,
    excludedFoodGroups = [],
    customerId = '',
    mealPlan = 'Balanced',
  }: GetRecipeRecommendationParams) =>
  async (dispatch: Dispatch) => {
    dispatch(setRecipesLoading(true))

    const recipeRecommendations = await getRecipeRecommendations({
      date,
      portionCount,
      excludedFoodGroups,
      customerId,
      mealPlan,
      isOnboarding: true,
    })

    if (!recipeRecommendations.length) {
      dispatch(setRecipesLoading(false))
      dispatch(resetRecipes())

      return
    }

    const recipeIds = recipeRecommendations.map(
      (recipeRecommendation) => recipeRecommendation.recipeId
    )

    const recipes = await getRecipes({ recipeIds, portionCount })
    const mergedRecipes = recipeRecommendations
      .map<Recipe>((recipeRecommendation): Recipe => {
        const recipe = recipes.find(
          (r) => r.id === recipeRecommendation.recipeId
        )

        if (!recipe) return undefined
        return {
          ...recipe,
          sortOrder: recipeRecommendation.sortOrder,
          price: recipeRecommendation.price,
          pricePerPerson: recipeRecommendation.pricePerPerson,
          portions: recipeRecommendation.portions,
        }
      })
      .filter(Boolean)

    dispatch(setRecipes(mergedRecipes))
  }
