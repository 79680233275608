import React, { ReactNode, useEffect } from 'react'
import Cookies from 'universal-cookie'
import {
  GrowthBook,
  GrowthBookProvider as GBProvider,
} from '@growthbook/growthbook-react'
import { selectEmail } from '@mindfulchefuk/features/Customer/selectors'
import { useGetCustomer } from '@mindfulchefuk/features/Customer'
import { getCustomerId } from '@mindfulchefuk/helpers/js/authentication'
import { generateGrowthBookDeviceId } from '@mindfulchefuk/context/GrowthBookProvider/generateGrowthBookDeviceId'

type GrowthBookExperimentProps = {
  growthbook: GrowthBook
  children?: ReactNode
}

export const GrowthBookProvider = ({
  growthbook,
  children,
}: GrowthBookExperimentProps) => {
  const deviceId = generateGrowthBookDeviceId(new Cookies())
  const customerId = getCustomerId()
  const id = customerId || deviceId
  const { data: email } = useGetCustomer({ select: selectEmail }) || {}

  useEffect(() => {
    growthbook.init({ streaming: true })
  }, [growthbook])

  useEffect(() => {
    growthbook.setAttributes({
      id,
      deviceId,
      customerId,
      loggedIn: !!customerId,
      email,
      platform: 'web',
      releaseVersion:
        process.env.HEROKU_RELEASE_VERSION || process.env.HEROKU_BRANCH,
    })
  }, [growthbook, id, customerId, email, deviceId])

  return <GBProvider growthbook={growthbook}>{children}</GBProvider>
}
