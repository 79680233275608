import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { getAllProducts } from '@mindfulchefuk/query/Products/services/getAllProducts'
import { Products } from '@mindfulchefuk/query/Products/interfaces'
import { STALE_15_MINS } from '@mindfulchefuk/constants'

export const useQueryAllProducts = <TData = Products>(
  options: UseQueryOptions<Products, unknown, TData> = {}
) => {
  return useQuery({
    queryKey: ['products', 'all'],
    queryFn: () => getAllProducts(),
    staleTime: STALE_15_MINS,
    ...options,
  })
}
