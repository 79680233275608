import { PaymentsCombinedOrderPreview } from '@mindfulchefuk/api-client'
import errorHandler from '@mindfulchefuk/utils/errorHandler'
import { spraypaintErrorMessage } from '@mindfulchefuk/utils/spraypaintUtils'
import getPaymentDate from '@mindfulchefuk/helpers/js/getPaymentDate'

interface CombinedOrderPreviewParamsType {
  customer_id: string
  recipe_purchase_value: number
  shop_purchase_value: number
  delivery_date: string
  recipe_contents: {
    recipe_id: string
    portions: number
    price: number
  }[]
}
export interface PostCombinedOrderPreviewProps {
  customerId: string
  recipePurchaseValue: number
  shopPurchaseValue: number
  deliveryDate: string
  recipeContents: {
    recipe_id: string
    portions: number
    price: number
  }[]
}

export interface PostCombinedOrderPreviewResponse {
  recipe_discount_amount: string
  recipe_credit_balance_amount: string
  recipe_external_payment_amount: string
  recipe_shipping_amount: string
  shop_discount_amount: string
  shop_credit_balance_amount: string
  shop_external_payment_amount: string
  shop_shipping_amount: string
  shop_purchase_value: string
  recipe_purchase_value: string
  delivery_date: string
}

export const postCombinedOrderPreview = async ({
  customerId,
  recipePurchaseValue,
  shopPurchaseValue,
  deliveryDate,
  recipeContents,
}: PostCombinedOrderPreviewProps) => {
  const combinedOrderPreviewParams: CombinedOrderPreviewParamsType = {
    customer_id: customerId,
    recipe_purchase_value: recipePurchaseValue || null,
    shop_purchase_value: shopPurchaseValue || null,
    delivery_date: deliveryDate,
    recipe_contents: recipeContents,
  }

  const combinedOrderPreview = new PaymentsCombinedOrderPreview(
    combinedOrderPreviewParams
  )

  try {
    const result = await combinedOrderPreview.save()

    if (!result) {
      throw new Error(spraypaintErrorMessage(combinedOrderPreview))
    }

    const {
      recipe_discount_amount: recipeDiscountAmount,
      recipe_credit_balance_amount: recipeCreditBalanceAmount,
      recipe_external_payment_amount: recipeExternalPaymentAmount,
      recipe_shipping_amount: recipeShippingAmount,
      shop_discount_amount: shopDiscountAmount,
      shop_credit_balance_amount: shopCreditBalanceAmount,
      shop_external_payment_amount: shopExternalPaymentAmount,
      shop_shipping_amount: shopShippingAmount,
    } = combinedOrderPreview

    return {
      recipe: {
        purchaseValue: recipePurchaseValue || null,
        discountAmount: recipeDiscountAmount
          ? parseFloat(recipeDiscountAmount)
          : null,
        creditBalanceAmount: recipeCreditBalanceAmount
          ? parseFloat(recipeCreditBalanceAmount)
          : null,
        externalPaymentAmount: recipeExternalPaymentAmount
          ? parseFloat(recipeExternalPaymentAmount)
          : null,
        shippingAmount: recipeShippingAmount
          ? parseFloat(recipeShippingAmount)
          : 0,
        paymentDate: getPaymentDate(deliveryDate),
      },
      shop: {
        purchaseValue: shopPurchaseValue || null,
        discountAmount: shopDiscountAmount
          ? parseFloat(shopDiscountAmount)
          : null,
        creditBalanceAmount: shopCreditBalanceAmount
          ? parseFloat(shopCreditBalanceAmount)
          : null,
        externalPaymentAmount: shopExternalPaymentAmount
          ? parseFloat(shopExternalPaymentAmount)
          : null,
        shippingAmount: shopShippingAmount
          ? parseFloat(shopShippingAmount)
          : null,
      },
    }
  } catch (error) {
    errorHandler(error)
  }
}
