import styled from '@emotion/styled'
import { theme } from '@mindfulchefuk/design-system/system/theme'
import {
  CSSPropMapper,
  Responsive,
} from '@mindfulchefuk/design-system/system/types'
import { createCSSMapper } from '@mindfulchefuk/design-system/system/utils/createCSSMapper'
import { margin, MarginProps } from '@mindfulchefuk/design-system/system'

interface TertiaryButtonProps extends MarginProps {
  /** The HTML element the button will render as */
  as?: string
  /** The link url */
  href?: string
  target?: '_blank' | '_self' | '_parent' | '_top'
  variant: Responsive<TertiaryButtonVariant>
  size: Responsive<TertiaryButtonSize>
  textWrap?: Responsive<boolean>
}

const sizes = {
  'x-small': {
    textDecorationThickness: '1px',
    ...theme.typography['body-sm-300-caps'],
  },
  small: {
    padding: `${theme.space[2]} ${theme.space[0]}`,
    ...theme.typography['body-md-500-caps'],
  },
  medium: {
    padding: `${theme.space[10]} ${theme.space[0]}`,
    ...theme.typography['body-lg-500-caps'],
  },
}

type TertiaryButtonSize = keyof typeof sizes
type TertiaryButtonVariant = keyof typeof variants

const variants = {
  primary: {
    color: theme.colors.aubergine,
  },
  secondary: {
    color: theme.colors.white,
  },
}

const buttonVariants: CSSPropMapper<TertiaryButtonProps> = {
  variant: {
    transform: ({ value }) =>
      variants[value as TertiaryButtonVariant] ?? variants.primary,
  },
  size: {
    transform: ({ value }) =>
      sizes[value as TertiaryButtonSize] ?? sizes.medium,
  },
  textWrap: {
    transform: ({ value }) => ({
      whiteSpace: value ? 'normal' : 'nowrap',
      textOverflow: value ? 'visible' : 'ellipsis',
    }),
  },
  ...margin,
}

const [buttonVariantMapper, shouldForwardProp] = createCSSMapper(buttonVariants)

export const TertiaryButton = styled('button', {
  shouldForwardProp,
})<TertiaryButtonProps>`
  display: inline-block;
  box-sizing: border-box;

  text-align: center;
  background: transparent;
  border-color: transparent;
  text-decoration: underline;
  text-underline-offset: 2px;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  &:disabled {
    cursor: not-allowed;
    color: ${theme.colors['pepper-500']};
  }

  ${buttonVariantMapper};
`

TertiaryButton.defaultProps = {
  textWrap: false,
}
