export const removeSensitiveData = (rawPageSource: string): string => {
  const matchPasswordAndEmail = /(password=[^&]+)|(email=[^&]+)/gi

  const matchQueryParamConnectors = /\?&&|\?&/gim
  const matchTrailingQuestionMark = /\?$/

  return rawPageSource
    .replace(matchPasswordAndEmail, '')
    .replace(matchQueryParamConnectors, '?')
    .replace(matchTrailingQuestionMark, '')
}
