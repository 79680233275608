export const overlays = {
  'white.20': 'rgba(255, 255, 255, 0.2)',
  'white.50': 'rgba(255, 255, 255, 0.5)',
  'white.95': 'rgba(255, 255, 255, 0.95)',
  'white.80': 'rgba(255, 255, 255, 0.8)',
  'pepper.50': 'rgba(30, 30, 40, 0.5)',
} as const

export type Overlays = typeof overlays
export type OverlayToken = keyof Overlays
