import React, { VoidFunctionComponent } from 'react'
import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg'

export const TurkeyIcon: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 20 20" {...props}>
    <g
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polygon points="9.3 8.16 10.27 9.84 11.24 8.16 9.3 8.16" />
      <path d="M8.51,10.73A4.26,4.26,0,0,1,7.26,6.37a3.63,3.63,0,0,1,4-2.28,3.36,3.36,0,0,1,2.63,3.08,4.22,4.22,0,0,1-2.06,3.58" />
      <path d="M3.94,17.2A5.69,5.69,0,0,1,7.4,11.34c2.47-1.12,6-.63,7.7,1.26a7.83,7.83,0,0,1,1.52,3.17,1.91,1.91,0,0,1-.08,1.83,3,3,0,0,1-2.11.72,42.29,42.29,0,0,1-7.63-.13" />
      <path d="M2.72,13.41a2.39,2.39,0,0,1-1.6-.87A1.61,1.61,0,0,1,1,10.78a1.2,1.2,0,0,1,1.62-.41,4.59,4.59,0,0,1-1.34-1.9,2,2,0,0,1,.51-2.15,1.32,1.32,0,0,1,2,.38c-.25-1.22-.46-2.64.35-3.59a2.55,2.55,0,0,1,3.65.13A2.46,2.46,0,0,1,9.86,1.16,2.45,2.45,0,0,1,12.37,2.7a2.63,2.63,0,0,1,3.22.09A2.63,2.63,0,0,1,16.13,6a2.68,2.68,0,0,1,2.81,1.71,2.67,2.67,0,0,1-1.14,3.09,1.3,1.3,0,0,1,1.76.56,1.85,1.85,0,0,1-.3,1.94,3.32,3.32,0,0,1-1.75,1" />
    </g>
  </Svg>
)
