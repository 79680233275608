import {
  APILineItem,
  LineItem,
} from '@mindfulchefuk/query/ShopOrders/interfaces'

export function transformLineItem(item: APILineItem): LineItem {
  return {
    lineItemId: item.id,
    productId: item.product_id,
    quantity: item.quantity,
    unitPrice: parseFloat(item.unit_price),
  }
}
