import React, { VoidFunctionComponent } from 'react'

import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg/Svg'

export const QuoteCloseIcon: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox=" 0 0 15 12" {...props}>
    <path
      d="M11.597.353c-.853 0-1.558.28-2.117.838a2.764 2.764 0 00-.839 2.03c0 .794.243 1.433.728 1.919a2.378 2.378 0 001.743.728l.573-.044c.089 0 .17.044.243.132.074.088.11.19.11.309 0 1.205-1.103 2.103-3.308 2.69-.56.177-.839.5-.839.971v.089c.059.588.368.882.927.882h.22c1.853-.265 3.31-.912 4.368-1.941 1.059-1.03 1.588-2.5 1.588-4.412 0-1.382-.323-2.426-.97-3.132-.648-.706-1.456-1.06-2.427-1.06zm-7.809 0c-.852 0-1.558.28-2.117.838a2.764 2.764 0 00-.839 2.03c0 .794.243 1.433.728 1.919a2.378 2.378 0 001.743.728l.574-.044c.088 0 .169.044.242.132.074.088.11.19.11.309 0 1.205-1.102 2.103-3.308 2.69-.56.177-.839.5-.839.971v.089c.06.588.368.882.927.882h.22c1.853-.265 3.31-.912 4.368-1.941 1.059-1.03 1.588-2.5 1.588-4.412 0-1.382-.323-2.426-.97-3.132S4.759.352 3.788.352z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </Svg>
)

export const QuoteOpenIcon: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 15 12" {...props}>
    <path
      d="M3.48 10.897c.852 0 1.558-.28 2.117-.838a2.764 2.764 0 00.838-2.03c0-.794-.242-1.433-.728-1.919a2.378 2.378 0 00-1.742-.728l-.574.044c-.088 0-.169-.044-.242-.132a.467.467 0 01-.11-.309c0-1.205 1.102-2.103 3.308-2.69.56-.177.838-.5.838-.971v-.089C7.127.647 6.818.353 6.26.353h-.22c-1.854.265-3.31.912-4.368 1.941C.61 3.324.082 4.794.082 6.706c0 1.382.324 2.426.971 3.132s1.456 1.06 2.427 1.06zm7.808 0c.853 0 1.56-.28 2.118-.838a2.764 2.764 0 00.838-2.03c0-.794-.242-1.433-.728-1.919a2.378 2.378 0 00-1.742-.728l-.574.044c-.088 0-.169-.044-.242-.132a.467.467 0 01-.11-.309c0-1.205 1.102-2.103 3.308-2.69.559-.177.838-.5.838-.971v-.089c-.058-.588-.367-.882-.926-.882h-.22c-1.854.265-3.31.912-4.368 1.941-1.06 1.03-1.589 2.5-1.589 4.412 0 1.382.324 2.426.97 3.132.648.706 1.457 1.06 2.427 1.06z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </Svg>
)
