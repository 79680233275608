import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Button } from './Button'

export const buttonGroupStyles = css`
  display: flex;
  flex-wrap: nowrap;

  > ${Button} {
    flex-grow: 1;
  }

  > ${Button}:not(:first-of-type) {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  > ${Button}:not(:last-of-type) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`

export const ButtonGroup = styled.div`
  ${buttonGroupStyles}
`
