import { applyMiddleware, combineReducers, createStore } from 'redux'
import thunk from 'redux-thunk'
// eslint-disable-next-line import/no-named-default
import { default as invariant } from 'redux-immutable-state-invariant'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage/session'

import basketReducer from '@mindfulchefuk/reducers/basketReducer'
import recipeCartReducer from '@mindfulchefuk/features/Cart/recipeCartSlice'
import productCartReducer from '@mindfulchefuk/features/Cart/productCartSlice'
import checkoutReducer from '@mindfulchefuk/reducers/checkoutReducer'
import { notificationsReducer } from '@mindfulchefuk/features/Notifications/notificationsSlice'
import recipesReducer from '@mindfulchefuk/reducers/recipesReducer'
import { recipeFiltersReducer } from '@mindfulchefuk/features/RecipeFilters/recipeFiltersSlice'
import { navigationReducer } from '@mindfulchefuk/features/Navigation/navigationSlice'

const persistBasketConfig = {
  key: 'basket',
  storage,
  blacklist: ['isBasketLoading', 'lastAddedRecipeId'],
}

const persistCheckoutConfig = {
  key: 'checkout',
  storage,
}

const appReducer = combineReducers({
  basket: persistReducer(persistBasketConfig, basketReducer),
  checkout: persistReducer(persistCheckoutConfig, checkoutReducer),
  navigation: navigationReducer,
  notifications: notificationsReducer,
  recipeCart: recipeCartReducer,
  productCart: productCartReducer,
  recipes: recipesReducer,
  recipeFilters: recipeFiltersReducer,
})

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGGED_OUT') {
    storage.removeItem('persist:root')
    storage.removeItem('persist:shipping')
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

const configureStore = (initialState) => {
  if (process.env.NODE_ENV !== 'production') {
    const composeEnhancers = composeWithDevTools({
      trace: true,
      traceLimit: 25,
    })
    return createStore(
      rootReducer,
      initialState,
      composeEnhancers(applyMiddleware(invariant(), thunk))
    )
  }
  return createStore(rootReducer, initialState, applyMiddleware(thunk))
}

export default configureStore
