import React, { VoidFunctionComponent } from 'react'
import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg'

export const DeliveryIcon: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 15 15" {...props}>
    <g
      stroke="currentColor"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    >
      <path d="M2.37 1.402a46.222 46.222 0 0 1 4.673 0 3.472 3.472 0 0 1 2.182.75c.352.42.538.953.525 1.5a6.81 6.81 0 0 1-.255 3.098 1.23 1.23 0 0 1-2.302-.098A2.25 2.25 0 0 1 8.73 3.855a12.067 12.067 0 0 1 2.655-.15c2.31 0 2.123.975 2.422 3l.6 4.08c.09.326.09.671 0 .998a1.29 1.29 0 0 1-.907.742c-.395.077-.8.09-1.2.037M.983 5.505l-.06 3.54c-.05.698.01 1.399.18 2.078a2.25 2.25 0 0 0 1.335 1.5M6.188 12.578h1.747" />
      <path d="M11.79 12.488a1.755 1.755 0 0 0-.997-1.433 1.732 1.732 0 0 0-1.733.195 1.792 1.792 0 0 0-.6 1.905c.117.378.354.707.675.938a1.837 1.837 0 0 0 1.838.127c.585-.303.958-.901.974-1.56M6 12.488a1.703 1.703 0 0 0-2.737-1.238 1.807 1.807 0 0 0-.6 1.905 1.785 1.785 0 0 0 2.52 1.095c.58-.308.952-.903.974-1.56" />
    </g>
  </Svg>
)
