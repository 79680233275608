import styled from '@emotion/styled'

import { Property } from 'csstype'
import { TypographyToken } from '../system/theme/typography'
import { theme } from '../system'
import { Box, BoxProps } from '../layout/Box'
import { createCSSMapper } from '../system/utils/createCSSMapper'
import { CSSPropMapper, Responsive } from '../system/types'

export interface TextProps extends BoxProps {
  variant?: Responsive<TypographyToken>
  textTransform?: Responsive<Property.TextTransform>
  fontStyle?: Responsive<Property.FontStyle>
}

const textVariants: CSSPropMapper<TextProps> = {
  variant: {
    transform: ({ value }) => theme.typography[value as TypographyToken],
  },
  fontStyle: { property: 'fontStyle' },
  textTransform: { property: 'textTransform' },
}

const [textVariantMapper, shouldForwardProp] = createCSSMapper(textVariants)

export const Text = styled(Box, { shouldForwardProp })<TextProps>`
  ${textVariantMapper};
`

export const TruncatedText = styled(Text)<TextProps>`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

interface MultilineTruncatedTextProps extends TextProps {
  lines?: number
}

export const MultilineTruncatedText = styled(Text)<MultilineTruncatedTextProps>`
  -webkit-line-clamp: ${({ lines }) => lines};
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
`
