import React, { FunctionComponent, SelectHTMLAttributes } from 'react'
import {
  SelectChevron,
  SelectElement,
} from '@mindfulchefuk/design-system/Forms/Select/Select.styles'
import { Box } from '@mindfulchefuk/design-system/layout'

export interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  options?: { value: string; label: string }[]
}

export const Select: FunctionComponent<SelectProps> = ({
  options,
  name,
  children,
  ...htmlProps
}) => (
  <Box position="relative">
    <SelectElement {...htmlProps}>
      {options?.map(({ value, label }) => (
        <option
          data-testid={`${name}-option-${value}`}
          key={value}
          value={value}
        >
          {label}
        </option>
      ))}
      {children}
    </SelectElement>
    <SelectChevron type="chevron-down" />
  </Box>
)
