import styled from '@emotion/styled'
import {
  alignment,
  AlignmentProps,
  flexParent,
  FlexParentProps,
} from '@mindfulchefuk/design-system/system'

import { Box } from './Box'
import { createCSSMapper } from '../system/utils/createCSSMapper'

const [flexMapper, shouldForwardProp] = createCSSMapper({
  ...alignment,
  ...flexParent,
})

export type FlexProps = AlignmentProps & FlexParentProps

export const Flex = styled(Box, { shouldForwardProp })<FlexProps>`
  ${flexMapper};
`
Flex.defaultProps = {
  display: 'flex',
}

export { flexMapper, shouldForwardProp }
