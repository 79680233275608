import React, {
  ImgHTMLAttributes,
  ReactElement,
  ReactEventHandler,
  useState,
} from 'react'
import Imgix, {
  Picture,
  Source,
  buildURL,
  SharedImgixAndSourceProps,
} from 'react-imgix'

import { DEFAULT_IMAGE, IMAGE_PATH_PREFIX } from '@mindfulchefuk/constants'

type GetImageUrlOptions = {
  resolve: boolean
}

const DEFAULT_LAZY_LOADING_IMAGE_SIZE = 50

function getImageUrl(src: string, options: GetImageUrlOptions): string {
  if (!src) {
    return DEFAULT_IMAGE
  }
  if (!options.resolve) {
    return src
  }
  return [IMAGE_PATH_PREFIX, src.replace(/(^\/|\/$)/g, '')].join('/')
}

export type SourceProps = {
  width?: number
  height?: number
  media: string
  src?: string
  q?: number
  /** Comes from [Imgix's fit query parameter](https://docs.imgix.com/apis/rendering/size/fit) */
  fit?:
    | 'clamp'
    | 'clip'
    | 'crop'
    | 'facearea'
    | 'fill'
    | 'fillmax'
    | 'max'
    | 'min'
    | 'scale'
  /** Comes from [Imgix's crop query parameter](https://docs.imgix.com/apis/rendering/size/crop) */
  crop?:
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'faces'
    | 'focalpoint'
    | 'edges'
    | 'entropy'

  imgixParams?: SharedImgixAndSourceProps['imgixParams']
}

export type ImageProps = Omit<ImgHTMLAttributes<HTMLImageElement>, 'onLoad'> &
  Omit<SourceProps, 'media'> & {
    resolve?: boolean
    /**
     * Alternative responsive image sources, applied in order.
     * NOTE: Place more specific media queries first eg, min width large then min width small
     */
    sources?: SourceProps[]
    loading?: 'auto' | 'eager' | 'lazy'
    'data-testid'?: string
    useFallback?: boolean
    onLoad?: ReactEventHandler<HTMLSourceElement>
  }

export const Image = (props: ImageProps): ReactElement => {
  const {
    className,
    resolve,
    src,
    height,
    width,
    sources = [],
    'data-testid': testId,
    alt = '',
    loading = 'auto',
    q: parentQuality = 50,
    fit: parentFit = 'crop',
    crop: parentCrop = 'focalpoint',
    imgixParams,
    useFallback,
    onLoad,
  } = props

  const resolved = getImageUrl(src, { resolve })

  let loadingStyles = {}
  if (loading === 'lazy') {
    const bgUrl = buildURL(resolved, {
      w: width / 10 || DEFAULT_LAZY_LOADING_IMAGE_SIZE,
      h: height / 10 || DEFAULT_LAZY_LOADING_IMAGE_SIZE,
      q: 5,
      blur: 100,
    })

    loadingStyles = {
      backgroundImage: `url(${bgUrl})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
    }
  }

  const fallbackUrl = getImageUrl('mindful-chef-placeholder.png', {
    resolve: true,
  })

  const [error, setError] = useState(false)

  return (
    <Picture>
      <>
        {!!sources.length &&
          sources.map((source) => {
            const {
              media,
              height: sourceHeight,
              width: sourceWidth,
              crop = parentCrop,
              fit = parentFit,
              q = parentQuality,
              src: sourcePath = resolved,
              imgixParams: sourceImgixParams = {},
            } = source

            let resolveSourcePath = sourcePath
            if (resolveSourcePath !== resolved) {
              resolveSourcePath = getImageUrl(sourcePath, { resolve })
            }

            return (
              <Source
                key={media}
                src={error && useFallback ? fallbackUrl : resolveSourcePath}
                height={sourceHeight}
                width={sourceWidth}
                htmlAttributes={{
                  media,
                  onError: () => setError(true),
                }}
                imgixParams={{ ...sourceImgixParams, crop, fit, q }}
                disableLibraryParam
              />
            )
          })}
      </>
      <Imgix
        className={className}
        src={error && useFallback ? fallbackUrl : resolved}
        height={height}
        width={width}
        htmlAttributes={{
          alt,
          loading,
          'data-testid': testId,
          style: { ...loadingStyles },
          onLoad,
          onError: () => setError(true),
        }}
        imgixParams={{
          crop: parentCrop,
          fit: parentFit,
          q: parentQuality,
          ...imgixParams,
        }}
        disableLibraryParam
      />
    </Picture>
  )
}
