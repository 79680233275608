import React, { VoidFunctionComponent } from 'react'
import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg'

const Paths: VoidFunctionComponent<{ transform?: string }> = ({
  transform,
}) => (
  <g stroke="currentColor" fill="none" transform={transform}>
    <path
      d="M18.4706 9.82336L1 10.0881"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.41176 15.6471C5.98751 14.3285 3.7442 12.7454 1.73025 10.9321C1.37979 10.6704 1.12752 10.3136 1.00923 9.91221C0.91309 9.24709 1.58605 8.70021 2.19492 8.28635L8.3637 4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </g>
)

export const ArrowLeftIcon: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 20 20" {...props}>
    <Paths />
  </Svg>
)

export const ArrowRightIcon: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 20 20" {...props}>
    <Paths transform="rotate(180 10 10)" />
  </Svg>
)
