import styled from '@emotion/styled'

import { Box } from './Box'
import { createCSSMapper } from '../system/utils/createCSSMapper'
import { CSSMapperTransform, CSSPropMapper, Responsive } from '../system/types'

interface AspectRatioProps {
  ratio: Responsive<number>
}

const ratioTransform: CSSMapperTransform = ({ value }) => ({
  paddingBottom: `${(1 / value) * 100}%`,
})

export const aspectRatio: CSSPropMapper<AspectRatioProps> = {
  ratio: {
    themeKey: 'space',
    transform: ratioTransform,
  },
}

const [aspectRatioMapper, shouldForwardProp] = createCSSMapper(aspectRatio)

export const AspectRatio = styled(Box, { shouldForwardProp })<AspectRatioProps>`
  ${aspectRatioMapper};
`

AspectRatio.defaultProps = {
  position: 'relative',
}
