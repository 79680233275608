import { AuthenticationLoginCredential } from '@mindfulchefuk/api-client'

export interface UpdateLoginCredentialsParams {
  password: string
  customerId: string
  loginCredentialsId: string
}

export const updateLoginCredentials = async (
  params: UpdateLoginCredentialsParams
) => {
  const model = new AuthenticationLoginCredential({
    id: params.loginCredentialsId,
  })

  // setting isPersisted here forces a PATCH request on save
  model.isPersisted = true
  model.password = params.password
  model.customer_id = params.customerId

  await model.save()
}
