import React, { VoidFunctionComponent } from 'react'
import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg'

export const PoundIcon: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 21 20" {...props}>
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g stroke="currentColor">
        <path
          d="M15.906 3.29C11.12.531 6.673 2.464 6.673 6.462c0 4 1.366 8.365 0 10.19-1.365 1.824-3.144 1.762-3.144 0 0-1.763 3.797-2.693 6.188-1.347 1.595.898 3.658 1.346 6.189 1.346h1.74M10.588 9.997c0 .148-1.345.315-4.034.5"
          transform="translate(-1135.000000, -507.000000) translate(-89.200000, 158.000000) translate(1019.200000, 54.000000) translate(0.000000, 0.000000) translate(0.000000, 295.000000) translate(205.700000, 0.000000)"
        />
      </g>
    </g>
  </Svg>
)
