/* eslint-disable camelcase */
import { APIOrder, ShopOrder } from '@mindfulchefuk/query/ShopOrders/interfaces'
import { transformLineItem } from './transformLineItem'

export const transformShopOrder = ({
  delivery_date,
  id,
  line_items,
  shipping_price,
  cancellable,
  cancellation_deadline,
  status,
}: APIOrder): ShopOrder => ({
  cancellable,
  cancellationDeadline: cancellation_deadline,
  deliveryDate: delivery_date,
  lineItems: line_items.map(transformLineItem),
  orderId: id,
  shippingPrice: parseFloat(shipping_price),
  status,
})
