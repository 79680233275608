import styled from '@emotion/styled'

import { Box } from '../layout/Box'
import { colors, space, typography } from '../system'

interface Props {
  /** Styles the label as if it checked - used for RadioBox */
  'data-checked'?: string
  /** Styles the label as if it has a checkbox inside, used for CheckButton from Figma */
  'data-checked-inside'?: string
  /** Workaround for when using component as="button" in a form */
  type?: 'button'
  disabled?: boolean
}

/**
 * Used in the 'boxy' / 'button' style of Radio button / Checkbox
 * e.g. RadioBox, RadioListBox, CheckButton from Figma
 */
export const SelectionBox = styled(Box)<Props>`
  ${typography['body-lg-500']};
  user-select: none;
  display: block;
  text-align: left;
  padding: ${space[16]};
  border-radius: ${space[10]};
  background-color: ${colors.white};
  border: 1px solid ${colors['aubergine-200']};
  color: ${colors.aubergine};
  opacity: ${(props) => (props.disabled ? '.5' : '1')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  &:hover,
  &[data-checked-inside] {
    background: ${(props) =>
      props.disabled ? colors.white : colors['aubergine-50']};
  }

  &[data-checked],
  &[data-checked]:hover {
    background: ${colors.aubergine};
    color: ${colors.white};
  }
`

SelectionBox.defaultProps = {
  as: 'label',
}
