import { SpaceToken } from '../theme/space'
import { CSSPropMapper, ResponsiveProps } from '../types'

export type MarginProps = ResponsiveProps<{
  margin?: SpaceToken
  marginLeft?: SpaceToken
  marginRight?: SpaceToken
  marginTop?: SpaceToken
  marginBottom?: SpaceToken
  marginY?: SpaceToken
  marginX?: SpaceToken

  m?: SpaceToken
  ml?: SpaceToken
  mr?: SpaceToken
  mt?: SpaceToken
  mb?: SpaceToken
  my?: SpaceToken
  mx?: SpaceToken
}>

const config: CSSPropMapper<MarginProps> = {
  margin: { property: 'margin', themeKey: 'space' },
  marginTop: { property: 'marginTop', themeKey: 'space' },
  marginRight: { property: 'marginRight', themeKey: 'space' },
  marginBottom: { property: 'marginBottom', themeKey: 'space' },
  marginLeft: { property: 'marginLeft', themeKey: 'space' },
  marginY: { property: ['marginTop', 'marginBottom'], themeKey: 'space' },
  marginX: { property: ['marginLeft', 'marginRight'], themeKey: 'space' },
}

export const margin: CSSPropMapper<MarginProps> = {
  ...config,
  mt: config.marginTop,
  mr: config.marginRight,
  mb: config.marginBottom,
  ml: config.marginLeft,
  m: config.margin,
  my: config.marginY,
  mx: config.marginX,
}
