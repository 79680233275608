import React, { VoidFunctionComponent } from 'react'

import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg/Svg'

export const LinkIcon: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 30 30" {...props}>
    <circle cx="15" cy="15" r="15" fill="pepper" />
    <path
      d="M15.6499 13.7535V13.7535C14.5435 12.7282 12.8167 12.7888 11.7849 13.8893L8.05916 17.8635C7.01548 18.9767 7.10234 20.7334 8.25076 21.7383V21.7383C9.31947 22.6734 10.9302 22.6198 11.9343 21.6156L14.1499 19.4"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M13.97 15.9965V15.9965C15.0764 17.0218 16.8032 16.9612 17.8349 15.8607L21.5607 11.8865C22.6044 10.7733 22.5175 9.01659 21.3691 8.01172V8.01172C20.3004 7.07661 18.6897 7.13024 17.6856 8.13437L15.47 10.35"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </Svg>
)
