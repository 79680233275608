import { createReducer } from '@reduxjs/toolkit'

import {
  RECIPES_EXCLUDED_FOOD_GROUPS_SET,
  RECIPES_LOADING_SET,
  RECIPES_MEAL_PLAN_SET,
  RECIPES_RESET,
  RECIPES_SET,
  RECIPES_SORT,
} from '@mindfulchefuk/actions/recipesActions'

import { RecipesState } from '@mindfulchefuk/features/Recipes/interfaces'
import { sortRecipes } from '@mindfulchefuk/features/Recipes/utils/sortRecipes'

export const initialState: RecipesState = {
  allRecipes: [],
  selectedMealPlan: 'Balanced',
  excludedFoodGroups: [],
  sortedBy: 'customerFav',
  isPending: false,
  isFetched: false,
}

const recipesReducer = createReducer(initialState, {
  // seems to be the equivalent of toggleIsPending
  [RECIPES_LOADING_SET]: (state, action) => {
    const isPending = action.payload
    return {
      ...state,
      isPending,
      isFetched: !isPending,
    }
  },

  [RECIPES_SET]: (state, action) => {
    const allRecipes = action.payload
    return {
      ...state,
      allRecipes: sortRecipes(allRecipes, state.sortedBy),
      sortedBy: 'customerFav',
      isPending: false,
      isFetched: true,
    }
  },

  [RECIPES_MEAL_PLAN_SET]: (state, action) => ({
    ...state,
    selectedMealPlan: action.payload,
  }),

  [RECIPES_SORT]: (state, action) => ({
    ...state,
    sortedBy: action.payload,
    allRecipes: sortRecipes([...state.allRecipes], action.payload),
  }),

  [RECIPES_RESET]: () => ({
    ...initialState,
  }),

  [RECIPES_EXCLUDED_FOOD_GROUPS_SET]: (state, action) => ({
    ...state,
    excludedFoodGroups: action.payload,
  }),
})

export default recipesReducer
