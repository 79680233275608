import { DeliveriesReschedulableDate } from '@mindfulchefuk/api-client'
import errorHandler from '@mindfulchefuk/utils/errorHandler'
import { DeliveryReschedulableDate } from '@mindfulchefuk/query/Deliveries/interfaces'
import { getCustomerId } from '@mindfulchefuk/helpers/js/authentication'

export const getDeliveryReschedulableDates = async ({
  deliveryDate,
}: {
  deliveryDate: string
}): Promise<DeliveryReschedulableDate[]> => {
  const customerId = getCustomerId()
  try {
    const response = await DeliveriesReschedulableDate.where({
      delivery_date: { eq: deliveryDate },
      customer_id: { eq: customerId },
    }).all()

    return response.data
  } catch (error) {
    errorHandler(error)
  }
}
