import Cookies from 'universal-cookie'
import { AUTH_TOKEN, CUSTOMER_ID_COOKIE_KEY } from '@mindfulchefuk/constants'
import decode from 'jwt-decode'

export function getCustomerId() {
  if (typeof window === 'undefined') return false

  const cookies = new Cookies()
  return cookies.get(CUSTOMER_ID_COOKIE_KEY)
}

export function isAuthenticated() {
  const customerId = getCustomerId()
  return !!customerId
}

export function getAuthToken() {
  if (typeof window === 'undefined') return undefined

  return window.localStorage.getItem(AUTH_TOKEN)
}

export function isAdmin(): boolean {
  try {
    if (typeof window === 'undefined') return false

    const token = getAuthToken()
    const decodedToken = decode<{ admin_id?: number }>(token)
    return !!decodedToken?.admin_id
  } catch (error) {
    console.error(error)
    return false
  }
}
