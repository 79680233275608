import { atomWithQuery } from 'jotai-tanstack-query'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { Customer } from '@mindfulchefuk/query/Customer/interfaces'
import { getCustomer } from '@mindfulchefuk/query/Customer/services'
import { STALE_15_MINS } from '@mindfulchefuk/constants'
import { customerIdAtom } from '@mindfulchefuk/store/customer'

export const useQueryCustomer = <TData = Customer>(
  customerId: string,
  options: UseQueryOptions<Customer, unknown, TData> = {}
) => {
  return useQuery({
    queryKey: ['customer', { customerId }],
    queryFn: () => getCustomer(customerId),
    enabled: !!customerId,
    staleTime: STALE_15_MINS,
    ...options,
  })
}

export const customerAtom = atomWithQuery((get) => ({
  queryKey: ['customer', get(customerIdAtom)],
  queryFn: () => getCustomer(get(customerIdAtom)),
  enabled: !!customerIdAtom,
  staleTime: STALE_15_MINS,
}))
