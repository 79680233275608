import Cookies from 'universal-cookie'
import { addDays } from 'date-fns'

const generateDeviceId = () => {
  return Math.random().toString(36).substring(2)
}

const maxCookieExpiryDate = (): Date => {
  const browserMaxCookieDurationDays = 400
  return addDays(new Date(), browserMaxCookieDurationDays)
}

export const generateGrowthBookDeviceId = (cookies: Cookies) => {
  let mcDeviceId = cookies.get('mc_growthbook_device_id')

  if (!mcDeviceId) {
    mcDeviceId = generateDeviceId()
    cookies.set('mc_growthbook_device_id', mcDeviceId, {
      expires: maxCookieExpiryDate(),
    })
  }

  return mcDeviceId
}
