/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RecipeFilter, RecipeFiltersState } from './interfaces'

const initialState: RecipeFiltersState = {
  mealPlan: null,
  excludedFoodGroups: null,
  portionCount: null,
  sortedBy: null,
}

const recipeFilterSlice = createSlice({
  name: 'recipeFilters',
  initialState,
  reducers: {
    setRecipeFilter: <F extends RecipeFilter>(
      state: RecipeFiltersState,
      action: PayloadAction<{ filter: F; value: RecipeFiltersState[F] }>
    ) => {
      state[action.payload.filter] = action.payload.value
    },
    resetFilters: () => initialState,
  },
})

export const { resetFilters, setRecipeFilter } = recipeFilterSlice.actions
export const recipeFiltersReducer = recipeFilterSlice.reducer
