import React, { VoidFunctionComponent } from 'react'

import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '@mindfulchefuk/design-system/Svg/Svg'

export const DiscountIcon: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 20 20" {...props}>
    <g fillRule="evenodd">
      <path
        fill="currentColor"
        d="M9.64812 18.6535C7.44596 16.3017 4.56655 13.3355 2.3225 11.0256C2.1765 10.874 2.08724 10.6767 2.06975 10.467C1.91754 8.10128 1.96781 5.72159 2.69814 3.43965C2.78472 3.15197 3.1813 2.80702 3.47874 2.72323C4.80673 2.24841 6.21572 2.1716 7.58561 2.06546C8.76558 2.06826 9.90645 1.82666 11.0487 2.2512C12.3991 3.53601 15.7533 6.78994 17.031 8.06497C17.4709 8.53281 18.3269 9.34839 18.729 9.84136C19.1312 10.3343 19.0697 11.3775 18.6927 11.9529C18.183 12.7908 16.4584 14.2907 15.7812 15.0253C14.4658 16.3129 13.2076 17.6606 11.8447 18.9007C11.8088 18.9332 11.7669 18.9584 11.7213 18.9748C11.6758 18.9911 11.6274 18.9984 11.579 18.996C11.5307 18.9937 11.4832 18.9818 11.4394 18.9612C11.3957 18.9405 11.3564 18.9114 11.3238 18.8755C11.2627 18.8072 11.2289 18.7187 11.2289 18.627C11.2289 18.5352 11.2627 18.4467 11.3238 18.3784C12.5569 17.014 13.896 15.7557 15.1779 14.4388L17.119 12.4836C17.8325 11.8216 18.3171 11.2561 18.123 10.4935L18.0881 10.3204C18.1314 10.4726 18.1048 10.379 18.0769 10.3581C16.1038 8.16971 12.6714 4.95629 10.5516 2.84892C8.8759 2.72183 6.36234 2.80982 4.48276 3.21621C3.98843 3.34608 3.44523 3.45781 3.39076 3.75667C2.75958 5.69505 2.72747 7.93509 2.77076 9.97543C2.78332 10.2017 2.83499 10.562 2.86432 10.5075C3.21761 10.8678 5.27314 13.0017 5.67391 13.4123C7.15551 14.9373 8.74603 16.5866 10.2821 18.0027C10.7373 18.4133 10.0782 19.1018 9.64812 18.6535Z"
      />
      <path
        fill="currentColor"
        d="M5.19069 6.1895C5.03154 6.18951 4.87485 6.15027 4.73449 6.07525C4.59413 6.00023 4.47444 5.89175 4.38601 5.75942C4.29759 5.62708 4.24317 5.47498 4.22756 5.31659C4.21196 5.1582 4.23566 4.9984 4.29656 4.85135C4.35746 4.70431 4.45369 4.57455 4.57671 4.47358C4.69973 4.37261 4.84576 4.30355 5.00185 4.2725C5.15793 4.24145 5.31927 4.24938 5.47156 4.29559C5.62386 4.3418 5.7624 4.42485 5.87493 4.5374C6.01026 4.67275 6.10241 4.84519 6.13975 5.03291C6.17708 5.22064 6.15792 5.41522 6.08468 5.59205C6.01144 5.76889 5.88741 5.92003 5.72829 6.02637C5.56916 6.13272 5.38207 6.18949 5.19069 6.1895ZM5.19069 4.65331C5.06167 4.65406 4.93689 4.69945 4.83755 4.78177C4.7382 4.86409 4.67041 4.97826 4.64569 5.1049C4.62098 5.23154 4.64086 5.36283 4.70196 5.47647C4.76306 5.59011 4.86161 5.67909 4.98088 5.72831C5.10014 5.77752 5.23276 5.78392 5.35621 5.74643C5.47966 5.70894 5.58633 5.62987 5.6581 5.52264C5.72986 5.41542 5.7623 5.28665 5.74989 5.15822C5.73749 5.02979 5.68102 4.90961 5.59006 4.81811C5.48233 4.71619 5.33896 4.66054 5.19069 4.66309V4.65331Z"
      />
      <path
        fill="currentColor"
        d="M10.2039 15.1499C10.1732 12.0049 9.35487 8.81938 10.0922 5.70511C10.2234 5.29872 10.8644 5.44256 10.8113 5.86292C10.5321 8.88502 10.5181 12.0468 11.106 15.034C11.2931 15.7322 10.2011 15.8719 10.2039 15.1499Z"
      />
      <path
        fill="currentColor"
        d="M7.33002 11.7325C6.96078 11.732 6.60314 11.6035 6.31802 11.3689C6.0329 11.1342 5.83794 10.808 5.76636 10.4457C5.69479 10.0835 5.75101 9.70757 5.92546 9.38211C6.09992 9.05666 6.3818 8.80176 6.7231 8.66084C7.0644 8.51992 7.44399 8.5017 7.79721 8.60929C8.15044 8.71687 8.45543 8.94361 8.66025 9.25086C8.86506 9.55812 8.95702 9.92689 8.92046 10.2943C8.88389 10.6618 8.72107 11.0052 8.45973 11.2661C8.31143 11.4144 8.13529 11.532 7.94142 11.612C7.74755 11.6921 7.53977 11.733 7.33002 11.7325ZM7.33002 9.35841C7.17613 9.35833 7.02567 9.40391 6.89769 9.48938C6.76971 9.57484 6.66995 9.69636 6.61105 9.83855C6.55215 9.98073 6.53675 10.1372 6.56679 10.2881C6.59683 10.4391 6.67098 10.5777 6.77983 10.6865C6.92813 10.8279 7.12514 10.9068 7.33002 10.9068C7.5349 10.9068 7.73192 10.8279 7.88021 10.6865C7.98907 10.5777 8.06321 10.4391 8.09326 10.2881C8.1233 10.1372 8.1079 9.98073 8.049 9.83855C7.99009 9.69636 7.89034 9.57484 7.76236 9.48938C7.63437 9.40391 7.48392 9.35833 7.33002 9.35841Z"
      />
      <path
        fill="currentColor"
        d="M13.4855 12.4737C13.1163 12.4732 12.7587 12.3447 12.4735 12.1101C12.1884 11.8754 11.9935 11.5492 11.9219 11.1869C11.8503 10.8247 11.9065 10.4488 12.081 10.1233C12.2554 9.79787 12.5373 9.54297 12.8786 9.40205C13.2199 9.26113 13.5995 9.24291 13.9527 9.3505C14.3059 9.45809 14.6109 9.68482 14.8158 9.99207C15.0206 10.2993 15.1125 10.6681 15.076 11.0356C15.0394 11.403 14.8766 11.7464 14.6152 12.0073C14.467 12.1557 14.2909 12.2733 14.097 12.3534C13.9031 12.4334 13.6953 12.4743 13.4855 12.4737ZM13.4855 10.0996C13.3318 10.0998 13.1815 10.1456 13.0537 10.2312C12.926 10.3167 12.8264 10.4383 12.7677 10.5804C12.709 10.7225 12.6937 10.8789 12.7238 11.0297C12.7539 11.1805 12.828 11.319 12.9367 11.4277C13.0841 11.5703 13.2812 11.65 13.4862 11.65C13.6913 11.65 13.8883 11.5703 14.0357 11.4277C14.1446 11.3189 14.2187 11.1803 14.2488 11.0294C14.2788 10.8784 14.2634 10.7219 14.2045 10.5798C14.1456 10.4376 14.0459 10.3161 13.9179 10.2306C13.7899 10.1451 13.6394 10.0995 13.4855 10.0996Z"
      />
    </g>
  </Svg>
)
