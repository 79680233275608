import React, { VoidFunctionComponent } from 'react'

import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg'

export const ArrowSlightlyCurvedIcon: VoidFunctionComponent<BoxProps> = (
  props
) => (
  <Svg viewBox="0 0 20 20" {...props} fill="none">
    <path
      d="M17.8679 11.4072C16.7619 10.9944 15.8487 10.5227 12.8387 10.108C9.36835 9.62992 3.31325 10.0241 1.52172 10.3077"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.05711 6.86195C3.8184 7.75725 2.69122 8.79748 1.69958 9.9605C1.52331 10.1254 1.40864 10.3455 1.37457 10.5845C1.34965 10.9782 1.73934 11.2735 2.08962 11.484L5.61991 13.6862"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)
