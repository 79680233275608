import { ShopOrder, ShopLineItem } from '@mindfulchefuk/api-client'
import { ApiError } from '@mindfulchefuk/utils/spraypaintUtils'
import errorHandler from '@mindfulchefuk/utils/errorHandler'
import {
  ShopOrder as ShopOrderType,
  ShopOrderStatus,
} from '@mindfulchefuk/query/ShopOrders/interfaces'
import { LINE_ITEMS } from '@mindfulchefuk/features/ShopOrder/constants'
import { transformShopOrder } from '@mindfulchefuk/query/ShopOrders/transforms'

type PendingLineItem = {
  quantity: number
  productId: string
}

type BulkCreateParams = {
  lineItems?: PendingLineItem[]
  customerId: string
  deliveryAddressId: string
  deliveryDate: string
}

export const createShopOrder = async ({
  lineItems,
  customerId,
  deliveryAddressId,
  deliveryDate,
}: BulkCreateParams): Promise<ShopOrderType> => {
  try {
    const shopOrder = new ShopOrder({
      customer_id: customerId,
      delivery_address_id: deliveryAddressId,
      delivery_date: deliveryDate,
      status: ShopOrderStatus.BASKET,
      line_items: lineItems?.map(
        (lineItem) =>
          new ShopLineItem({
            product_id: lineItem.productId,
            quantity: lineItem.quantity,
          })
      ),
    })

    const results = await shopOrder.save({
      with: lineItems ? LINE_ITEMS : undefined,
    })

    if (!results) {
      throw new ApiError(shopOrder)
    }

    return transformShopOrder(shopOrder)
  } catch (error) {
    errorHandler(error)
  }
}
