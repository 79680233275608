import React, { VoidFunctionComponent } from 'react'

import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg'

export const RecipesIcon: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg width="16" height="21" viewBox="0 0 16 21" {...props}>
    <path
      d="m1.37 15.91.22-14.28a66.07 66.07 0 0 1 10.62 0 3 3 0 0 1 1.89.59 2.93 2.93 0 0 1 .65 2.12c.09 2.95.08 5.89 0 8.84a3.31 3.31 0 0 1-.61 2.16 3.47 3.47 0 0 1-2.69.74L4.5 16c.052-4.214 0-8.427-.16-12.64M6 18c2.83 0 4.86-.15 7.68-.39M1.36 16.33a1.77 1.77 0 0 0 .22 1.81M2.57 16.099l2.4-.1M2.75 17.08a6 6 0 0 0-.1 2.45 2 2 0 0 1 .72-1.37l.62 1.46a7.44 7.44 0 0 1 1.13-2.46l-.06.1"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="transparent"
    />
  </Svg>
)
