import React, { VoidFunctionComponent } from 'react'

import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg'

export const DuckIcon: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 20 20" {...props}>
    <g
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M4.86,16.4l.51-1.28A39.49,39.49,0,0,1,7,11.39a3.66,3.66,0,0,1,1-1.27,3.48,3.48,0,0,1,2.63-.55,3.55,3.55,0,0,1,2.2,1.28,5.12,5.12,0,0,1,.56,1l2.49,5.32a1.08,1.08,0,0,1,.16.77,1.06,1.06,0,0,1-.62.59,3.94,3.94,0,0,1-1,.24,29.31,29.31,0,0,1-8.52.06,3.85,3.85,0,0,1-1.2-.31" />
      <path d="M3.75,14.76A2.29,2.29,0,0,1,2.84,13a6.78,6.78,0,0,1,.25-2l1-4.11A8.34,8.34,0,0,1,5.58,3.19a6.4,6.4,0,0,1,4.74-1.9,7.66,7.66,0,0,1,2.93.46,4.69,4.69,0,0,1,2.29,1.79A7.51,7.51,0,0,1,16.38,6l.95,4.51a6.66,6.66,0,0,1,.18,2.87,3.46,3.46,0,0,1-2,2.33" />
      <path d="M11.12,12.45c.1.31.31.75.46,1.08" />
      <path d="M9.09,12.45c-.1.31-.32.75-.46,1.08" />
    </g>
  </Svg>
)
