import { FEATURE_FLAGS_KEY } from '@mindfulchefuk/constants'

/**
 * Check the URL for any params with the prefix features.XXX
 * If found, set the suffix and the value ask key value pair to localstorage
 *
 * Ex: features.sca_onboarding=true => sca_onboarding: 'true'
 *
 * NOTE: we want to support true/false/undefined as
 * undefined can indicate we are A/B testing this feature
 */
const setURLParamsToLocalStorage = () => {
  const searchParams = new URLSearchParams(window.location.search)
  searchParams.forEach((value, key) => {
    const [prefix] = key.split('.')
    if (prefix === FEATURE_FLAGS_KEY) {
      if (value !== 'true' && value !== 'false')
        /* eslint-disable no-console */
        console.warn(
          `Warning! You are setting your feature flag value to something other than true or false`
        )
      window.localStorage.setItem(key, value)
    }
  })
}

const setLocalStorageFeaturesToGlobal = () => {
  Object.keys(window.localStorage).forEach((key: string) => {
    const [prefix, flagKey] = key.split('.')
    if (prefix === FEATURE_FLAGS_KEY) {
      window.mc.features[flagKey] = window.localStorage.getItem(key) === 'true'
    }
  })
}

const init = () => {
  if (
    typeof window !== 'undefined' &&
    typeof window.localStorage !== 'undefined' &&
    window.mc?.features
  ) {
    setURLParamsToLocalStorage()
    setLocalStorageFeaturesToGlobal()
  }
}

init()
