import { useEffect } from 'react'
import { useRouter } from 'next/router'

import { removeSensitiveData } from '@mindfulchefuk/utils/removeSensitiveData'

export const REFERRER_PATH_KEY = 'referrerPath'

// Used to shim the referrer path into session storage
// We use the referrer path to know which page the user was previous on
// As our app is a SPA, document.referrer can't be reliably used

// This useEffect returns a function to be run on unmount
// which is how this captures the previous path correctly
export const useReferrer = () => {
  const router = useRouter()

  useEffect(() => {
    return () => {
      const storage = window.sessionStorage
      if (!storage) return

      storage.setItem(REFERRER_PATH_KEY, removeSensitiveData(router.asPath))
    }
  }, [router.asPath])
}
