import React, { VoidFunctionComponent } from 'react'
import Illustration from '@mindfulchefuk/design-system/Illustrations/Illustrations'

//layout for delivery details pages.
export const SectionBackground15: VoidFunctionComponent = () => (
  <>
    <Illustration
      type="cross-section-pomegranate"
      top={-16}
      right={{
        base: -128,
        sm: -32,
        lg: -32,
      }}
      transform="scale(0.8) rotate(-0deg)"
    />
    <Illustration
      type="cross-section-pumpkin-02"
      top={['580px', '400px']}
      left={{ base: '-64px', md: -32, lg: -80 }}
      transform="rotate(90deg)"
    />
    <Illustration
      type="cross-section-kale"
      top="750px"
      right={{ base: '-115px', md: '-95px', lg: '-100px', xl: '-160px' }}
    />
    <Illustration
      type="cross-section-courgette"
      top="850px"
      left={{ base: -240, md: '-217px', lg: '-250px' }}
      transform="scale(0.32)"
    />
    <Illustration
      type="cross-section-apricot"
      top="1800px"
      right={{ base: '-113px', md: '-95px', lg: '-100px', xl: '-160px' }}
    />
    <Illustration
      type="cross-section-red-onion"
      top="2150px"
      right={{ base: '-89px', md: '-66px', lg: '-100px', xl: '-170px' }}
    />
  </>
)
