import { useEffect, useRef, useState } from 'react'

export const useIsSticky = () => {
  const ref = useRef<HTMLDivElement>(null)
  const [isSticky, setIsSticky] = useState(false)

  useEffect(() => {
    if (typeof IntersectionObserver === 'undefined' || !ref.current) return

    const options: IntersectionObserverInit = {
      root: null,
      threshold: 1.0, // 100% of the object should be visible and we trick it by doing top: -1px
    }

    // eslint-disable-next-line compat/compat
    const observer = new IntersectionObserver((entries) => {
      setIsSticky(entries[0].intersectionRatio < 1)
    }, options)

    observer.observe(ref.current)

    return () => {
      observer.disconnect()
    }
  }, [])

  return { ref, isSticky }
}
