import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { theme } from '@mindfulchefuk/design-system/system'

export const baseInputStyles = {
  backgroundColor: theme.colors.white,
  border: 'solid 1px currentColor',
  borderRadius: `${theme.space[10]}`,
  color: theme.colors.aubergine,
  height: '60px',
  width: '100%',
  padding: `${theme.space[16]} ${theme.space[40]} ${theme.space[16]} ${theme.space[24]}`,
  ...theme.typography['body-lg'],
}

export const inputReadOnlyStyles = { color: theme.colors['aubergine-300'] }

export const inputFocusStyles = {
  outline: 'none',
  boxShadow: 'inset 0 0 0 1px currentColor',
}

export const inputInvalidStyles = {
  color: theme.colors.tomato,
  borderColor: theme.colors.tomato,
  borderWidth: 2,
}

export const inputPlaceholderStyles = {
  base: {
    color: theme.colors['aubergine-300'],
  },
}

export const inputStyles = css`
  ${baseInputStyles};

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    border-color: ${theme.colors.aubergine};
    -webkit-text-fill-color: ${theme.colors.aubergine};
    // Workaround to disable auto background colour on autofilled fields
    transition: background-color 5000s ease-in-out 0s;
  }

  &:-webkit-autofill:focus {
    box-shadow: inset 0 0 0 1px ${theme.colors.aubergine};
  }

  &:read-only:not(select) {
    ${inputReadOnlyStyles};
  }

  &::placeholder {
    ${inputPlaceholderStyles.base}
  }

  &:focus,
  &:active {
    ${inputFocusStyles};
  }

  &[aria-invalid='true'] {
    ${inputInvalidStyles};
  }

  &[type='search'] {
    appearance: textfield;

    &::-webkit-search-cancel-button {
      display: none;
    }
  }
`

export const a11yHidden = css`
  appearance: none;
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
`

export const HiddenInput = styled.input`
  ${a11yHidden}
`

export const resetFormControl = css`
  border: 0;
  margin: 0;
  padding: 0;
`

export const FormControlReset = styled.div<{ as?: string }>`
  ${resetFormControl}
`
