import React, { VoidFunctionComponent } from 'react'
import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg'

export const ChatIcon: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 20 20" {...props}>
    <g fill="none">
      <path
        d="M4.51911 14.6709L2.02611 17.5059C1.97211 14.2839 1.90011 11.0529 1.70211 7.83095C1.61499 6.83786 1.6452 5.83797 1.79211 4.85195C1.95231 3.85466 2.47858 2.95293 3.26811 2.32295C4.39231 1.69334 5.68883 1.44097 6.96711 1.60295L15.4001 1.77395C16.3037 1.69164 17.2076 1.92717 17.9561 2.43995C18.7751 3.17795 18.8021 4.42895 18.7751 5.52695L18.6311 11.0349C18.6702 11.7026 18.5271 12.3683 18.2171 12.9609C17.646 13.6355 16.837 14.0642 15.9581 14.1579C12.7391 14.8489 9.41633 14.907 6.17511 14.3289"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </Svg>
)
