import React, { VoidFunctionComponent } from 'react'

import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg/Svg'

export const RecycleIcon: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 17 16" {...props}>
    <g stroke="currentColor" fill="none">
      <path d="M5.875 3.75S7.21.525 8.65.547c1.44.023 4.133 5.55 4.133 5.55.23-.424.416-.872.555-1.335.06-.275.096-.557.105-.84" />
      <path d="M10.547 5.25c.308.19.626.363.953.518a4.22 4.22 0 001.215.337m1.56 2.145s2.205 2.7 1.5 3.953c-.705 1.252-6.84 1.02-6.84 1.02.265.402.572.777.915 1.117.213.185.445.348.69.488" />
      <path d="M10.75 11.64a9.68 9.68 0 00-.885.577 4.25 4.25 0 00-.878.915m-2.444.046s-4.32.412-4.988-.856C.888 11.056 4.413 6 4.413 6c-.483.005-.963.06-1.433.165-.26.079-.51.181-.75.308" />
      <path d="M4.75 8.385c.02-.352.02-.705 0-1.058a4.087 4.087 0 00-.292-1.23" />
    </g>
  </Svg>
)
