import { SpraypaintBase } from 'spraypaint'
import { ProductWithQuantity } from '@mindfulchefuk/query/Products/interfaces'
import { ISODate } from '@mindfulchefuk/lib/toISODateFormat'
import { FetchState } from '@mindfulchefuk/types/fetch.d'

/**
 * Order statuses from the API
 *
 * @see https://mindfulchef.postman.co/workspace/MindfulChef-API~3051f434-fb9c-495a-8ebb-bdf416f51665/folder/7544997-ed6ef5db-1a38-4c83-a016-657ba0f8ae68?ctx=documentation
 */
export enum ShopOrderStatus {
  BASKET = 'basket',
  CANCELLED = 'cancelled',
  COMPLETED = 'completed',
  SUBMITTED = 'submitted',
  SUCCESS = 'success',
}

export type PendingLineItem = {
  quantity: number
  productId: string
}

export interface LineItem {
  lineItemId?: string
  productId: string
  quantity: number
  unitPrice?: number
}

export type LineItems = LineItem[]

export interface ShopOrder {
  cancellable?: boolean
  cancellationDeadline?: ISODate
  deliveryDate: ISODate
  lineItems?: LineItems
  orderId: string
  shippingPrice: number
  status: ShopOrderStatus
}

export interface ShopOrderWithProducts extends ShopOrder {
  totalPrice: number
  totalPriceWithShipping: number
  totalProducts: number
  products: ProductWithQuantity[]
}

export type ShopOrders = ShopOrder[]

export interface ShopOrderAndProducts {
  order: ShopOrder
  products: ProductWithQuantity[]
}

interface ShopOrderHook extends ShopOrder {
  cartShippingTotal: number
  fetchState: FetchState
  shopOrderTotal: number
  products: ProductWithQuantity[]
  isUneditable?: boolean
}

export interface ShopOrderBasketHook extends ShopOrderHook {
  orderIsCompleted: boolean
  submitOrderError: unknown
  updateProductQuantityFromOrder: (productId: string, quantity: number) => void
  removeProductFromOrder: (productId: string) => void
  removeProductCompletelyFromOrder: (productId: string) => void
  resetOrder: () => void
  submitOrder: (date: ISODate | Date) => void
}

export interface APILineItem {
  id: string
  product_id: string
  quantity: number
  unit_price: string
  isMarkedForDestruction?: boolean
}

export interface APIOrder extends SpraypaintBase {
  customer_id: string
  cancellable: boolean
  cancellation_deadline: ISODate
  delivery_address_id: string
  delivery_date: ISODate
  id: string
  line_items: APILineItem[]
  shipping_price: string
  status: ShopOrderStatus
  trigger_payment?: boolean
}
