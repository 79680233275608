import { CouriersAvailability } from '@mindfulchefuk/api-client'

export type LowercaseDay =
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday'

export type CourierAvailabilities = {
  availableCouriers: Record<LowercaseDay, string[]>
  defaultCouriers: Record<LowercaseDay, string[]>
}

export const getCourierAvailabilities = async (
  postcode: string
): Promise<CourierAvailabilities> => {
  const { data: couriersData } = await CouriersAvailability.where({
    postcode_prefix: { match: postcode },
  }).all()

  const {
    attributes: {
      monday_available_couriers: mondayAvailableCouriers,
      tuesday_available_couriers: tuesdayAvailableCouriers,
      wednesday_available_couriers: wednesdayAvailableCouriers,
      thursday_available_couriers: thursdayAvailableCouriers,
      friday_available_couriers: fridayAvailableCouriers,
      saturday_available_couriers: saturdayAvailableCouriers,
      sunday_available_couriers: sundayAvailableCouriers,
      monday_default_courier: mondayDefaultCourier,
      tuesday_default_courier: tuesdayDefaultCourier,
      wednesday_default_courier: wednesdayDefaultCourier,
      thursday_default_courier: thursdayDefaultCourier,
      friday_default_courier: fridayDefaultCourier,
      saturday_default_courier: saturdayDefaultCourier,
      sunday_default_courier: sundayDefaultCourier,
    },
  } = couriersData[0]

  return {
    availableCouriers: {
      monday: mondayAvailableCouriers,
      tuesday: tuesdayAvailableCouriers,
      wednesday: wednesdayAvailableCouriers,
      thursday: thursdayAvailableCouriers,
      friday: fridayAvailableCouriers,
      saturday: saturdayAvailableCouriers,
      sunday: sundayAvailableCouriers,
    },
    defaultCouriers: {
      monday: mondayDefaultCourier,
      tuesday: tuesdayDefaultCourier,
      wednesday: wednesdayDefaultCourier,
      thursday: thursdayDefaultCourier,
      friday: fridayDefaultCourier,
      saturday: saturdayDefaultCourier,
      sunday: sundayDefaultCourier,
    },
  }
}
