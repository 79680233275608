const sessionStorageKey = 'trackedExperiments'
let experimentAndVariants = ''
let hasDoneTheSetup = false

function onBeforeUnload() {
  window.sessionStorage.setItem(sessionStorageKey, experimentAndVariants)
  window.removeEventListener('beforeunload', onBeforeUnload)
}

function doSetup() {
  experimentAndVariants = window.sessionStorage.getItem(sessionStorageKey) || ''
  hasDoneTheSetup = true

  window.sessionStorage.removeItem(sessionStorageKey)

  window.addEventListener('beforeunload', onBeforeUnload)
}

export const amplitudeExperimentTracker = {
  setExperimentString(experimentId: string, variant: string) {
    if (!hasDoneTheSetup) doSetup()

    if (experimentAndVariants.includes(`${experimentId}:${variant}`))
      return { experimentAndVariants, hasStringChanged: false }

    if (experimentAndVariants) {
      // if there are other experiments running, append the experimentVariant string
      if (experimentAndVariants.includes(experimentId)) {
        // check if the id exists, remove substring
        const matchExperimentIdAndVariant = new RegExp(`${experimentId}:.`)
        experimentAndVariants = experimentAndVariants.replace(
          matchExperimentIdAndVariant,
          ''
        )
      }
      //replace here
      experimentAndVariants = `${experimentAndVariants} ${experimentId}:${variant}`
    } else {
      //no experiments being tracked simply assign the formatted string
      experimentAndVariants = `${experimentId}:${variant}`
    }
    return { experimentAndVariants, hasStringChanged: true }
  },
  getExperimentString() {
    if (!hasDoneTheSetup) doSetup()
    return experimentAndVariants
  },
  resetExperiments() {
    experimentAndVariants = ''
  },
  reset() {
    /** Warning, this method should be only used for testing **/
    experimentAndVariants = ''
    hasDoneTheSetup = false
    window.sessionStorage.removeItem(sessionStorageKey)
    window.removeEventListener('beforeunload', onBeforeUnload)
  },
}
