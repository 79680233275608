import React, { VoidFunctionComponent } from 'react'
import Illustration from '@mindfulchefuk/design-system/Illustrations/Illustrations'

//layout for choose-recipes March 2022
export const SectionBackground07: VoidFunctionComponent = () => (
  <>
    <Illustration
      type="cross-section-pomegranate"
      top="-25px"
      right={{
        base: '-175px',
        md: '-150px',
        lg: '-200px',
        xl: '-210px',
      }}
      transform="scale(0.81) rotate(-15deg)"
    />
    <Illustration
      type="cross-section-pumpkin-02"
      top={['780px', '600px']}
      left={{ base: '-64px', md: -32, lg: -80 }}
      transform="rotate(90deg)"
    />
    <Illustration
      type="cross-section-kale"
      top="950px"
      right={{ base: '-115px', md: '-95px', lg: '-100px', xl: '-160px' }}
    />
    <Illustration
      type="cross-section-courgette"
      top="1450px"
      left={{ base: -240, md: '-217px', lg: '-250px' }}
      transform="scale(0.32)"
    />
    <Illustration
      type="cross-section-apricot"
      top="2000px"
      right={{ base: '-113px', md: '-95px', lg: '-100px', xl: '-160px' }}
    />
    <Illustration
      type="cross-section-red-onion"
      top="2350px"
      right={{ base: '-89px', md: '-66px', lg: '-100px', xl: '-170px' }}
    />
    <Illustration
      type="cross-section-squash-one"
      top="3150px"
      left={{ base: '-150px', md: '-130px', lg: '-150px', xl: '-170px' }}
    />
    <Illustration
      type="cross-section-garlic"
      top="3800px"
      left={{ base: '-55px', md: '-35px', lg: '-100px', xl: '-150px' }}
      transform="rotate(15deg)"
    />
    <Illustration
      type="cross-section-cucumber"
      top="3950px"
      right={{ base: '-120px', md: '-100px', lg: '-140px', xl: '-170px' }}
    />
    <Illustration
      type="cross-section-sweet-corn-1"
      top="4700px"
      left={{ base: '-35px', md: '-20px', lg: '-60px', xl: '-120px' }}
    />
    <Illustration
      type="cross-section-sweet-corn-2"
      top="4800px"
      left={{ base: '-30px', md: '-15px', lg: '-50px', xl: '-120px' }}
    />
    <Illustration
      type="cross-section-sweet-corn-3"
      top="4900px"
      left={{ base: '-35px', md: '-20px', lg: '-55px', xl: '-120px' }}
      transform="rotate(180deg)"
    />
    <Illustration
      type="cross-section-aubergine-sliced"
      top="4850px"
      right={{ base: '-65px', md: '-45px', lg: '-100px', xl: '-150px' }}
      transform="rotate(-15deg)"
    />
    <Illustration
      type="cross-section-beetroot-one"
      top="5600px"
      right={{ base: '-125px', md: '-100px', lg: '-140px', xl: '-150px' }}
    />
    <Illustration
      type="cross-section-strawberry"
      top="6150px"
      left={{ base: '-65px', md: '-50px', lg: '-80px', xl: '-130px' }}
    />
    <Illustration
      type="cross-section-chestnut"
      top="6500px"
      right={{ base: '-65px', md: '-50px', lg: '-110px', xl: '-150px' }}
    />
    <Illustration
      type="cross-section-aubergine-sliced-1"
      top="7280px"
      left={{ base: '-130px', md: '-110px', lg: '-130px', xl: '-160px' }}
    />
    <Illustration
      type="cross-section-raspberry"
      top="8000px"
      left={{ base: '-20px', md: '-25px', lg: '-50px', xl: '-130px' }}
    />
    <Illustration
      type="chopped-lemon-three"
      top="8050px"
      right={{ base: '-85px', md: '-70px', lg: '-100px', xl: '-150px' }}
      transform="rotate(-90deg)"
    />
    <Illustration
      type="cross-section-penne-2"
      top="8500px"
      right={{ base: '-75px', md: '-60px', lg: '-100px', xl: '-150px' }}
      transform="rotate(-140deg)"
    />
    <Illustration
      type="cross-section-penne-1"
      top="8600px"
      right={{ base: '-55px', md: '-50px', lg: '-100px', xl: '-150px' }}
      transform="rotate(48deg)"
    />
    <Illustration
      type="cross-section-carrot"
      top="9300px"
      left={{ base: '-70px', md: '-50px', lg: '-80px', xl: '-130px' }}
    />
    <Illustration
      type="courgette-diced-3"
      top="9470px"
      right={{ base: '-65px', md: '-50px', lg: '-100px', xl: '-150px' }}
    />
    <Illustration
      type="chopped-red-onion-diced-2"
      top="9750px"
      right={{ base: '-180px', md: '-160px', lg: '-190px', xl: '-210px' }}
    />
    <Illustration
      type="chopped-cucumber-diced-2"
      top="10200px"
      left={{ base: '-70px', md: '-50px', lg: '-90px', xl: '-130px' }}
    />
    <Illustration
      type="cross-section-pumpkin-02"
      top="10800px"
      right={{ base: '-50px', lg: '-80px', xl: '-150px' }}
      transform="rotate(-100deg)"
    />
    <Illustration
      type="cross-section-radish"
      top="11500px"
      right={{ base: '-50px', lg: '-80px', xl: '-140px' }}
    />
    <Illustration
      type="cross-section-edamame-bean-1"
      top="11850px"
      left={{ base: '-20px', lg: '-55px', xl: '-115px' }}
    />
    <Illustration
      type="cross-section-edamame-bean-2"
      top="11920px"
      left={{ base: '-15px', lg: '-50px', xl: '-110px' }}
    />
    <Illustration
      type="cross-section-edamame-bean-3"
      top="11990px"
      left={{ base: '-20px', lg: '-55px', xl: '-115px' }}
    />
    <Illustration
      type="chopped-beetroot-one"
      top="12400px"
      right={{ base: '-70px', lg: '-100px', xl: '-170px' }}
      transform="rotate(55deg)"
    />
    <Illustration
      type="cross-section-apricot"
      top="12890px"
      left={{ base: '-115px', lg: '-125px', xl: '-150px' }}
    />
    <Illustration
      type="chopped-broccoli-03"
      top="13400px"
      right={{ base: '-60px', md: '-40px', lg: '-110px', xl: '-150px' }}
      transform="scale(0.8)"
    />
    <Illustration
      type="cross-section-chestnut"
      top="14000px"
      left={{ base: '-60px', md: '-50px', lg: '-100px', xl: '-150px' }}
      transform="rotate(45deg)"
    />
    <Illustration
      type="cross-section-strawberry"
      top="14500px"
      left={{ base: '-65px', md: '-50px', lg: '-80px', xl: '-130px' }}
    />
    <Illustration
      type="chopped-carrot-twirl"
      top="14950px"
      right={{ base: '-30px', md: '-20px', lg: '-80px', xl: '-120px' }}
      transform="rotate(-30deg)"
    />
    <Illustration
      type="cross-section-pomegranate"
      top="15600px"
      left={{ base: '-190px', md: '-170px', lg: '-180px', xl: '-210px' }}
      transform="rotate(70deg)"
    />
  </>
)
