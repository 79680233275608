import { ShopOrder } from '@mindfulchefuk/api-client'
import { AUTH_TOKEN } from '@mindfulchefuk/constants'
import { ISODate } from '@mindfulchefuk/lib/toISODateFormat'
import errorHandler from '@mindfulchefuk/utils/errorHandler'
import { LINE_ITEMS } from '@mindfulchefuk/features/ShopOrder/constants'
import {
  ShopOrders,
  APIOrder,
} from '@mindfulchefuk/query/ShopOrders/interfaces'
import { transformShopOrder } from '@mindfulchefuk/query/ShopOrders/transforms'

interface ShopOrdersParameters {
  customerId: string
  fromDate?: ISODate
  toDate?: ISODate
}

interface ShopOrdersResponse {
  data: APIOrder[]
}

export const getShopOrders = async ({
  customerId,
  fromDate,
  toDate,
}: ShopOrdersParameters): Promise<ShopOrders> => {
  try {
    const results = (await ShopOrder.includes(LINE_ITEMS)
      .where({
        customer_id: { eq: customerId },
        delivery_date: { gte: fromDate, lte: toDate },
      })
      .extraParams({ pagination_links: 'false' })
      .all()) as ShopOrdersResponse
    const { data = [] } = results || {}

    return data.map(transformShopOrder)
  } catch (err) {
    // Add extra meta data to help debug why this error is thrown.
    const authToken = window.localStorage.getItem(AUTH_TOKEN)
    const authTokenMeta = authToken ? authToken.length : 'No auth token set'

    const unavailableDateJwtStorage = ShopOrder.jwtStorage
    const metaData = {
      authTokenMeta,
      unavailableDateJwtStorage,
    }
    errorHandler(err, {
      metaData,
    })

    throw err
  }
}
