import {
  APIProductsData,
  Products,
} from '@mindfulchefuk/query/Products/interfaces'
import { transformProduct } from '@mindfulchefuk/query/Products/transforms'
import { ShopProduct } from '@mindfulchefuk/api-client/src'
import errorHandler from '@mindfulchefuk/utils/errorHandler'

type Props = { customerId: string }

export const getCustomerProductsWithFeedbacks = async ({
  customerId,
}: Props): Promise<Products> => {
  try {
    const { data }: APIProductsData = await ShopProduct.includes([
      'images',
      'bundle_contents',
      'product_feedbacks',
    ])
      .where({ customer_id: customerId })
      .selectExtra(['sub_descriptions_html', 'description_html'])
      .where({ 'product_feedbacks.customer_id': customerId })
      .all()

    return data.map(transformProduct)
  } catch (error) {
    errorHandler(error)
    throw error
  }
}
