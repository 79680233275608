import styled from '@emotion/styled'
import { colors, space, typography } from '@mindfulchefuk/design-system/system'

interface LabelProps {
  disabled: boolean
}

export const RadioButtonLabel = styled.label<LabelProps>`
  ${typography['body-md']};
  color: ${colors.aubergine};

  display: grid;
  position: relative;
  grid-template-columns: ${space[28]} auto;
  grid-gap: ${space[10]};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  color: ${({ disabled }) =>
    disabled ? colors['aubergine-200'] : colors.aubergine};
`
