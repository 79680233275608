import styled from '@emotion/styled'
import { LabelHTMLAttributes } from 'react'
import { ColorToken, colors, space, typography } from '../system'

export interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  as?: string
  color?: ColorToken
}

export const Label = styled.label<LabelProps>`
  ${typography['body-md-500-caps']};
  color: ${({ color }) => colors[color] || colors.aubergine};
  display: block;
  margin-bottom: ${space[10]};
`
