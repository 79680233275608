'use client'

import * as CookieConsent from 'vanilla-cookieconsent'

import { CookieCategory } from '@mindfulchefuk/features/Cookies/types'
import { cookieConfig } from '@mindfulchefuk/config/cookieConfig'
import { useOnClientFirstRun } from '@mindfulchefuk/hooks/useOnClientFirstRun'
import {
  setTagManagerDefaultConsents,
  updateGoogleTagConsents,
} from '@mindfulchefuk/features/Cookies/helpers/googleTagManagerConsent'
import { trackConsentUpdate } from '@mindfulchefuk/features/Cookies/helpers/consentCategories'

/* We need this to run before the DOM change for GTM's <Script>
 * tag getting inserted so we use useLayoutEffect. This lets us
 * set default consents before GTM loads tags we should block.
 */
export const useCookieConsent = () => {
  useOnClientFirstRun(() => {
    CookieConsent.run({
      autoShow: false,
      cookie: {
        name: 'cookie_consent_categories',
      },
      onChange: onCookieConsentChange,
      onConsent: onInitalConsent,
      categories: {
        essential: {
          enabled: true,
          readOnly: true,
        },
        analytics: {
          enabled: true,
          autoClear: {
            cookies: cookieConfig.autoClear.analytics,
          },
        },
        marketing: {
          enabled: true,
          autoClear: {
            cookies: cookieConfig.autoClear.marketing,
          },
        },
        diagnostic: {
          enabled: true,
          autoClear: {
            cookies: cookieConfig.autoClear.diagnostic,
          },
        },
      },
      language: {
        default: 'en',
        translations: {
          en: '',
        },
      },
    })
    setTagManagerDefaultConsents()
  })
}

function onCookieConsentChange({
  changedCategories,
}: {
  changedCategories: string[]
}) {
  updateGoogleTagConsents(changedCategories as CookieCategory[])
  trackConsentUpdate(changedCategories as CookieCategory[])
}

function onInitalConsent({ cookie }: { cookie: CookieConsent.CookieValue }) {
  const changedCategories = cookie.categories
  onCookieConsentChange({ changedCategories })
}
