import React, { FunctionComponent, useEffect } from 'react'

import { Box, BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Flex } from '@mindfulchefuk/design-system/layout/Flex'
import { displayDixaMessenger } from '@mindfulchefuk/features/Dixa/displayDixaMessenger'
import { SpaceToken } from '../system'
import { Responsive } from '../system/types'

export type ModalProps = {
  size: ModalSize
  transparent?: boolean
  'data-testid'?: string
  /**
   * You can opt in to dismissing the modal by clicking the overlay with this
   * I didn't do this by default as it could be frustrating in some circumstances
   */
  closeModal?: () => void
  /** Allows you to opt-out of the automatic scroll locking behaviour */
  lockScroll?: boolean
}

export type ModalSize = 'small' | 'medium' | 'large' | 'fullscreen'

const sizes: Record<ModalSize, BoxProps> = {
  small: {
    maxWidth: 384,
  },
  medium: {
    height: ['100%', 'auto'],
    maxWidth: '592px',
    width: '100%',
  },
  large: {
    height: ['100%', 'auto'],
    maxWidth: '960px',
    width: '100%',
  },
  fullscreen: {
    height: '100vh',
    width: '100vw',
    maxHeight: '100vh',
  },
}

export const Modal: FunctionComponent<ModalProps> = ({
  transparent,
  children,
  size = 'small',
  'data-testid': testId,
  closeModal,
  lockScroll = true,
}) => {
  useEffect(() => {
    if (!lockScroll) return
    document.body.classList.add('scrollLock')

    return () => document.body.classList.remove('scrollLock')
  }, [lockScroll])

  const sizeProps = sizes[size]
  displayDixaMessenger(false)

  const height = transparent ? 'auto' : sizeProps.height
  const backgroundColor = transparent ? undefined : 'white'

  const wrappedCloseModal = () => {
    displayDixaMessenger(true)
    if (closeModal) {
      closeModal()
    }
  }

  return (
    <Flex
      position="fixed"
      top={0}
      left={0}
      right={0}
      bottom={0}
      align="center"
      justify="center"
      zIndex="modal"
      padding={getPadding(size)}
      data-testid={testId}
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        backgroundColor="pepper.50"
        onClick={wrappedCloseModal}
        cursor={closeModal ? 'pointer' : 'auto'}
      />
      <Box
        display="block"
        zIndex="modal"
        maxHeight="100%"
        overflow="auto"
        data-testid="modal"
        position="relative"
        backgroundColor={backgroundColor}
        {...sizeProps}
        height={height}
      >
        {children}
      </Box>
    </Flex>
  )
}

function getPadding(size: ModalSize): Responsive<SpaceToken> {
  switch (size) {
    case 'small':
      return 12
    case 'medium':
    case 'large':
      return [0, 24]
    case 'fullscreen':
    default:
      return 0
  }
}
