/**
 * Product types from the API
 *
 * @see https://mindfulchef.postman.co/workspace/MindfulChef-API~3051f434-fb9c-495a-8ebb-bdf416f51665/folder/7544997-871587e4-1729-4359-9172-c43edb9a7dc7?ctx=documentation
 */
import { SpicinessLevel } from '@mindfulchefuk/features/Recipes/interfaces'

export type ProductType =
  | 'recipes'
  | 'meal'
  | 'smoothie'
  | 'broths_and_soups'
  | 'frozen_desserts'
  | 'breakfasts'
  | 'snacks'
  | 'bundled_products'

export type ProductOnlyType = Exclude<ProductType, 'recipes'>

export type CategoryName =
  | 'Recipes'
  | 'Ready meals'
  | 'Soups'
  | 'Smoothies'
  | 'Desserts'
  | 'Breakfast'
  | 'Snacks & Vitamins'
  | 'Healthy Bundles'

/**
 * Product statuses from the API
 *
 * @see https://mindfulchef.postman.co/workspace/MindfulChef-API~3051f434-fb9c-495a-8ebb-bdf416f51665/folder/7544997-871587e4-1729-4359-9172-c43edb9a7dc7?ctx=documentation
 */
export enum ProductStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  ARCHIVED = 'archived', // May be part of previous orders
}

export interface ProductWithQuantity extends Product {
  quantity: number
}

export type ProductImage = {
  id: string
  url: string
  primary: boolean
}

export type ProductImageUrl = string // Url of the primary image
export type ProductId = string

interface ProductBundleContent {
  content_id: string
}

export interface ProductDescription {
  title: string
  description: string
}

export interface Product {
  id: ProductId
  name: string
  sku: string
  productType: ProductType
  description: string
  descriptionHTML: string
  price: number
  priceBeforeDiscount: number
  status: ProductStatus
  inStock: boolean
  images?: ProductImage[]
  imageUrl?: ProductImageUrl
  subDescriptions: ProductDescription[]
  isBundle: boolean
  sortOrder: number
  spicinessLevel: SpicinessLevel
  bundleContents: ProductBundleContent[]
  postCheckoutUpsell: boolean
  feedback?: ProductFeedback
}

export type Products = Product[]

type ProductFeedback = {
  id: string
  rating: number
  description: string
  created_at: Date
}

export interface APIProductImage {
  id: string
  url: string
  primary: boolean
}

export interface APIProductDescription {
  title: string
  description: string
}

export interface APIProduct {
  id: string
  name: string
  sku: string
  product_type: ProductType
  description: string
  description_html: string
  price: string
  price_before_discount: string
  status: ProductStatus
  in_stock: boolean
  is_bundle: boolean
  images: APIProductImage[]
  sub_descriptions_html: APIProductDescription[]
  sort_order: number | null
  spiciness_level: SpicinessLevel
  bundle_contents: ProductBundleContent[]
  post_checkout_upsell: boolean
  product_feedbacks: ProductFeedback[]
}

export interface APIProductsData {
  data: APIProduct[]
}
