import { UseQueryOptions } from '@tanstack/react-query'
import { getCustomerId } from '@mindfulchefuk/helpers/js/authentication'
import { Customer, useQueryCustomer } from '@mindfulchefuk/query'

export const useGetCustomer = <TData = Customer>(
  options?: UseQueryOptions<Customer, unknown, TData>
) => {
  const customerId = getCustomerId()

  return useQueryCustomer(customerId, options)
}
