import React, { VoidFunctionComponent } from 'react'
import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg'

const Path: VoidFunctionComponent<{ transform?: string }> = ({ transform }) => (
  <g fill="none" transform={transform}>
    <path
      d="M3 13.649C4.60486 11.4711 6.52362 9.45682 8.71554 7.64902C9.02832 7.33217 9.46004 7.10738 9.94469 7.00902C10.7436 6.91902 11.3951 7.51902 11.899 8.06902L17 13.619"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </g>
)

export const ChevronUpIcon: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 20 20" {...props}>
    <Path />
  </Svg>
)

export const ChevronRightIcon: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 20 20" {...props}>
    <Path transform="rotate(90 10 10)" />
  </Svg>
)

export const ChevronDownIcon: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 20 20" {...props}>
    <Path transform="rotate(180 10 10)" />
  </Svg>
)

export const ChevronLeftIcon: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 20 20" {...props}>
    <Path transform="rotate(270 10 10)" />
  </Svg>
)
