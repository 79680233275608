import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  NotificationsState,
  NotificationId,
  BannerNotificationVariant,
  AddNotificationPayload,
  NotificationMeta,
} from './interfaces'

const initialState: NotificationsState = {
  list: [],
  meta: {},
}

const priorityOrder: Record<BannerNotificationVariant, number> = {
  error: 5,
  warning: 4,
  success: 3,
  info: 2,
  promo: 1,
}

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<AddNotificationPayload>) => {
      const { id } = action.payload
      // TypeScript isn't happy to reference an optional property unless we do this
      const meta = 'meta' in action.payload ? action.payload.meta : undefined

      const hasNotification = state.list.includes(id)
      if (hasNotification && !meta) return state

      const newList = hasNotification ? state.list : [...state.list, id]

      const sorted = newList.sort((a, b) => {
        const aVariant = a.split('-')[0] as BannerNotificationVariant
        const bVariant = b.split('-')[0] as BannerNotificationVariant

        if (priorityOrder[aVariant] < priorityOrder[bVariant]) return 1

        if (priorityOrder[aVariant] > priorityOrder[bVariant]) return -1

        return 0
      })

      const newMeta = meta ? { ...state.meta, [id]: meta } : state.meta

      return {
        ...state,
        list: sorted,
        meta: newMeta,
      }
    },

    removeNotification: (state, action: PayloadAction<NotificationId>) => {
      const newMeta: NotificationMeta = { ...state.meta }

      if (action.payload in newMeta) {
        delete newMeta[action.payload as keyof NotificationMeta]
      }

      return {
        ...state,
        list: state.list.filter(
          (notification) => notification !== action.payload
        ),
        meta: newMeta,
      }
    },

    clearNotifications: () => initialState,
  },
})

export const { addNotification, removeNotification, clearNotifications } =
  notificationsSlice.actions

export const notificationsReducer = notificationsSlice.reducer
