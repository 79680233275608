import { theme } from '@mindfulchefuk/design-system/system/theme'
import { Box, BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { motion, Variants } from 'framer-motion'
import React, { FC } from 'react'
import { useIsSticky } from '@mindfulchefuk/design-system/hooks/useIsSticky'

const variants: Variants = {
  sticky: { boxShadow: theme.shadows.standard },
  off: { boxShadow: theme.shadows.zero },
}

const MotionBox = motion(Box)

export const StickyBar: FC<BoxProps> = (props) => {
  const { ref, isSticky } = useIsSticky()

  return (
    <MotionBox
      as="header"
      top={-1}
      position="sticky"
      z={isSticky ? 'header' : undefined}
      ref={ref}
      variants={variants}
      animate={isSticky ? 'sticky' : 'off'}
      {...props}
    />
  )
}
