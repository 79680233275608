import {
  ProductData,
  PurchaseData,
  TrackingEvent,
} from '@mindfulchefuk/utils/analytics/interfaces'

import { ProductWithQuantity } from '@mindfulchefuk/query'
import { Recipe } from '@mindfulchefuk/features/Recipes/interfaces'
import { ANALYTICS_CATEGORIES } from '@mindfulchefuk/constants'

/**
 * Generic coupon for Ready to Go My Account to send to Google Analytics
 *
 * Needed because it is too difficult to find out what a customer's coupon is
 * in Ready to Go My Account
 */
export const READY_TO_GO_ACCOUNT_COUPON = 'R2G_ACCOUNT'

export const logPageView = (): void => {
  if (typeof window !== 'undefined') {
    window.dataLayer.push({ event: 'mc.analytics.pageview' })
  }
}

export const logRecipesLoaded = (): void => {
  if (typeof window !== 'undefined') {
    window.dataLayer.push({ event: 'mc.analytics.recipes.loaded' })
  }
}

export const logEvent = ({
  category = 'Global',
  action = 'Generic',
  label,
  value,
}: TrackingEvent): void => {
  if (typeof window !== 'undefined') {
    window.dataLayer?.push({
      event: 'mc.analytics.event',
      category,
      action,
      label,
      value,
    })
  }
}

export const logEvents = (events: Array<TrackingEvent>) =>
  events.forEach((event) => logEvent(event))

export const logProductClick = ({
  list = 'Generic',
  brand = 'Unknown',
  category = 'Unknown',
  id = 0,
  name = 'Product',
  position = 0,
  price = 0,
  variant = 2,
}: ProductData): void => {
  if (typeof window !== 'undefined') {
    window.dataLayer.push({
      event: 'mc.analytics.product.click',
      ecommerce: {
        actionField: { list },
        products: [{ brand, category, id, name, position, price, variant }],
      },
    })
  }
}

export const logProductAdd = ({
  brand = 'Unknown',
  category = 'Unknown',
  currencyCode = 'GBP',
  id = 0,
  name = 'Product',
  price = 0,
  quantity = 1,
  variant = 2,
}: ProductData): void => {
  if (typeof window !== 'undefined') {
    window.dataLayer.push({
      event: 'mc.analytics.product.add',
      ecommerce: {
        currencyCode,
        add: {
          products: [{ brand, category, id, name, price, quantity, variant }],
        },
      },
    })

    if (typeof window.fbq === 'function') {
      window.fbq('track', 'AddToCart', {
        content_name: name,
        content_category: category,
        content_ids: [id],
        content_type: brand,
        value: price,
        currency: currencyCode,
      })
    }

    window.ttq?.track('AddToCart', {
      price,
      value: price,
      quantity: 1,
      currency: currencyCode,
      content_type: 'product',
      content_id: id,
      content_name: name,
      content_category: category,
    })
  }
}

export const logProductRemove = ({
  brand = 'Unknown',
  category = 'Unknown',
  id = 0,
  name = 'Product',
  price = 0,
  quantity = 1,
  variant = 2,
}: ProductData): void => {
  if (typeof window !== 'undefined') {
    window.dataLayer.push({
      event: 'mc.analytics.product.remove',
      ecommerce: {
        remove: {
          products: [{ brand, category, id, name, price, quantity, variant }],
        },
      },
    })
  }
}

export const logAddRecipeToCart = (recipe: Recipe): void => {
  const { foodGroup, id, price, title, portions } = recipe

  logProductAdd({
    brand: 'recipe box',
    category: foodGroup,
    id,
    name: title,
    variant: portions,
    price,
    quantity: 1,
  })
}

export const logRemoveRecipeFromCart = (recipe: Recipe): void => {
  const { foodGroup, id, price, title, portions } = recipe

  logProductRemove({
    brand: 'recipe box',
    category: foodGroup,
    id,
    name: title,
    variant: portions,
    price,
    quantity: 1,
  })
}

export const logAddProductToCart = (product: ProductWithQuantity): void => {
  const { name, id, price, productType } = product

  logProductAdd({
    name,
    id,
    price,
    brand: 'ready to go',
    category: productType,
    variant: 1,
    quantity: 1,
  })
}
export const logRemoveProductFromCart = (
  product: ProductWithQuantity
): void => {
  const { name, id, price, productType } = product

  logProductRemove({
    name,
    id,
    price,
    brand: 'ready to go',
    category: productType,
    variant: 1,
    quantity: 1,
  })
}
export const logRemoveAllProductFromCart = (
  product: ProductWithQuantity
): void => {
  const { name, id, price, productType, quantity } = product

  logProductRemove({
    name,
    id,
    price,
    brand: 'ready to go',
    category: productType,
    variant: 1,
    quantity,
  })
}
export const logPurchase = ({
  transactionId,
  affiliation,
  total,
  coupon,
  products,
  isWaitrose,
}: PurchaseData): void => {
  if (typeof window !== 'undefined') {
    window.dataLayer.push({
      event: 'mc.analytics.purchase',
      ecommerce: {
        purchase: {
          actionField: {
            id: transactionId,
            revenue: total,
            affiliation,
            coupon,
            isWaitrose,
          },
          products,
        },
      },
    })
  }
}

export const analyticsActive = (): boolean => window.dataLayer !== undefined

export const logCarbonLabelClick = (placement: string): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.carbonLabel,
    action: 'Click',
    label: `Recipe ${placement}`,
  })
}

// All tiktok events start here

const mapTikTokItems = (recipes: Recipe[]) => {
  if (!recipes.length) return []
  return recipes.map((recipe) => {
    const { foodGroup, id, price, title } = recipe
    return {
      price,
      quantity: 1,
      content_id: id,
      content_name: title,
      content_category: foodGroup,
    }
  })
}

export const logPageViewTikTok = () => {
  window.ttq?.track('ViewContent')
}

export const logProductViewContentTikTok = (recipe: Recipe): void => {
  const { foodGroup, id, price, title } = recipe

  window.ttq?.track('ViewContent', {
    price,
    value: price,
    currency: 'GBP',
    quantity: 1,
    content_type: 'product',
    content_id: id,
    content_name: title,
    content_category: foodGroup,
  })
}

export const logInitiateCheckoutTikTok = (
  recipes: Recipe[],
  total: number
): void => {
  const contentParameters = mapTikTokItems(recipes)

  window.ttq?.track('InitiateCheckout', {
    currency: 'GBP',
    content_type: 'product',
    value: total,
    quantity: recipes.length,
    contents: contentParameters,
  })
}

export const logPlaceAnOrderTikTok = (total: number, recipes: Recipe[]) => {
  const contentParameters = mapTikTokItems(recipes)
  window.ttq?.track('PlaceAnOrder', {
    currency: 'GBP',
    content_type: 'product',
    value: total,
    quantity: recipes.length,
    contents: contentParameters,
  })
}

export const logCompletePaymentTikTok = (total: number, recipes: Recipe[]) => {
  const contentParameters = mapTikTokItems(recipes)

  window.ttq?.track('CompletePayment', {
    currency: 'GBP',
    content_type: 'product',
    value: total,
    quantity: recipes.length,
    contents: contentParameters,
  })
}
