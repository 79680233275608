import React, { VoidFunctionComponent } from 'react'

import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg'

export const TimerIcon: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 20 20" {...props} fill="none">
    <path
      d="M2.84307 7.09149C4.09178 5.46937 5.80276 4.26376 7.75044 3.63359C9.79769 2.97824 12.0289 3.24806 13.861 4.37254C15.116 5.23752 16.1012 6.43946 16.7031 7.83991C17.3375 9.33339 17.5914 10.961 17.442 12.5767C17.0179 15.3004 15.0391 17.5238 12.3831 18.261C9.59672 19.0989 6.57597 18.4912 4.33044 16.641C2.28258 14.9195 1.60506 12.0592 2.66307 9.60201"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.88416 4.06926L4.74731 2.94189"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.1125 3.84188C13.501 3.30188 13.6999 2.76188 14.0599 2.2124"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.5788 6.58936L9.08617 10.5967C8.95797 11.068 9.05598 11.5721 9.35144 11.9609C9.6102 12.1661 9.91103 12.3117 10.2325 12.3873L13.7378 13.4483"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.60839 3.43474L6.83154 1.36C8.19496 1.16932 9.56731 1.04916 10.9431 1C11.161 1 11.4263 1 11.5494 1.18C11.6117 1.30614 11.6317 1.449 11.6063 1.58737L11.4736 3.08421"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)
