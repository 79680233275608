import { Property } from 'csstype'
import { CSSPropMapper, ResponsiveProps } from '../types'
import { SpaceToken } from '../theme/space'
import { ZIndexToken } from '../theme/zIndices'

export type PositionProps = ResponsiveProps<{
  left?: SpaceToken
  right?: SpaceToken
  top?: SpaceToken
  bottom?: SpaceToken
  pointerEvents?: Property.PointerEvents
  position?: Property.Position
  transform?: Property.Transform
  transformOrigin?: Property.TransformOrigin
  textAlign?: Property.TextAlign
  zIndex?: ZIndexToken
  z?: ZIndexToken
}>

export const position: CSSPropMapper<PositionProps> = {
  left: { property: 'left', themeKey: 'space' },
  right: { property: 'right', themeKey: 'space' },
  top: { property: 'top', themeKey: 'space' },
  bottom: { property: 'bottom', themeKey: 'space' },
  position: { property: 'position' },
  transform: { property: 'transform' },
  transformOrigin: { property: 'transformOrigin' },
  textAlign: { property: 'textAlign' },
  zIndex: { property: 'zIndex', themeKey: 'zIndices' },
  z: { property: 'zIndex', themeKey: 'zIndices' },
  pointerEvents: { property: 'pointerEvents' },
}
