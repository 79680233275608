import { Property } from 'csstype'
import { SpaceToken } from '../theme/space'
import { CSSPropMapper, ResponsiveProps } from '../types'

export type SizeProps = ResponsiveProps<{
  height?: SpaceToken
  width?: SpaceToken
  maxHeight?: SpaceToken
  maxWidth?: SpaceToken
  minHeight?: SpaceToken
  minWidth?: SpaceToken
  size?: SpaceToken

  w?: SpaceToken
  h?: SpaceToken
  mw?: SpaceToken
  aspectRatio?: Property.AspectRatio
}>

const mapper: CSSPropMapper<SizeProps> = {
  width: { property: 'width', themeKey: 'space' },
  height: { property: 'height', themeKey: 'space' },
  maxWidth: { property: 'maxWidth', themeKey: 'space' },
  maxHeight: { property: 'maxHeight', themeKey: 'space' },
  minWidth: { property: 'minWidth', themeKey: 'space' },
  minHeight: { property: 'minHeight', themeKey: 'space' },
  size: { property: ['width', 'height'], themeKey: 'space' },
  aspectRatio: { property: ['aspectRatio'] },
}

export const size: CSSPropMapper<SizeProps> = {
  ...mapper,
  w: mapper.width,
  h: mapper.height,
  mw: mapper.maxWidth,
}
