import { isMobile, isTablet } from 'react-device-detect'
import amplitudeSDK, { Identify } from 'amplitude-js'

import { mockAmplitude, MockAmplitudeClient } from './mockAmplitudeInstance'
import { amplitudeExperimentTracker } from './helpers/amplitudeExperimentTracker'

const amplitudeOptions = {
  includeReferrer: true,
  includeUtm: true,
  unsetParamsReferrerOnNewSession: true,
}

const sessionStorageExperimentKey = 'experimentVariants'
const experimentUserPropertyKey = 'experiment variants'

let isInitialised = false

let sdk:
  | typeof amplitudeSDK
  | {
      getInstance: () => MockAmplitudeClient
      Identify: new () => Identify
    }

const variantToString = (variant: boolean | string | number): string => {
  const type = typeof variant

  if (type === 'string') return variant as string

  if (type === 'number') return variant.toString(10)

  return variant ? '1' : '0'
}

const resetProperty = (property: string) => {
  const identifyObj = new sdk.Identify()
  identifyObj.unset(property)

  sdk.getInstance().identify(identifyObj)
}

const getDevice = () => {
  if (isMobile) {
    return 'Mobile'
  } else if (isTablet) {
    return 'Tablet'
  }

  return 'Desktop'
}

export const initialiseAmplitude = (
  customerId: string | null,
  apiKey: string,
  isAmplitudeEnabled: boolean
) => {
  if (isInitialised) return

  // set SDK to real or mock
  sdk = isAmplitudeEnabled ? amplitudeSDK : mockAmplitude

  // initialise SDK
  sdk.getInstance().init(apiKey, customerId, amplitudeOptions)
  isInitialised = true

  const identifyObj = new sdk.Identify()

  // reset the experiments
  // experiments persist in Amplitude across sessions
  // so we need to reset them when a new session starts
  resetProperty(experimentUserPropertyKey)

  // check if the customer is in any experiments
  const experimentString = amplitudeExperimentTracker.getExperimentString()
  if (experimentString) {
    sessionStorage.removeItem(sessionStorageExperimentKey)

    identifyObj.set(experimentUserPropertyKey, experimentString)
  }

  const device = getDevice()

  identifyObj.set('Device', device)

  sdk.getInstance().identify(identifyObj)
}

/** @deprecated - please use trackAnalyticsEvent instead */
export const instrument = (
  eventName: string,
  eventProperties?: { [key: string]: string | number | boolean | string[] }
) => {
  sdk.getInstance().logEvent(eventName, eventProperties)
}

export const trackAnalyticsEvent = ({
  name,
  data,
}: {
  name: string
  data?: { [key: string]: string | number | boolean | string[] }
}) => {
  sdk.getInstance().logEvent(name, data)
}

export const sdkInitialised = () => isInitialised

// Used when customer is logged in or out
export const setUserId = (customerId: null | string) => {
  sdk.getInstance().setUserId(customerId)
}

export const setExperiment = (
  experimentId: string,
  variant: string | boolean | number
) => {
  const stringifyVariant = variantToString(variant)

  const experimentHelperObject = amplitudeExperimentTracker.setExperimentString(
    experimentId,
    stringifyVariant
  )

  if (experimentHelperObject.hasStringChanged === false) return

  const identifyObj = new sdk.Identify()
  identifyObj.set(
    experimentUserPropertyKey,
    experimentHelperObject.experimentAndVariants
  )

  sdk.getInstance().identify(identifyObj)
}
