import React, { VoidFunctionComponent } from 'react'

import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg'

export const CartIcon: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 20 20" {...props} fill="none">
    <path
      d="M1 1.5301C2 1.3101 3.18 1.1301 4 1.8101C4.51292 2.37788 4.81328 3.10583 4.85 3.8701C5.21 6.2301 5.47 8.60677 5.63 11.0001C5.5965 11.7559 5.81454 12.5015 6.25 13.1201C6.98799 13.7238 7.9477 13.9855 8.89 13.8401L15.24 13.7101C15.9603 13.7746 16.6824 13.6064 17.3 13.2301C17.8437 12.6912 18.195 11.9884 18.3 11.2301L19.4 6.7401C19.4998 6.48895 19.4814 6.20624 19.35 5.9701C19.1696 5.80923 18.9309 5.72967 18.69 5.7501L15.47 5.6001C12.37 5.4601 10.04 5.3201 6.95 5.1801"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="7.71002"
      cy="17.23"
      r="1.94"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="15.1199"
      cy="17.23"
      r="1.94"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)
