import React, { VoidFunctionComponent } from 'react'
import Illustration from '@mindfulchefuk/design-system/Illustrations/Illustrations'

//layout for Forgot password, Sign in, Reset password April 2022
export const SectionBackground10: VoidFunctionComponent = () => (
  <>
    <Illustration
      type="cross-section-leek"
      top={{ base: '-35px', sm: '48px', md: '35px' }}
      left={{
        base: '-40px',
        sm: '-12px',
        md: '91px',
      }}
      transform="scale(0.9)"
    />
    <Illustration
      type="chopped-red-onion-diced-4"
      top={{ base: '-80px', sm: '-10px', md: '0' }}
      left={{
        base: '-140px',
        sm: '-105px',
        md: '0',
      }}
      transform="scale(0.4)"
    />
    <Illustration
      type="cross-section-sweet-corn-3"
      top={{
        base: '80px',
        sm: '119px',
        md: '90px',
      }}
      right={{
        base: '-20px',
        sm: '-30px',
        md: '55px',
        xl: '105px',
      }}
    />
    <Illustration
      type="cross-section-sweet-corn-2"
      display={{ base: 'none', sm: 'block' }}
      top={{
        sm: '269px',
        md: '240px',
      }}
      right={{
        sm: '29px',
        md: '114px',
        xl: '14px',
      }}
    />
    <Illustration
      type="cross-section-pumpkin-01"
      display={{ base: 'none', sm: 'block' }}
      top={{
        sm: '260px',
        md: '231px',
      }}
      right={{
        sm: '-79px',
        md: '8px',
        xl: '-92px',
      }}
    />
    <Illustration
      type="cross-section-lemon"
      display={{ base: 'none', md: 'block' }}
      top="253px"
      left={{ md: '-60px', xl: '-160px' }}
      transform="scale(0.7)"
    />
    <Illustration
      type="cross-section-coriander-1"
      display={{ base: 'none', sm: 'block' }}
      top={{ sm: '427px', md: '414px' }}
      left={{
        sm: '19px',
        md: '135px',
        xl: '180px',
      }}
      transform="scale(0.7)"
    />
    <Illustration
      type="chopped-carrot-twirl-x2"
      display={{ base: 'none', md: 'block' }}
      top="375px"
      right="-60px"
      transform="scale(0.65)"
    />
  </>
)
