import { useLayoutEffect, EffectCallback } from 'react'

/* We can't run useLayoutEffect on the server but need it so we can run earlier than useEffect.
 * This will run before commiting to the DOM for the first time and not re-run after
 *
 * CAUTION: This blocks first paint of the browser so can slow first load down.
 */
export function useOnClientFirstRun(effect: EffectCallback): void {
  const isOnNextServer = typeof window === 'undefined'

  if (isOnNextServer) return

  /* We're ok with hook order being different on the server as it will
   * still remain the same between each run on the client. We're also ok
   * with not including effect in the dependency array because we'd
   * rather ensure that this only runs once on first load even if effect
   * were to somehow change. We'll disable these eslint rules.
   */
  useLayoutEffect(effect, []) // eslint-disable-line react-hooks/rules-of-hooks, react-hooks/exhaustive-deps
}
