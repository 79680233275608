import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { cssDixaSelector } from './displayDixaMessenger'

const regexOrderUpcoming = /order\/\[orderDate\]/

const disabledDixaRouteswhichContain = [
  'checkout',
  'onboarding',
  'choose-recipes',
  regexOrderUpcoming,
]

const resetDixaInlineStyles = () => {
  const dixaElement = document.querySelector(cssDixaSelector) as HTMLElement
  if (!dixaElement) return
  /**
   * When route changes happen, sometimes the component does not get a
   * chance to set the inline styles, so we have used a inline style reset
   * in these cases
   */
  dixaElement.removeAttribute('style')
}

export const useAllowedDixaRoutes = () => {
  const { pathname } = useRouter()
  useEffect(() => {
    if (pathname) {
      const isSuccessPage = !!pathname?.includes('success')

      /**
       * Note: Dixa Messenger script sometimes loads after our main code,
       * so we can not access the element to add the class so we need
       * to append it to the body and allow the global CSS to do its magic
       */
      const documentBody = document.body

      // Remove Dixa disabled CSS class , if it is success page and dixa disabled route
      if (
        isSuccessPage &&
        documentBody.classList.contains('dixa-disabled-route')
      ) {
        documentBody.classList.remove('dixa-disabled-route')
        resetDixaInlineStyles()
        return
      }

      const disabledRoute = disabledDixaRouteswhichContain.some(
        (disabledPath) => {
          if (typeof disabledPath === 'string') {
            return pathname.includes(disabledPath)
          }
          return disabledPath.test(pathname)
        }
      )

      // Disabled Dixa Messenger route which does not have the dixa-disabled-route class on the element
      if (
        disabledRoute &&
        !documentBody.classList.contains('dixa-disabled-route')
      ) {
        documentBody.classList.add('dixa-disabled-route')
        return
      }

      // Allowed Dixa Messenger route which has the dixa-disabled-route class on the element
      if (
        !disabledRoute &&
        documentBody.classList.contains('dixa-disabled-route')
      ) {
        documentBody.classList.remove('dixa-disabled-route')
        resetDixaInlineStyles()
      }
    }
  }, [pathname])
}
