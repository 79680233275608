import createActionHelper from '@mindfulchefuk/helpers/js/createActionHelper'

export const CHECKOUT_CARD_IS_VALID_SET = 'CHECKOUT_CARD_IS_VALID_SET'
export const setCardIsValid = createActionHelper(CHECKOUT_CARD_IS_VALID_SET)

export const CHECKOUT_PAYMENT_TOKEN_IN_PROGRESS_SET =
  'CHECKOUT_PAYMENT_TOKEN_IN_PROGRESS_SET'
export const setPaymentTokenInProgress = createActionHelper(
  CHECKOUT_PAYMENT_TOKEN_IN_PROGRESS_SET
)

export const CHECKOUT_PAYMENT_TOKEN_SET = 'CHECKOUT_PAYMENT_TOKEN_SET'
export const setPaymentToken = createActionHelper(CHECKOUT_PAYMENT_TOKEN_SET)

export const CHECKOUT_ERROR_SET = 'CHECKOUT_ERROR_SET'
export const setCheckoutError = createActionHelper(CHECKOUT_ERROR_SET)

export const CHECKOUT_RESET = 'CHECKOUT_RESET'
export const resetCheckout = createActionHelper(CHECKOUT_RESET)

export const CHECKOUT_STEPS_SET = 'CHECKOUT_STEPS_SET'
export const setCheckoutSteps = createActionHelper(CHECKOUT_STEPS_SET)

export const CHECKOUT_SUBMITTING_SET = 'CHECKOUT_SUBMITTING_SET'
export const setCheckoutSubmitting = createActionHelper(CHECKOUT_SUBMITTING_SET)

export const CHECKOUT_VALUE_SET = 'CHECKOUT_VALUE_SET'
export const setCheckoutValue = createActionHelper(CHECKOUT_VALUE_SET)

export const CHECKOUT_VOUCHER_ERROR_SET = 'CHECKOUT_VOUCHER_ERROR_SET'
export const setVoucherError = createActionHelper(CHECKOUT_VOUCHER_ERROR_SET)

export const CHECKOUT_VOUCHER_ERROR_UNSET = 'CHECKOUT_VOUCHER_ERROR_UNSET'
export const unsetVoucherError = createActionHelper(
  CHECKOUT_VOUCHER_ERROR_UNSET
)
