import {
  Product,
  ProductStatus,
  ProductType,
} from '@mindfulchefuk/query/Products/interfaces'

export const getPublishedProductsByProductType = (
  products: Product[],
  type: ProductType
): Product[] =>
  products
    .filter((product) => {
      if (type === 'bundled_products') {
        return (
          (product.isBundle || product.productType === 'bundled_products') &&
          product.status === ProductStatus.PUBLISHED
        )
      }
      return (
        product.productType === type &&
        product.status === ProductStatus.PUBLISHED
      )
    })
    .sort((a, b) => Number(b.inStock) - Number(a.inStock))
