import React, { VoidFunctionComponent } from 'react'

import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg'

export const SortIcon: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.486 2a.4.4 0 0 1 .391.411L4.714 16.6a.401.401 0 0 1-.4.402.4.4 0 0 1-.391-.41l.163-14.188a.401.401 0 0 1 .4-.402Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.153 10.114c.161-.13.383-.084.495.105L3.7 15.355c.145.249.294.476.449.632.142.142.252.186.341.18a1.08 1.08 0 0 0 .514-.44 29.078 29.078 0 0 0 3.328-5.51c.093-.201.31-.276.482-.166.173.11.237.362.143.564a29.93 29.93 0 0 1-3.393 5.626c-.23.352-.543.611-.9.734a.31.31 0 0 1-.05.013c-.38.064-.696-.137-.928-.37-.23-.23-.423-.535-.573-.793l-3.05-5.131a.462.462 0 0 1 .09-.58ZM15.486 17.01a.4.4 0 0 0 .391-.41l-.163-14.188a.401.401 0 0 0-.4-.402.4.4 0 0 0-.391.41l.163 14.19a.401.401 0 0 0 .4.4Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.153 8.897c.161.131.383.085.495-.104L14.7 3.656c.145-.248.294-.476.449-.63.143-.144.252-.188.341-.181.198.075.378.226.514.44a29.082 29.082 0 0 1 3.328 5.51c.093.201.31.276.482.166.173-.11.237-.362.143-.564a29.929 29.929 0 0 0-3.393-5.626c-.23-.352-.543-.611-.9-.734a.313.313 0 0 0-.05-.013c-.38-.064-.696.137-.928.369-.23.23-.423.535-.573.794l-3.05 5.131a.462.462 0 0 0 .09.58Z"
      fill="currentColor"
    />
  </Svg>
)
