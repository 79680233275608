import React, { forwardRef, ReactElement, InputHTMLAttributes } from 'react'
import styled from '@emotion/styled'
import { Flex } from '@mindfulchefuk/design-system/layout/Flex'
import { ColorToken, colors, theme } from '@mindfulchefuk/design-system/system'
import {
  inputPlaceholderStyles,
  inputStyles,
} from '@mindfulchefuk/design-system/Forms/form.styles'

const Input = styled.input`
  ${inputStyles};
  color: ${({ color }) => colors[color as ColorToken] || 'aubergine'};
  &::placeholder {
    color: ${({ color }) =>
      color ? `${colors[color as ColorToken]}50` : inputPlaceholderStyles.base};
  }
  padding-right: ${theme.space[48]};
`

export const InputWithIcon = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement>
>((props, ref) => {
  const { children, ...rest } = props

  return (
    <Flex justifyContent="flex-end" alignItems="center" position="relative">
      <Input {...rest} ref={ref} />
      {children &&
        React.cloneElement(children as ReactElement, {
          position: 'absolute',
          right: '18px',
        })}
    </Flex>
  )
})
