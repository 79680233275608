import { colors } from './colors'
import { space } from './space'
import { breakpoints, media } from './breakpoints'
import { typography } from './typography'
import { overlays } from './overlays'
import { zIndices } from './zIndices'
import { shadows } from './shadows'

export const theme = {
  colors,
  space,
  breakpoints,
  media,
  typography,
  overlays,
  zIndices,
  shadows,
} as const

export type Theme = typeof theme

export * from './colors'
export * from './breakpoints'
export * from './typography'
export * from './space'
export * from './overlays'
export * from './shadows'
export * from './zIndices'
