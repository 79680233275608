import { useEffect, useRef, useState } from 'react'

/**
 * @param visibleRatio - The ratio of the element that needs to be visible before the hook returns true e.g. 0.5 = 50%
 * @returns ref - The ref to be attached to the element
 * @returns isVisible - Whether the element is visible or not
 */
export const useIsVisible = (visibleRatio: number) => {
  const ref = useRef<HTMLDivElement>(null)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (typeof IntersectionObserver === 'undefined' || !ref.current) return

    const options: IntersectionObserverInit = {
      root: null,
      threshold: [0, 0.25, 0.5, 0.75, 1],
    }

    // eslint-disable-next-line compat/compat
    const observer = new IntersectionObserver((entries) => {
      setIsVisible(entries[0].intersectionRatio >= visibleRatio)
    }, options)

    observer.observe(ref.current)

    return () => {
      observer.disconnect()
    }
  }, [visibleRatio])

  return { ref, isVisible }
}
