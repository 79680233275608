import { useQuery } from '@tanstack/react-query'
import { DeliveryReschedulableDate } from '@mindfulchefuk/query/Deliveries/interfaces'
import { getDeliveryReschedulableDates } from '@mindfulchefuk/query/Deliveries/services/getReschedulableDeliveryDates'

export const useDeliveryReschedulableDates = (
  deliveryId: string,
  deliveryDate: string
) => {
  return useQuery<DeliveryReschedulableDate[]>({
    queryKey: ['delivery-reschedulable-dates', deliveryId, deliveryDate],
    queryFn: () => getDeliveryReschedulableDates({ deliveryDate }),
    enabled: !!deliveryDate,
  })
}
