import Illustration from '@mindfulchefuk/design-system/Illustrations/Illustrations'
import React, { VoidFunctionComponent } from 'react'

// layout for How it works section on the homepage
export const SectionBackground13: VoidFunctionComponent = () => (
  <>
    {/* Desktop illustrations */}
    <Illustration
      type="chopped-beetroot-one"
      transform="scale(0.6)"
      left={{ sm: -96, xl: -96 }}
      display={{ base: 'none', md: 'block' }}
    />
    <Illustration
      type="rhubarb-diced-2"
      top={28}
      left={{ sm: -36, md: 40, xl: 24 }}
      display={{ base: 'none', sm: 'block' }}
    />
    <Illustration
      type="cross-section-papaya"
      bottom={{ sm: -96, md: -96 }}
      right={{ base: 'calc(100% - 146px)', lg: null }}
      left={{ xl: -80 }}
      transform={{ base: 'scale(0.6)', md: 'scale(0.7)' }}
      display={{ base: 'none', sm: 'block' }}
    />
    <Illustration
      type="cross-section-orange"
      bottom={{ sm: -80, lg: -80 }}
      left={{ sm: -20, xl: -14 }}
      transform={{ sm: 'scale(0.6)', md: 'scale(0.7)' }}
      display={{ base: 'none', sm: 'block' }}
    />
    <Illustration
      type="cross-section-cranberries"
      right={{ sm: -48, lg: -40, xl: -56 }}
      bottom={{ sm: 64, md: 80, lg: 112 }}
      display={{ base: 'none', sm: 'block' }}
    />
    {/* left-hand side juice drops */}
    <Illustration
      type="chopped-juice-drop-3"
      bottom={{ xl: 160 }}
      left={{ xl: -320 }}
      display={{ base: 'none', xl: 'block' }}
    />
    <Illustration
      type="chopped-juice-drop-2"
      bottom={{ xl: 128 }}
      left={{ xl: '-295px' }}
      display={{ base: 'none', xl: 'block' }}
    />
    <Illustration
      type="chopped-juice-drop-1"
      bottom={{ xl: 112 }}
      left={{ xl: -320 }}
      display={{ base: 'none', xl: 'block' }}
    />
    {/* right-hand side juice drops */}
    <Illustration
      type="chopped-juice-drop-3"
      top={{ xl: 56 }}
      right={{ xl: '-325px' }}
      display={{ base: 'none', xl: 'block' }}
    />
    <Illustration
      type="chopped-juice-drop-1"
      top={{ xl: 112 }}
      right={{ xl: '-339px' }}
      display={{ base: 'none', xl: 'block' }}
    />
    <Illustration
      type="chopped-juice-drop-2"
      top={{ xl: 80 }}
      right={{ xl: '-354px' }}
      display={{ base: 'none', xl: 'block' }}
    />
    {/* Mobile illustrations */}
    <Illustration
      type="cross-section-watermelon"
      transform="rotate(-185deg)"
      left={-48}
      top={8}
      display={{ base: 'block', sm: 'none' }}
    />
    <Illustration
      type="cross-section-blackberry"
      right={-36}
      top={96}
      display={{ base: 'block', sm: 'none' }}
    />
    <Illustration
      type="cross-section-cherries"
      right={-28}
      top="355px"
      display={{ base: 'block', sm: 'none' }}
    />
    <Illustration
      type="cross-section-runner-bean-2"
      left={-44}
      transform="rotate(30deg) scale(0.4)"
      bottom="335px"
      display={{ base: 'block', sm: 'none' }}
    />
  </>
)
