import Bugsnag from '@mindfulchefuk/lib/bugsnag'
import errorMetaBuilder from '@mindfulchefuk/utils/errorMetaBuilder'

interface Options {
  /**
   * To send additional data to Bugsnag to aid with debugging
   */
  metaData?: Record<string, unknown>
  /**
   * To stop it throwing an error. A warning is still sent to the console
   */
  suppress?: boolean
}

const errorHandler = (errorObject: Error, options: Options = {}): void => {
  const { metaData, suppress = false } = options

  // Bugsnag Queries need use dot notation to access this information.
  // So no whitespace, or odd characters in attribute name.
  Bugsnag.addMetadata('MC_errorHandler', errorMetaBuilder(metaData))

  Bugsnag.notify(errorObject)

  // Throwing a string literal like: throw 'error description`
  // Will lose some of the metadata like a stack trace.
  if (!(errorObject instanceof Error)) {
    console.warn('A non Error has been thrown. Only throw Error objects.') // eslint-disable-line no-console
  }

  if (suppress === true) {
    console.warn('Suppressed Error', errorObject) // eslint-disable-line no-console
    return
  }

  throw errorObject
}

export default errorHandler
