import { Property } from 'csstype'
import { CSSPropMapper, ResponsiveProps } from '../types'

export type FlexParentProps = ResponsiveProps<{
  flexDirection?: Property.FlexDirection
  flexFlow?: Property.FlexFlow
  flexWrap?: Property.FlexWrap

  direction?: Property.FlexDirection
  flow?: Property.FlexFlow
  wrap?: Property.FlexWrap
}>

export const flexParent: CSSPropMapper<FlexParentProps> = {
  flexDirection: { property: 'flexDirection' },
  flexFlow: { property: 'flexFlow' },
  flexWrap: { property: 'flexWrap' },
  direction: { property: 'flexDirection' },
  flow: { property: 'flexFlow' },
  wrap: { property: 'flexWrap' },
}
