import {
  CSSPropMapperConfig,
  CSSPropMapperPropertyConfig,
} from '@mindfulchefuk/design-system/system/types'
import { ObjectInterpolation } from 'emotion'
import { Breakpoint } from '../theme/breakpoints'
import { getThemeToken } from './getThemeToken'
import { parsePropertyConfig } from './parsePropertyConfig'

interface TokenParserProp {
  value: string | number
  config: CSSPropMapperConfig
  props: Record<string, unknown>
  breakpoint: Breakpoint
}

export const parseToken = ({
  value,
  config,
  props,
  breakpoint,
}: TokenParserProp): ObjectInterpolation<undefined> => {
  if ('transform' in config) {
    return config.transform({
      value: getThemeToken(value, config),
      props,
      breakpoint,
    })
  }

  return parsePropertyConfig(value, config as CSSPropMapperPropertyConfig)
}
