import React, { VoidFunctionComponent } from 'react'
import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg'

export const CowIcon: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 20 20" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M0 0H993V749H0z" transform="translate(-553 -469)" />
      <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
        <path
          d="M6.93 3.71s7.07.1 8.25-.21c.93-.919 1.77-1.923 2.51-3 0 0 1.49 2.33.75 3.55-.578.9-1.435 1.584-2.44 1.95.978-.249 2.013-.109 2.89.39 1.16.84 1 .89 1 .89s-.77 1.75-1.81 1.86c-.879.066-1.753-.178-2.47-.69 0 0-.43 6-.53 6.88-.1.88 0 3-1.53 3.16-2.235.209-4.485.209-6.72 0-1.07-.25-1.36-.48-1.74-3.77-.38-3.29-.39-6.83-.39-6.83-.456.351-.998.573-1.57.64C1.66 8.8.6 7.12.6 7.12c.378-.573.852-1.077 1.4-1.49.736-.236 1.515-.301 2.28-.19 0 0-2.4-.87-2.37-2.85 0-.65 0-2 .6-2.12.41-.11 2.65 2.74 2.65 2.74"
          transform="translate(0 1)"
        />
        <path
          d="M10 4.15c-.11.578-.18 1.163-.21 1.75-.003.549.092 1.094.28 1.61.176.47.503.867.93 1.13.523.284.993.656 1.39 1.1.324.412.534.902.61 1.42.048 1.191.964 2.167 2.15 2.29"
          transform="translate(0 1)"
        />
      </g>
    </g>
  </Svg>
)
