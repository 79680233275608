import { media } from './breakpoints'

// Just typography sizes - we can change all px values to rem if we ever want to!
const sizes = {
  12: '12px',
  14: '14px',
  16: '16px',
  18: '18px',
  20: '20px',
  22: '22px',
  24: '24px',
  26: '26px',
  28: '28px',
  32: '32px',
  34: '34px',
  36: '36px',
  38: '38px',
  40: '40px',
  44: '44px',
  48: '48px',
  52: '52px',
  56: '56px',
}

const weights = {
  light: 300,
  normal: 400,
  medium: 500,
  semiBold: 600,
}

const bodyDefaults = {
  fontFamily:
    'Moderat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, sans-serif',
  fontWeight: weights.light,
  letterSpacing: 'normal' as const,
  textTransform: 'none' as const,
}

const bodyXs = { ...bodyDefaults, fontSize: sizes[12], lineHeight: sizes[16] }
const bodyXs300 = { ...bodyXs }
const bodyXs500 = { ...bodyXs, fontWeight: weights.medium }
const bodyXs500Caps = { ...bodyXs500, textTransform: 'uppercase' as const }

const bodySm = { ...bodyDefaults, fontSize: sizes[14], lineHeight: sizes[18] }
const bodySm300 = { ...bodySm }
const bodySm500 = { ...bodySm, fontWeight: weights.medium }
const bodySm300Caps = { ...bodySm, textTransform: 'uppercase' as const }
const bodySm500Caps = { ...bodySm500, textTransform: 'uppercase' as const }

const bodyMd = { ...bodyDefaults, fontSize: sizes[16], lineHeight: sizes[22] }
const bodyMd500 = { ...bodyMd, fontWeight: weights.medium }
const bodyMd500Caps = { ...bodyMd500, textTransform: 'uppercase' as const }

const bodyLg = { ...bodyDefaults, fontSize: sizes[18], lineHeight: sizes[24] }
const bodyLg500 = { ...bodyLg, fontWeight: weights.medium }
const bodyLg500Caps = { ...bodyLg500, textTransform: 'uppercase' as const }

const bodyXl = { ...bodyDefaults, fontSize: sizes[20], lineHeight: sizes[28] }
const bodyXl500 = { ...bodyXl, fontWeight: weights.medium }
const bodyXl500Caps = { ...bodyXl500, textTransform: 'uppercase' as const }

const bodyXXl = { ...bodyDefaults, fontSize: sizes[22], lineHeight: sizes[32] }
const bodyXXl500 = { ...bodyXXl, fontWeight: weights.medium }
const bodyXXl500Caps = { ...bodyXXl500, textTransform: 'uppercase' as const }

const bodyXXXl = {
  ...bodyDefaults,
  fontSize: sizes[26],
  letterSpacing: '-0.3px',
  lineHeight: sizes[36],
  [media.sm]: {
    fontSize: sizes[28],
    letterSpacing: '-0.4px',
    lineHeight: sizes[40],
  },
}

export const body = {
  'body-xs': bodyXs,
  'body-xs-300': bodyXs300,
  'body-xs-500': bodyXs500,
  'body-xs-500-caps': bodyXs500Caps,
  'body-sm': bodySm,
  'body-sm-500': bodySm500,
  'body-sm-300': bodySm300,
  'body-sm-300-caps': bodySm300Caps,
  'body-sm-500-caps': bodySm500Caps,
  'body-md': bodyMd,
  'body-md-500': bodyMd500,
  'body-md-500-caps': bodyMd500Caps,
  'body-lg': bodyLg,
  'body-lg-500': bodyLg500,
  'body-lg-500-caps': bodyLg500Caps,
  'body-xl': bodyXl,
  'body-xl-500': bodyXl500,
  'body-xl-500-caps': bodyXl500Caps,
  'body-xxl': bodyXXl,
  'body-xxl-500': bodyXXl500,
  'body-xxl-500-caps': bodyXXl500Caps,
  'body-xxxl': bodyXXXl,
}

export const headingDefaults = {
  fontFamily:
    'Grenette, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, sans-serif',
  fontWeight: weights.normal,
  letterSpacing: 'normal' as const,
  textTransform: 'none' as const,
}

const headingXxs = {
  ...headingDefaults,
  fontSize: sizes[14],
  letterSpacing: '-0.1px',
  lineHeight: sizes[18],
  [media.sm]: {
    fontSize: sizes[16],
    lineHeight: sizes[18],
  },
}

const headingXs = {
  ...headingDefaults,
  fontSize: sizes[18],
  letterSpacing: '-0.1px',
  lineHeight: sizes[24],
  [media.sm]: {
    fontSize: sizes[20],
    lineHeight: sizes[24],
  },
}

const headingSm = {
  ...headingDefaults,
  fontSize: sizes[22],
  letterSpacing: '-0.1px',
  lineHeight: sizes[32],
  [media.sm]: {
    fontSize: sizes[24],
    letterSpacing: '-0.2px',
  },
}

const headingMd = {
  ...headingDefaults,
  fontSize: sizes[26],
  letterSpacing: '-0.3px',
  lineHeight: sizes[36],
  [media.sm]: {
    fontSize: sizes[28],
    letterSpacing: '-0.4px',
    lineHeight: sizes[40],
  },
}

const headingLg = {
  ...headingDefaults,
  fontSize: sizes[32],
  letterSpacing: '-0.6px',
  lineHeight: sizes[44],

  [media.sm]: {
    fontSize: sizes[34],
    letterSpacing: '-0.7px',
    lineHeight: sizes[48],
  },
}

const headingXl = {
  ...headingDefaults,
  fontSize: sizes[38],
  letterSpacing: '-0.8px',
  lineHeight: sizes[52],

  [media.sm]: {
    fontSize: sizes[40],
    letterSpacing: '-0.9px',
    lineHeight: sizes[56],
  },
}

export const heading = {
  'heading-xxs': headingXxs,
  'heading-xs': headingXs,
  'heading-sm': headingSm,
  'heading-md': headingMd,
  'heading-lg': headingLg,
  'heading-xl': headingXl,
}

export const typography = {
  ...heading,
  ...body,
}

export type TypographyToken = keyof typeof typography
