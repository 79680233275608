import React, { VoidFunctionComponent } from 'react'

import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg/Svg'

export const GlobeIcon: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 22 22" {...props}>
    <path
      stroke="none"
      fill="currentColor"
      d="M3.6,7.8C7.2,0.6,17.5,1.6,19,9.6c0.6,3.4-1.2,7-4.4,8.5c-4.5,2.4-9.7,0-11.3-4.7C2.9,12.2,2.8,10.9,3,9.7
			c0-0.3,0.3-0.5,0.6-0.4C3.8,9.3,4,9.6,4,9.9c-1,5.7,5.1,10.3,10.3,7.4C18.6,15.4,19.6,9,16,5.8C12.4,2.5,6.4,3.7,4.5,8.2
			C4.2,8.9,3.2,8.4,3.6,7.8L3.6,7.8z"
    />
    <path
      stroke="currentColor"
      fill="none"
      d="M5.1,7c0,0,0.3,0.1,0.6,1s0.8,1,1.1,1.3c0.3,0.3,1.1,1.4,1.5,1.4c0.4,0,0.9-0.1,0.5-0.7S8.1,8.8,8.7,8.8
		c0.7-0.1,1.3,0.4,1.6-0.5s1-1.1,1-1.1s0.3-0.4-0.4-0.9C10,5.6,9.3,5.5,10.1,5s1.7-0.7,1.9-0.1s0.5,0.9,0.7,0.8s0.9-0.9,0.9-0.9
		L14,4.6"
    />
    <path
      stroke="currentColor"
      fill="none"
      d="M17.4,7.6c0,0-0.7,0.1-0.7,1.6c-0.7,1.4-1.1,1.9-0.6,2.3c0.5,0.4,1.4,0.7,1.4,0.7l0.6,0.1"
    />
    <path
      stroke="currentColor"
      fill="none"
      d="M11.3,18.4c0,0-0.2-0.9,0.6-1.7c0.9-0.8,1.3-1.4,1.3-1.4l0.7-0.3c0,0,0.5-1.2,0.4-1.6
		c-0.1-0.4-1.5-0.6-1.5-0.6s-0.4-0.2-0.6-0.6c-0.1-0.3-1.2-1-1.7-0.8c-0.5,0.2-1.2,0.9-0.7,1.6s1,1.1,1.1,1.2c0.1,0.2-0.2,1.1-0.3,2
		c-0.1,0.9-0.1,1.9-0.1,1.9L11.3,18.4z"
    />
  </Svg>
)
