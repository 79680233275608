import { Icon } from '@mindfulchefuk/design-system/Icon'
import { SpaceToken } from '@mindfulchefuk/design-system/system/theme/space'
import { Responsive } from '@mindfulchefuk/design-system/system/types'
import React, { VoidFunctionComponent } from 'react'

interface Props {
  checked: boolean
  size?: Responsive<SpaceToken>
}

export const CheckboxIcon: VoidFunctionComponent<Props> = ({
  checked,
  size = 28,
}) => (
  <Icon
    type={checked ? 'checkbox-ticked' : 'checkbox'}
    size={size as Responsive<SpaceToken>}
  />
)
