import React, { VoidFunctionComponent } from 'react'
import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg'

export const PeopleIcon: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 21 20" {...props}>
    <g stroke="currentColor" fill="none">
      <path
        transform="translate(2.35 4)"
        d="M.06 12C.033 10.09.847 8.264 2.29 7M7.542 12c.1-.977.333-1.936.696-2.85.289-.971.952-1.79 1.845-2.28M6.756 4.42c.469-.796.579-1.75.302-2.63C6.745.917 5.968.289 5.042.16 4.212.071 3.39.384 2.833 1c-.562.595-.882 1.375-.897 2.19-.07 1.288.903 2.399 2.198 2.51 1.785.43 3.52 1 4.407 2.45M16.547 12c.05-1.554-.601-3.05-1.775-4.08-1.103-1.002-2.415-1.753-3.842-2.2-.97-.195-1.685-1.019-1.734-2-.023-.797.191-1.583.615-2.26C10.25.79 10.952.332 11.747.2c1.022-.134 2.026.35 2.551 1.23.502.938.502 2.062 0 3"
      />
    </g>
  </Svg>
)
