import { Experiment, Result } from '@growthbook/growthbook-react'
import { trackAnalyticsEvent } from '@mindfulchefuk/utils/analytics/amplitude/amplitudeAdapter'

export const trackingCallback = (
  experiment: Experiment<unknown>,
  result: Pick<Result<unknown>, 'key' | 'hashAttribute' | 'hashValue'>
) => {
  trackAnalyticsEvent({
    name: 'experiment viewed',
    data: {
      experiment_id: experiment.key,
      variation_id: result.key,
      identifier_type: result.hashAttribute,
      identifier: result.hashValue,
    },
  })
}
