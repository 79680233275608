export const shadows = {
  /** Exactly the same as standard, but with 0% opacity - to be used with react-motion */
  zero: '0 3px 8px rgba(0, 0, 0, 0)',
  standard: '0 3px 8px rgba(0, 0, 0, 0.25)',
  /** Can be applied to a CSS triangle to give the correct amount of box shadow */
  triangle: '-3px 3px 3px 0 rgba(0, 0, 0, 0.1)',
}

export type Shadows = typeof shadows
export type ShadowToken = keyof Shadows
