import React, { ReactElement } from 'react'
import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg'

export const NewIcon = ({
  showUpdatedBadge,
  ...props
}: BoxProps & { showUpdatedBadge: boolean }): ReactElement => {
  if (showUpdatedBadge) {
    return (
      <Svg viewBox="0 0 48 48" {...props}>
        <path
          d="M5.52707 30.8446C5.23129 30.8446 5.05566 30.669 5.05566 30.3547V30.0959C5.05566 29.8001 5.17583 29.6615 5.45312 29.5691L5.9615 29.3934C6.37744 29.2548 6.51609 29.0607 6.51609 28.6263V19.3553C6.51609 18.9394 6.37744 18.7453 5.9615 18.6066L5.45312 18.431C5.17583 18.3293 5.05566 18.1907 5.05566 17.9041V17.5714C5.05566 17.2571 5.23129 17.0815 5.52707 17.0815H7.84711C8.12441 17.0815 8.337 17.2016 8.54035 17.3773L15.2509 23.8752C15.4081 24.0139 15.5467 23.9492 15.5467 23.7551V19.2906C15.5467 18.8747 15.4081 18.6806 15.0106 18.5419L14.5022 18.3663C14.2249 18.2646 14.0863 18.126 14.0863 17.8394V17.5806C14.0863 17.2663 14.2804 17.0907 14.5762 17.0907H18.1348C18.4491 17.0907 18.6247 17.2663 18.6247 17.5806V17.8394C18.6247 18.1352 18.4861 18.2739 18.2088 18.3663L17.7004 18.5419C17.3029 18.6806 17.1643 18.8747 17.1643 19.2906V28.6355C17.1643 29.0514 17.3029 29.264 17.7004 29.4027L18.255 29.5783C18.5323 29.6523 18.6524 29.8186 18.6524 30.1052V30.364C18.6524 30.6782 18.4768 30.8539 18.1625 30.8539H14.5392C14.2434 30.8539 14.0678 30.6782 14.0678 30.364V30.1052C14.0678 29.8094 14.188 29.6523 14.4653 29.5783L15.0198 29.4027C15.4173 29.264 15.556 29.0514 15.556 28.6355V28.1456C15.556 27.7944 15.4543 27.5356 15.2232 27.323L8.44792 20.9636C8.29079 20.825 8.15214 20.8897 8.15214 21.1023V28.6447C8.15214 29.0792 8.29079 29.2733 8.68825 29.4119L9.19662 29.5875C9.47392 29.6892 9.61257 29.8279 9.61257 30.1144V30.3732C9.61257 30.6875 9.43695 30.8631 9.12268 30.8631H5.53631L5.52707 30.8446Z"
          fill="currentColor"
        />
        <path
          d="M28.3762 28.8018C27.9048 29.9665 26.583 31.0664 24.3 31.0664C21.4068 31.0664 19.3179 28.9959 19.3179 25.9642C19.3179 22.9324 21.3052 20.8712 24.0042 20.8712C26.1116 20.8712 27.6275 22.0358 28.1174 24.0416C28.1914 24.4113 28.0804 24.5962 27.7662 24.7071L22.1186 26.7961C21.9799 26.8515 21.9429 26.9717 21.9984 27.1103C22.4883 28.275 23.5697 29.0606 25.0302 29.0606C26.1116 29.0606 26.9158 28.6447 27.4149 28.1363C27.6737 27.8775 27.988 27.7389 28.2838 27.9237C28.5426 28.0993 28.5796 28.3582 28.3855 28.8111L28.3762 28.8018ZM21.5085 25.1045V25.2802C21.527 25.4188 21.6102 25.4743 21.7858 25.4003L25.4646 23.9861C25.6217 23.9307 25.7234 23.829 25.6587 23.6164C25.3629 22.7937 24.6512 22.3778 23.884 22.3778C22.5253 22.3778 21.4993 23.4038 21.4993 25.1138L21.5085 25.1045Z"
          fill="currentColor"
        />
        <path
          d="M32.8689 30.8446C32.6101 30.8446 32.4159 30.7429 32.3143 30.4471L30.0682 23.6719C29.8278 22.9417 29.6337 22.6644 29.2455 22.5072L28.8943 22.3686C28.654 22.2854 28.5615 22.156 28.5615 21.8602V21.5644C28.5615 21.2686 28.7371 21.0745 29.0514 21.0745H31.2605C31.5563 21.0745 31.7135 21.2317 31.7874 21.4905L33.5991 27.4986C33.6823 27.7389 33.8579 27.7389 33.9318 27.517L35.4477 23.1635C35.5494 22.8307 35.4662 22.6551 35.2074 22.535L34.7175 22.3224C34.4864 22.2207 34.3847 22.1098 34.3847 21.8694V21.5737C34.3847 21.2779 34.5789 21.0838 34.8746 21.0838H36.8804C37.1762 21.0838 37.3518 21.2224 37.435 21.4997L39.4778 27.5448C39.5517 27.7574 39.7181 27.7574 39.792 27.5263L41.1508 23.0618C41.234 22.7475 41.1878 22.5719 40.9382 22.4518L40.5685 22.2946C40.3559 22.193 40.2727 22.0543 40.2727 21.8602V21.5829C40.2727 21.2686 40.4483 21.093 40.7626 21.093H43.4986C43.8128 21.093 43.9884 21.2871 43.9884 21.5829V21.8417C43.9884 22.1005 43.8868 22.2114 43.6742 22.3131L43.3599 22.4518C42.9902 22.6274 42.7683 22.9232 42.5373 23.6534L40.4113 30.4656C40.3281 30.7614 40.134 30.8631 39.8752 30.8631H38.8677C38.6089 30.8631 38.4333 30.7614 38.3408 30.4656L36.5292 25.2525C36.4737 25.0583 36.2888 25.0583 36.2334 25.2525L34.4217 30.4656C34.32 30.7429 34.1629 30.8631 33.8949 30.8631H32.8689V30.8446Z"
          fill="currentColor"
        />
      </Svg>
    )
  }
  return (
    <Svg viewBox="0 0 21 21" {...props}>
      <path
        d="M5.3447 7V13.5454H4.3087L1.01475 8.69213H0.948338V13.5454H0V7H1.03866L4.31666 11.8639H4.38308V7H5.3447Z"
        fill="currentColor"
      />
      <path
        d="M10.6667 12.6767V13.5454H6.34473V7H10.5684V7.86865H7.309V9.79454H10.1726V10.6472H7.309V12.6767H10.6667Z"
        fill="currentColor"
      />
      <path
        d="M19.6811 7L18.2307 13.5454H17.1097L15.7071 8.72135H15.6407L14.2461 13.5454H13.1251L11.6667 7H12.6284L13.7148 12.1348H13.7786L15.136 7.46753H16.2198L17.548 12.1348H17.6224L18.7275 7H19.6811Z"
        fill="currentColor"
      />
    </Svg>
  )
}
