import styled from '@emotion/styled'
import { inputStyles } from '@mindfulchefuk/design-system/Forms/form.styles'
import { colors, space } from '@mindfulchefuk/design-system/system/theme'
import { Icon } from '../../Icon/Icon'

export const SelectChevron = styled(Icon)`
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
`

SelectChevron.defaultProps = {
  type: 'chevron-down',
}

export const SelectElement = styled.select`
  ${inputStyles};
  appearance: none;
  margin: 0;
  min-width: ${space[96]};
  &[aria-invalid='true'] + ${SelectChevron} {
    color: ${colors.tomato};
  }
`
