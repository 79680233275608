import { Grid } from '@mindfulchefuk/design-system/layout/Grid'
import { dataAttr } from '@mindfulchefuk/utils/dataAttr'
import React, { FunctionComponent } from 'react'
import { HiddenInput } from '@mindfulchefuk/design-system/Forms/form.styles'
import { SelectionBox } from '@mindfulchefuk/design-system/Forms/SelectionBox'
import { InputProps } from '@mindfulchefuk/design-system/Forms/types'
import { CheckboxIcon } from './CheckboxIcon'

export const CheckButton: FunctionComponent<InputProps> = ({
  children,
  value,
  checked,
  'data-testid': dataTestId,
  ...inputProps
}) => (
  <SelectionBox
    data-checked-inside={dataAttr(checked)}
    data-testid={dataTestId}
  >
    <HiddenInput
      {...inputProps}
      id={inputProps.id && `${inputProps.id}-${value}`}
      type="checkbox"
      value={value}
      checked={checked}
    />

    <Grid gap={10} align="center" templateColumns="30px 1fr">
      <CheckboxIcon checked={checked} />
      {children}
    </Grid>
  </SelectionBox>
)
