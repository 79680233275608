import { TOKEN_TIMESTAMP_MULTIPLIER } from '@mindfulchefuk/constants'

const getReadableExpiry = (exp: number): string => {
  if (exp === null || exp === undefined) {
    return 'Invalid Date'
  }

  return new Date(exp * TOKEN_TIMESTAMP_MULTIPLIER).toUTCString()
}

export default getReadableExpiry
