const getTotal = (items?: number[]): number => {
  if (!Array.isArray(items)) {
    throw new Error(`Expected array, received ${typeof items}`)
  }

  return items.reduce((sum, item) => {
    return sum + item
  }, 0)
}

export default getTotal
