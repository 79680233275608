import React, {
  FunctionComponent,
  HTMLAttributes,
  useCallback,
  useEffect,
  useRef,
} from 'react'

import styled from '@emotion/styled'

import {
  margin,
  MarginProps,
  padding,
  PaddingProps,
  size,
  SizeProps,
  visibility,
  VisibilityProps,
} from '../system/config'
import { theme } from '../system/theme'
import { CSSPropMapper, Responsive } from '../system/types'
import { createCSSMapper } from '../system/utils/createCSSMapper'

export interface DropdownProps
  extends SizeProps,
    PaddingProps,
    VisibilityProps,
    MarginProps,
    HTMLAttributes<HTMLDivElement> {
  alignment?: Responsive<'left' | 'right'>
  onToggle: () => void
  isOpen: boolean
}

const dropdownVariants: CSSPropMapper<DropdownProps> = {
  alignment: {
    transform: ({ value = 'left' }) =>
      value === 'left'
        ? { left: 0, right: 'auto' }
        : { right: 0, left: 'auto' },
  },
  ...size,
  ...padding,
  ...margin,
  ...visibility,
}

const [dropdownMapper, shouldForwardProp] = createCSSMapper(dropdownVariants)

const DropdownBox = styled('div', { shouldForwardProp })`
  position: absolute;
  z-index: 5;
  box-shadow: ${theme.shadows.standard};
  background-color: ${theme.colors.white};
  border-radius: ${theme.space[4]};
  border: 0.5px solid ${theme.colors.aubergine};
  border-top: 2px solid ${theme.colors.aubergine};
  top: 100%;

  ${dropdownMapper};
`

DropdownBox.defaultProps = {
  px: 12,
  py: 12,
  mt: 4,
}

export const Dropdown: FunctionComponent<DropdownProps> = ({
  children,
  onToggle,
  isOpen,
  ...boxProps
}) => {
  const container = useRef<HTMLDivElement>()

  const handleClick = useCallback(
    (event) => {
      if (
        isOpen &&
        container.current &&
        !container.current.contains(event.target)
      )
        onToggle()
    },
    [isOpen, onToggle]
  )

  useEffect(() => {
    const rootElement = document && document.querySelector('#__next')
    if (rootElement) {
      rootElement.addEventListener('click', handleClick)
    }

    return () => {
      rootElement?.removeEventListener('click', handleClick)
    }
  }, [handleClick])

  if (!isOpen) return null

  return (
    <DropdownBox ref={container} {...boxProps}>
      {children}
    </DropdownBox>
  )
}
