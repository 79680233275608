const toCurrency = (
  value: number,
  { currency = 'GBP', style = 'currency' } = {}
): string =>
  new Intl.NumberFormat('en-GB', {
    style,
    currency,
    currencyDisplay: 'symbol',
    minimumFractionDigits: 2,
  }).format(value)

export default toCurrency
