import React, { VoidFunctionComponent } from 'react'
import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg'

export const WarningIcon: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 21 20" fill="none" {...props}>
    <path
      d="M2.08297 15.6492C4.52764 11.4248 7.01664 7.24485 9.54997 3.10918C9.77171 2.6191 10.1446 2.21292 10.614 1.95018C11.082 1.82625 11.5809 1.95187 11.9345 2.28268C12.3096 2.62433 12.6301 3.02164 12.8845 3.46068C15.4622 7.58167 17.6534 11.9322 19.43 16.4567C19.7008 16.9831 19.7909 17.584 19.6865 18.1667C19.43 19.0217 18.4895 19.2592 17.701 19.3352C16.181 19.4682 14.6515 19.4587 13.122 19.4492L4.26797 19.3732C3.31797 19.3732 2.22547 19.2497 1.76947 18.3282"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9085 6.51953L10.7565 13.293"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.31 15.3454C10.0441 15.6008 9.8647 15.9329 9.797 16.2954C9.797 16.6994 10.1245 17.0269 10.5285 17.0269C10.9039 17 11.228 16.7541 11.355 16.3999C11.4721 16.1468 11.4721 15.855 11.355 15.6019C11.1666 15.3235 10.8297 15.185 10.5 15.2504C10.177 15.2504 9.8825 15.5639 9.9585 15.8299"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)

export const WarningIconFilled: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.98078 18.4623C1.53782 18.4062 1.31123 16.8292 1.91821 15.5189C3.85083 11.347 6.90082 5.71405 9.04979 2.20586C9.27153 1.71578 9.6444 1.3096 10.1138 1.04686C10.5818 0.922931 11.0807 1.04855 11.4343 1.37936C11.8095 1.72101 12.1299 2.11832 12.3843 2.55736C14.962 6.67835 17.1532 11.0289 18.9298 15.5534C19.2006 16.0798 19.2907 16.6807 19.1863 17.2634C18.9298 18.1184 17.9893 18.3559 17.2008 18.4319C15.6808 18.5649 14.1513 18.5554 12.6218 18.5459L3.76779 18.4699C3.52501 18.4699 3.25535 18.473 2.98078 18.4623Z"
      fill="currentColor"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.4084 5.61621L10.2564 12.3897"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.80988 14.4421C9.54395 14.6975 9.36457 15.0296 9.29688 15.3921C9.29688 15.7961 9.62438 16.1236 10.0284 16.1236C10.4037 16.0967 10.7279 15.8508 10.8549 15.4966C10.972 15.2435 10.972 14.9517 10.8549 14.6986C10.6665 14.4201 10.3296 14.2817 9.99988 14.3471C9.67687 14.3471 9.38238 14.6606 9.45838 14.9266"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)
