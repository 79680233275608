import React from 'react'
import Bugsnag, { BrowserConfig } from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { getVersion } from '@mindfulchefuk/utils/getVersion'

import errorMetaBuilder from '@mindfulchefuk/utils/errorMetaBuilder'

const bugsnagConfig: BrowserConfig = {
  apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
  appVersion: getVersion(),
  enabledReleaseStages: ['production', 'staging', 'uat'],
  plugins: [new BugsnagPluginReact(React)],
  releaseStage: process.env.NEXT_PUBLIC_BUGSNAG_RELEASE_STAGE,
  onError: (event) => {
    // Bugsnag Queries need use dot notation to access this information.
    // So no whitespace, or odd characters in attribute name.
    event.addMetadata('MC_onError', errorMetaBuilder())
  },
}

if (process.env.NODE_ENV === 'development') bugsnagConfig.logger = null

Bugsnag.start(bugsnagConfig)

export default Bugsnag
