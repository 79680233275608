import styled from '@emotion/styled'
import { colors, theme } from '@mindfulchefuk/design-system/system'

export const CheckboxLabel = styled.label<{ isDisabled?: boolean }>`
  ${theme.typography['body-md']};
  color: ${colors.aubergine};
  display: grid;
  grid-template-columns: ${theme.space[28]} auto;
  grid-gap: ${theme.space[10]};
  position: relative;
  ${({ isDisabled }) => isDisabled && 'opacity: 0.5;'}

  * {
    cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  }
`
