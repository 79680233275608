import jwtDecode from 'jwt-decode'
import {
  getCustomerId,
  isAuthenticated,
} from '@mindfulchefuk/helpers/js/authentication'

import { AUTH_TOKEN } from '@mindfulchefuk/constants'
import getReadableExpiry from '@mindfulchefuk/helpers/tokens/getReadableExpiry'
import { LoqateFeatures } from '@mindfulchefuk/helpers/js/getFeature'

export type AppData = {
  build: {
    nodeEnv: string
    releaseStage: string
    version: string
  }
  features: {
    [key: string]: unknown
    loqate: LoqateFeatures
    products: string
  }
}

declare global {
  interface Window {
    mc: AppData
  }
}

interface DecodedToken {
  iss: string
  aud: Array<string>
  exp: number
}

const errorMetaBuilder = (
  customMeta?: Record<string, unknown>
): Record<string, unknown> => {
  const metaData: Record<string, unknown> = {
    isAuthenticated: isAuthenticated(),
    customerId: getCustomerId(),
  }

  if (typeof window !== 'undefined') {
    const {
      mc: {
        build: { version = false, releaseStage = false, nodeEnv = false },
      },
    } = window
    metaData.version = `${version} ${releaseStage} ${nodeEnv}`
  }

  if (typeof document !== 'undefined') {
    const { referrer = false } = document
    if (referrer) {
      metaData.referrer = referrer
    }
  }

  if (typeof navigator !== 'undefined') {
    const { language = false } = navigator
    if (navigator) {
      metaData.language = language
    }
  }

  if (typeof localStorage !== 'undefined') {
    const { [AUTH_TOKEN]: token = false } = localStorage
    if (token) {
      const decodedToken: DecodedToken = jwtDecode(token)
      metaData.token = decodedToken
      metaData.tokenExpiry = getReadableExpiry(decodedToken.exp)
    }
  }

  return { ...metaData, ...customMeta }
}

export default errorMetaBuilder
