import { Property } from 'csstype'
import { CSSPropMapper, ResponsiveProps } from '../types'

export type GridParentProps = ResponsiveProps<{
  gridTemplateColumns?: Property.GridTemplateColumns
  gridTemplateRows?: Property.GridTemplateRows
  gridTemplateAreas?: Property.GridTemplateAreas

  templateColumns?: Property.GridTemplateColumns
  templateRows?: Property.GridTemplateRows
}>

export const gridParent: CSSPropMapper<GridParentProps> = {
  gridTemplateColumns: { property: 'gridTemplateColumns' },
  gridTemplateRows: { property: 'gridTemplateRows' },
  gridTemplateAreas: { property: 'gridTemplateAreas' },
  templateColumns: { property: 'gridTemplateColumns' },
  templateRows: { property: 'gridTemplateRows' },
}
