import { datadogLogs } from '@datadog/browser-logs'
import { getVersion } from '@mindfulchefuk/utils/getVersion'

export function init(): void {
  datadogLogs.init({
    clientToken: process.env.NEXT_PUBLIC_DATADOG_TOKEN,
    site: 'datadoghq.eu',
    forwardErrorsToLogs: true,
    sampleRate: 100,
    service: 'frontend',
    env: process.env.NEXT_PUBLIC_DD_ENV,
    version: getVersion(),
  })
}

type Payload = Record<string, unknown>
export function info(title: string, payload: Payload): void {
  const { logger } = datadogLogs
  logger.info(title, payload)
}
