import toCurrency from '@mindfulchefuk/utils/toCurrency'
import React, { VoidFunctionComponent } from 'react'
import { Text, TextProps } from './Text'

interface PriceTextProps extends TextProps {
  /** Price can be either a number or string, it will display an empty span if passed NaN, null, undefined */
  price: number | string
  /** Adds default styles (strikethrough and color) for discounted prices */
  isDiscounted?: boolean
  /** Can set this to false if you don't want to show the £ sign (e.g. you're using an icon instead) */
  withCurrency?: boolean
  /** Can set this to true if you want to show the - before price. as '- £50.00'(e.g. to display discounts) */
  isNegative?: boolean
}

export const formatPrice = (price: number | string, withCurrency: boolean) => {
  if (typeof price === 'string' && price.toLowerCase() === 'free') {
    return price
  }

  const priceAsNumber =
    typeof price === 'string'
      ? parseFloat(price.replace(/[^0-9-.]+/g, ''))
      : price

  const isValid =
    !Number.isNaN(priceAsNumber) &&
    priceAsNumber !== undefined &&
    priceAsNumber !== null

  const style = withCurrency ? 'currency' : 'decimal'

  const formattedPrice = isValid
    ? toCurrency(priceAsNumber, { style })
    : undefined

  return formattedPrice
}

/** Text component that displays Prices with a simple API (always with 2 decimal places) */
export const PriceText: VoidFunctionComponent<PriceTextProps> = ({
  price,
  isDiscounted,
  withCurrency = true,
  isNegative = false,
  ...textProps
}) => {
  let formattedPrice = formatPrice(price, withCurrency)

  if (isNegative && formattedPrice) formattedPrice = `- ${formattedPrice}`

  return (
    <Text
      // These are just defaults and can be overridden by the consumer
      as="span"
      color={isDiscounted ? 'aubergine-300' : 'aubergine'}
      textDecoration={isDiscounted ? 'line-through' : undefined}
      {...textProps}
    >
      {formattedPrice}
    </Text>
  )
}
