import { media, breakpointsArray } from '../theme/breakpoints'

/** Returns empty objects for each breakpoint and one for 'base'
 * e.g. { base: {}, '@media (min-width: 600px)': {} }
 */
export const createStylesByMediaQuery = (): Record<
  string,
  Record<string, string>
> => {
  const stylesByMediaQuery: Record<string, Record<string, string>> = {}

  breakpointsArray.forEach((breakpoint) => {
    const mediaQuery = breakpoint === 'base' ? breakpoint : media[breakpoint]

    stylesByMediaQuery[mediaQuery] = {}
  })

  return stylesByMediaQuery
}
