import { ShopOrder } from '@mindfulchefuk/api-client'
import errorHandler from '@mindfulchefuk/utils/errorHandler'
import { transformShopOrder } from '@mindfulchefuk/query/ShopOrders/transforms'
import {
  ShopOrder as ShopOrderType,
  ShopOrderStatus,
} from '@mindfulchefuk/query/ShopOrders/interfaces'
import { LINE_ITEMS } from '@mindfulchefuk/features/ShopOrder/constants'

interface GetBasketOrderParams {
  customerId: string
}

export const getBasketShopOrder = async ({
  customerId,
}: GetBasketOrderParams): Promise<ShopOrderType> => {
  try {
    const order = await ShopOrder.includes(LINE_ITEMS)
      .where({
        customer_id: { eq: customerId },
        status: ShopOrderStatus.BASKET,
      })
      .extraParams({ pagination_links: false })
      .first()

    return order.data ? transformShopOrder(order.data) : null
  } catch (error) {
    errorHandler(error)
  }
}
