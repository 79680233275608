/* eslint-disable camelcase */
import { APIProduct, Product } from '@mindfulchefuk/query/Products/interfaces'

export const transformProduct = (product: APIProduct): Product => {
  const {
    id,
    name,
    sku,
    product_type,
    description,
    description_html,
    price,
    price_before_discount,
    status,
    in_stock,
    is_bundle,
    images = [],
    sub_descriptions_html = [],
    sort_order,
    spiciness_level,
    bundle_contents,
    post_checkout_upsell,
    product_feedbacks,
  } = product

  const primaryImage = images.find(({ primary }) => {
    return primary
  })
  const imageUrl = primaryImage ? primaryImage.url : undefined

  const productImages = images.map((image) => ({
    id: image.id,
    url: image.url,
    primary: image.primary,
  }))

  return {
    id,
    name,
    sku,
    productType: product_type,
    description,
    descriptionHTML: description_html,
    price: parseFloat(price),
    priceBeforeDiscount: price_before_discount
      ? parseFloat(price_before_discount)
      : undefined,
    status,
    inStock: in_stock,
    imageUrl,
    images: productImages,
    subDescriptions: sub_descriptions_html,
    isBundle: is_bundle,
    sortOrder: sort_order ?? Infinity,
    spicinessLevel: spiciness_level,
    bundleContents: bundle_contents,
    feedback: product_feedbacks ? product_feedbacks[0] : undefined,
    postCheckoutUpsell: post_checkout_upsell,
  }
}
