import React, { VoidFunctionComponent } from 'react'

import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg/Svg'

export const FacebookIcon: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 18 36" {...props}>
    <path
      d="M16.925 12.051h-5.403V8.495a1.453 1.453 0 011.507-1.639h3.764V1.021H11.58a6.628 6.628 0 00-5.224 1.925 6.63 6.63 0 00-1.911 5.229v3.876H1v6.007h3.445V35h7.077V18.055h4.784l.62-6.004z"
      stroke="currentColor"
      strokeWidth="1.5px"
      fill="none"
    />
  </Svg>
)
