import { useQuery } from '@tanstack/react-query'
import { getCourierAvailabilities } from '@mindfulchefuk/query/Couriers/services/getCourierAvailabilities'
import { STALE_15_MINS } from '@mindfulchefuk/constants'

export const useQueryCourierAvailabilities = (postcode: string) =>
  useQuery({
    queryKey: ['courier-availabilities', postcode],
    queryFn: () => getCourierAvailabilities(postcode),
    enabled: !!postcode,
    staleTime: STALE_15_MINS,
  })
