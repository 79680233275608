import {
  ColorToken,
  HexToken,
} from '@mindfulchefuk/design-system/system/theme/colors'
import { OverlayToken } from '@mindfulchefuk/design-system/system/theme/overlays'
import {
  CSSPropMapper,
  ResponsiveProps,
} from '@mindfulchefuk/design-system/system/types'
import { Property } from 'csstype'

export type BackgroundProps = ResponsiveProps<{
  background?: ColorToken | OverlayToken | HexToken
  bg?: ColorToken | OverlayToken | HexToken
  backgroundColor?: ColorToken | OverlayToken | HexToken
  backgroundImage?: Property.BackgroundImage
  backgroundSize?: Property.BackgroundSize
  backgroundPosition?: Property.BackgroundPosition
}>

export const background: CSSPropMapper<BackgroundProps> = {
  background: {
    property: 'backgroundColor',
    themeKey: ['colors', 'overlays'],
  },
  backgroundColor: {
    property: 'backgroundColor',
    themeKey: ['colors', 'overlays'],
  },
  backgroundImage: {
    property: 'backgroundImage',
  },
  backgroundSize: {
    property: 'backgroundSize',
  },
  backgroundPosition: {
    property: 'backgroundPosition',
  },
  bg: {
    property: 'backgroundColor',
    themeKey: ['colors', 'overlays'],
  },
}
