import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '@mindfulchefuk/design-system/Svg/Svg'
import React from 'react'

const Top = (props: BoxProps) => {
  return (
    <Svg
      display="block"
      viewBox="0 0 864 31"
      fill="none"
      preserveAspectRatio="none"
      {...props}
    >
      <path
        d="M863 30V7.27273L539.903 20.8729C453.064 24.5281 366.073 21.6123 279.674 12.1502L262.889 10.3119C175.928 0.788219 88.3096 -1.24986 1 4.22011V30H863Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </Svg>
  )
}

const Bottom = (props: BoxProps) => {
  return (
    <Svg
      display="block"
      viewBox="0 0 864 38"
      fill="none"
      preserveAspectRatio="none"
      {...props}
    >
      <svg viewBox="0 0 864 38" fill="none">
        <path
          d="M863 28.9267V1H1V25.8288C86.5808 17.9718 172.664 17.184 258.374 23.4732L397.04 33.6483C463.733 38.5421 530.705 38.278 597.358 32.8584L722.643 22.6712C737.088 21.4967 751.599 21.3976 766.058 22.3748L863 28.9267Z"
          fill="currentColor"
          stroke="currentColor"
        />
      </svg>
    </Svg>
  )
}

export const MyAccountWave = { Top, Bottom }
