/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NavigationState } from './interfaces'

const initialState: NavigationState = {
  previousDashboardView: 'list',
  previousCalendarDate: undefined,
}

const navigationSlice = createSlice({
  name: 'recipeFilters',
  initialState,
  reducers: {
    setPreviousCalendarDate: (
      state: NavigationState,
      action: PayloadAction<NavigationState['previousCalendarDate']>
    ) => {
      state.previousCalendarDate = action.payload
    },
    setPreviousDashboardView: (
      state: NavigationState,
      action: PayloadAction<NavigationState['previousDashboardView']>
    ) => {
      state.previousDashboardView = action.payload
    },
  },
})

export const { setPreviousCalendarDate, setPreviousDashboardView } =
  navigationSlice.actions

export const navigationReducer = navigationSlice.reducer
