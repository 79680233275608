import React, { ReactElement } from 'react'
import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg/Svg'

export const RoundelVeganIcon = (props: BoxProps): ReactElement => (
  <Svg
    viewBox="0 0 28 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.54026 22.34L4.07211 29.3853H2.77357L0.30542 22.34H1.45661L3.39061 27.9762H3.47349L5.40749 22.34H6.54026Z"
      fill="currentColor"
    />
    <path
      d="M11.2556 27.1289H7.53497C7.58101 28.0222 8.12437 28.6485 9.0177 28.6485C9.65315 28.6485 10.1136 28.3906 10.2794 27.8749H11.2648C11.053 28.8787 10.2057 29.4866 9.0177 29.4866C7.5718 29.4866 6.53113 28.4735 6.53113 26.9171C6.53113 25.2778 7.60864 24.2556 8.97165 24.2556C10.1965 24.2556 11.274 25.066 11.274 26.7974C11.274 26.8987 11.2648 27 11.2556 27.1289ZM7.54417 26.4014H10.2794C10.2702 25.6278 9.74525 25.0844 8.97165 25.0844C8.19805 25.0844 7.64548 25.6646 7.54417 26.4014Z"
      fill="currentColor"
    />
    <path
      d="M16.0538 25.0107C16.2656 25.2778 16.3761 25.6186 16.3761 25.9777C16.3761 27.046 15.5104 27.6907 14.3961 27.6907C14.0645 27.6907 13.7606 27.6447 13.5027 27.5434C13.3093 27.6447 13.208 27.7736 13.208 27.9578C13.208 28.2525 13.5027 28.3906 13.908 28.3906H14.9026C16.0538 28.3906 16.68 28.9432 16.68 29.8273C16.68 30.8956 15.7683 31.4942 14.4237 31.4942C12.8949 31.4942 12.1766 30.7114 12.0845 29.7813L12.8857 29.0906C12.4989 28.934 12.2595 28.5932 12.2595 28.1696C12.2595 27.746 12.5634 27.4052 12.9133 27.2118C12.5726 26.9171 12.37 26.4935 12.37 25.987C12.37 25.0015 13.1344 24.2556 14.3316 24.2556C14.7737 24.2556 15.1697 24.3569 15.492 24.5503C15.8051 24.3937 16.1275 24.1082 16.3301 23.8503L16.8734 24.4305C16.7169 24.6239 16.3945 24.8542 16.0354 25.02L16.0538 25.0107ZM14.7829 29.1919H13.8711L13.0975 29.8457C13.2172 30.2786 13.5948 30.7022 14.4329 30.7022C15.1052 30.7022 15.7038 30.4444 15.7038 29.8642C15.7038 29.3392 15.1973 29.1919 14.7829 29.1919ZM15.3907 25.9685C15.3907 25.3975 15.0039 25.0015 14.3961 25.0015C13.7882 25.0015 13.3738 25.3975 13.3738 25.9685C13.3738 26.5027 13.779 26.9079 14.3961 26.9079C15.0131 26.9079 15.3907 26.5027 15.3907 25.9685Z"
      fill="currentColor"
    />
    <path
      d="M21.9478 28.6024V29.376H21.4873C21.0176 29.376 20.6861 29.2287 20.5387 28.7498C20.198 29.1826 19.6454 29.4773 18.8902 29.4773C17.914 29.4773 17.1772 29.0169 17.1772 28.0315C17.1772 27.046 17.9048 26.5856 18.8718 26.4935L20.4098 26.3461V26.0975C20.4098 25.4528 20.0506 25.0936 19.4244 25.0936C18.7981 25.0936 18.485 25.4528 18.4021 25.9777H17.3891C17.4812 24.9831 18.2271 24.2556 19.4336 24.2556C20.64 24.2556 21.4413 24.8818 21.4413 26.1343V28.1972C21.4413 28.4827 21.5702 28.6117 21.8281 28.6117H21.957L21.9478 28.6024ZM20.4098 27.0737L19.2125 27.1934C18.6231 27.2394 18.2087 27.5065 18.2087 27.9854C18.2087 28.4643 18.5955 28.6669 19.0468 28.6669C19.673 28.6669 20.419 28.3446 20.419 27.442V27.0737H20.4098Z"
      fill="currentColor"
    />
    <path
      d="M22.8319 24.3476H23.7621L23.8634 24.9555H23.937C24.2225 24.5687 24.6278 24.2556 25.4014 24.2556C26.5157 24.2556 27.2801 24.9739 27.2801 26.2632V29.3853H26.2578V26.4843C26.2578 25.5633 25.7513 25.1489 25.1067 25.1489C24.462 25.1489 23.8634 25.5725 23.8634 26.5671V29.3853H22.8319V24.3569V24.3476Z"
      fill="currentColor"
    />
    <path
      d="M7.70068 14.6684C7.42439 14.6684 7.2402 14.5487 7.12048 14.2724L2.87489 3.46968C2.5986 2.73291 2.29469 2.429 1.85263 2.18955L1.51188 2.00536C1.25401 1.86722 1.15271 1.7475 1.15271 1.50805V1.1673C1.15271 0.844964 1.35532 0.669983 1.65002 0.669983H6.07059C6.37451 0.669983 6.56791 0.854173 6.56791 1.1673V1.50805C6.56791 1.7475 6.4666 1.88564 6.20874 2.00536L5.86798 2.14351C5.34304 2.36453 5.28778 2.58556 5.49039 3.1013L8.47428 11.0215C8.55716 11.2425 8.71372 11.2425 8.79661 11.0215L11.6792 3.15655C11.8818 2.61319 11.7437 2.33691 11.1819 2.07904L10.8411 1.9409C10.5833 1.82117 10.482 1.68303 10.482 1.44358V1.1673C10.482 0.844964 10.6661 0.669983 10.9793 0.669983H14.6631C14.967 0.669983 15.142 0.854173 15.142 1.1673V1.48963C15.142 1.7475 15.0591 1.88564 14.8012 2.01457L14.4789 2.17113C13.9171 2.429 13.6777 2.7145 13.4566 3.2947L9.31234 14.2816C9.21104 14.5395 9.00843 14.6777 8.73214 14.6777H7.69147L7.70068 14.6684Z"
      fill="currentColor"
    />
    <path
      d="M14.2487 16.7774C14.2487 16.0591 14.6907 15.3776 15.4919 14.9355C15.6485 14.8342 15.6761 14.7329 15.5288 14.6316C15.0499 14.2909 14.6907 13.7291 14.6907 13.0292C14.6907 12.1727 15.2893 11.4727 16.2471 11.0859C16.4037 11.0307 16.4313 10.9018 16.3116 10.782C15.6301 10.1189 15.188 9.24404 15.188 8.2402C15.188 6.24174 16.8457 4.51956 19.4889 4.51956C20.2072 4.51956 20.8887 4.67612 21.4321 4.91557C21.6163 4.99845 21.7544 4.95241 21.8097 4.75901C22.1688 3.73675 22.8688 2.86185 23.4674 2.86185C23.7068 2.86185 23.8634 2.98157 24.0292 3.25786L24.4896 4.09592C24.5909 4.28011 24.6738 4.45509 24.6738 4.61165C24.6738 4.8511 24.5541 4.98925 24.2318 5.12739L23.0345 5.67075C22.8964 5.70759 22.8319 5.82731 22.9516 5.99308C23.4766 6.57328 23.8081 7.39293 23.8081 8.23099C23.8081 10.2479 22.1504 11.9516 19.4889 11.9516C18.6692 11.9516 18.3469 11.8319 17.7667 11.8319C17.1865 11.8319 16.7905 12.1082 16.7905 12.6331C16.7905 13.1949 17.2878 13.5173 18.1535 13.5173C19.3507 13.5173 21.0545 13.3146 22.2794 13.3146C24.0015 13.3146 25.0422 14.1988 25.0422 15.5802C25.0422 17.4221 23.4398 19.1995 18.7429 19.1995C15.6577 19.1995 14.2579 18.1957 14.2579 16.759L14.2487 16.7774ZM22.5096 16.1143C22.5096 15.3131 21.9847 14.9908 20.9071 14.9908C19.8849 14.9908 18.485 15.175 17.5457 15.1289C17.3891 15.1289 17.3062 15.1658 17.2049 15.2671C16.8457 15.6078 16.6892 15.967 16.6892 16.4643C16.6892 17.284 17.472 17.8457 19.0284 17.8457C21.4137 17.8457 22.5096 17.109 22.5096 16.1051V16.1143ZM21.4873 8.70989C21.4873 7.12585 20.4835 5.89178 19.2494 5.89178C18.2456 5.89178 17.5272 6.65617 17.5272 7.81657C17.5272 9.38219 18.4482 10.6347 19.7467 10.6347C20.769 10.6347 21.4873 9.85187 21.4873 8.70989Z"
      fill="currentColor"
    />
  </Svg>
)
