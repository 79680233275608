import {
  APIError,
  APIErrorObject,
  APIErrors,
} from '@mindfulchefuk/types/apiError'
import { JsonapiError, SpraypaintBase } from 'spraypaint'

import type { APIOrder } from '@mindfulchefuk/query'
import { GENERIC_ERROR } from '@mindfulchefuk/features/Notifications/constants'

export interface SpraypaintModelError {
  code: string
  attribute: string
  // `fullMessage` here is populated using data from rawPayload:
  // https://github.com/graphiti-api/spraypaint.js/blob/master/src/util/validation-error-builder.ts#L61-L62
  fullMessage: string
  rawPayload: JsonapiError
  message: string
}

export type SpraypaintModelErrorObject = {
  [field: string]: SpraypaintModelError
}

export interface SpraypaintModelWithErrors {
  errors?: SpraypaintModelErrorObject
}

export function spraypaintErrorMessage(
  model: SpraypaintModelWithErrors | APIOrder
): string {
  if (!model.errors) {
    return ''
  }

  const errorValues = Object.values(model.errors)
  let message = ''
  if (errorValues.length > 0) {
    const messages = errorValues.map(({ fullMessage }) => fullMessage)
    message = messages.join(', ')
  }

  return message
}

function mapAPIError(error: SpraypaintModelError): APIError {
  const {
    attribute,
    rawPayload: {
      status,
      meta: { code },
    },
    fullMessage,
  } = error

  return {
    httpStatus: status,
    message: fullMessage,
    id: `${attribute}.${code}`,
    context: attribute,
  }
}

function getAPIErrors(model: SpraypaintModelWithErrors): APIErrors {
  const errors = Object.values(model.errors)
  if (errors.length <= 0) {
    return []
  }

  return errors.map(mapAPIError)
}

export const createAPIError = (
  model: SpraypaintModelWithErrors
): APIErrorObject => {
  const name = 'APIError'
  const message = spraypaintErrorMessage(model)
  const errors = getAPIErrors(model)

  return {
    name,
    message,
    errors,
  }
}

export const hasAPIError = (error: APIErrorObject, id: string): boolean => {
  if (!error.errors) {
    return false
  }

  const hasErrorId = ({ id: errorId }: APIError) => {
    return errorId === id
  }

  return error.errors.some(hasErrorId)
}

export const getAPIError = (error: APIErrorObject, id: string): APIError => {
  if (!error.errors) {
    return undefined
  }

  const getError = ({ id: errorId }: APIError) => {
    return errorId === id
  }

  return error.errors.find(getError)
}

// Custom error class to keep API response errors standardised
// and to ensure we only pass error types round the app
export class ApiError extends Error {
  public httpStatus: number

  public context: string | number

  public code: string

  constructor(model: SpraypaintBase) {
    const errors = Object.values(model.errors)
    const firstError = errors[0]

    super(firstError?.message || GENERIC_ERROR)
    this.httpStatus = parseInt(firstError?.rawPayload?.status, 10)
    this.context = firstError?.attribute
    this.code = firstError?.rawPayload?.meta?.code
  }
}
