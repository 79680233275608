import { Product } from '@mindfulchefuk/query/Products/interfaces'
import {
  ShopOrder,
  ShopOrderWithProducts,
} from '@mindfulchefuk/query/ShopOrders/interfaces'

export const transformShopOrderWithProducts = (
  products: Product[],
  shopOrder: ShopOrder
): ShopOrderWithProducts => {
  if (!shopOrder || !products?.length) return undefined
  const totalPrice = shopOrder.lineItems.reduce((sum, lineItem) => {
    return sum + lineItem.unitPrice * lineItem.quantity
  }, 0)

  const totalPriceWithShipping = totalPrice + shopOrder.shippingPrice
  return {
    ...shopOrder,
    totalPrice,
    totalPriceWithShipping,
    totalProducts: shopOrder.lineItems.reduce(
      (total, lineItem) => total + lineItem.quantity,
      0
    ),
    products: shopOrder.lineItems
      .map((lineItem) => {
        const product = products.find(({ id }) => id === lineItem.productId)
        if (!product) return undefined

        return {
          ...product,
          quantity: lineItem?.quantity || 0,
          price: lineItem.unitPrice,
        }
      })
      .filter(Boolean),
  }
}
