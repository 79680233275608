import Illustration from '@mindfulchefuk/design-system/Illustrations/Illustrations'
import React, { VoidFunctionComponent } from 'react'

export const SectionBackground04: VoidFunctionComponent = () => (
  <>
    <Illustration
      type="chopped-carrot-twirl"
      transform="rotate(-75deg)"
      top={-16}
      right={-12}
      z={0}
    />
    <Illustration
      type="cross-section-squash-02"
      bottom={-36}
      right={{ base: 0, md: -96 }}
      z={0}
      transform={{ base: 'rotate(25deg)', md: 'rotate(25deg) scale(1.3)' }}
    />
    <Illustration
      type="chopped-broccoli-03"
      bottom={{ base: -48, md: -24 }}
      left={{ base: 4, md: -48 }}
      z={0}
      transform="rotate(-35deg)"
    />
    <Illustration
      type="cross-section-pumpkin-02"
      bottom={-36}
      left="40%"
      z={1}
      display={{ base: 'none', md: 'block' }}
      transform={{ base: 'scale(1.25)', md: 'scale(1.5)' }}
    />
    <Illustration
      type="cross-section-artichoke"
      bottom={-112}
      left="28%"
      z={0}
      display={{ base: 'none', md: 'block' }}
    />
    <Illustration
      type="chopped-grapefruit-03"
      top={48}
      left={-24}
      z={0}
      transform={{ md: 'scale(1.5)' }}
    />
  </>
)
