import React, { VoidFunctionComponent } from 'react'

import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg/Svg'

export const MenuIcon: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 20 20" {...props}>
    <g fill="none" stroke="currentColor">
      <path
        d="M2.46997 5.5H17.38"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.55981 10.3602L14.4998 10.5002"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.38989 15.2002H17.4599"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </Svg>
)
