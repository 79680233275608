import React, { ReactElement } from 'react'

import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg/Svg'

export const ChilliIcon = (props: BoxProps): ReactElement => (
  <Svg viewBox="0 0 20 20" {...props}>
    <path
      d="M10.8678 18.3477C8.8994 19.5259 6.72986 19.8994 5.07756 20C4.61779 20.0287 4.20113 19.7414 4.05745 19.3103C3.91377 18.8793 4.05745 18.4195 4.45975 18.1609C6.32756 16.9397 10.9253 12.6293 9.79021 7.32759C9.7471 7.1408 9.87641 6.93965 10.0632 6.89655C10.2643 6.85345 10.4511 6.98276 10.4942 7.16954C11.6724 12.6724 7.31894 17.1552 4.84768 18.7644C4.68963 18.8649 4.71837 19.023 4.73274 19.0805C4.73274 19.1236 4.81894 19.296 5.02009 19.2816C8.54021 19.0805 14.5316 17.4856 15.2069 9.03736C15.2356 8.75 15.2356 8.46264 15.2356 8.20402C15.2356 8.00287 15.3937 7.84483 15.5948 7.84483C15.796 7.84483 15.954 8.00287 15.954 8.20402C15.954 8.49138 15.954 8.7931 15.9109 9.09483C15.5086 14.1092 13.3391 16.8678 10.8534 18.3477H10.8678Z"
      fill="currentColor"
    />
    <path
      d="M15.3506 6.75287C15.5373 5 13.8994 3.7931 12.2471 3.89368C12.1322 3.89368 12.0029 3.86494 11.9741 3.75C11.6437 2.77299 11.5862 1.89655 11.385 0.948277C11.385 0.876438 11.3276 0.632185 11.2988 0.574714C11.2845 0.545979 11.3419 0.632185 11.3707 0.646553C11.3707 0.646553 11.1839 0.617818 11.0833 0.646553C10.8391 0.704024 10.8678 0.948277 10.8822 0.589082L11.0833 1.43678C11.1983 1.95402 11.5 3.39081 11.6149 3.93678C11.6437 4.06609 11.5718 4.18104 11.4569 4.22414C10.3649 4.71265 9.84768 6.02012 9.63217 7.14081L9.44538 6.82471C9.71837 6.86782 10.1925 6.6523 10.4511 6.47989C10.8103 6.25 11.1264 5.94828 11.4281 5.64655C11.5287 5.53161 11.7011 5.53161 11.8161 5.64655C12.3621 6.39368 12.8649 7.15517 13.0948 8.11782L12.6063 7.98851C13.1523 7.25575 13.2816 6.17816 13.1523 5.27299C13.1235 5.10058 13.2816 4.94253 13.454 4.97127C14.5172 5.10058 15.4942 5.66092 15.9827 6.63793C16.0689 6.81035 15.9971 7.0115 15.8391 7.0977C15.6666 7.18391 15.4655 7.11207 15.3793 6.95402C15.0919 6.14943 14.2299 5.63219 13.4109 5.53161L13.7126 5.21552C13.8563 6.30747 13.7414 7.44253 13.0373 8.37644C12.8937 8.56322 12.5776 8.47701 12.5488 8.24713C12.3764 7.48563 11.8879 6.72414 11.3994 6.04885L11.8304 6.07759C11.1264 6.75287 10.3362 7.55747 9.2586 7.39943C9.12929 7.37069 9.04309 7.24138 9.07182 7.11207C9.20113 6.43678 9.40228 5.79023 9.73274 5.18678C10.0919 4.56897 10.523 4.03736 11.227 3.72127L11.0402 4.02299L10.681 2.41379C10.681 2.29885 10.2069 0.675289 10.3075 0.589082C10.5373 -0.0143662 11.658 -0.272987 11.9166 0.416668C12.0603 0.962645 12.204 1.89655 12.3046 2.47127C12.3621 2.80173 12.4626 3.2615 12.5057 3.43391L12.2327 3.29023C13.2098 3.29023 14.158 3.53448 14.9195 4.16667C15.7098 4.79885 15.9684 5.81897 16.0402 6.76724C16.0546 6.95402 15.9253 7.11207 15.7385 7.14081C15.523 7.16954 15.3506 6.98276 15.3649 6.76724L15.3506 6.75287Z"
      fill="currentColor"
    />
    <path
      d="M9.99138 17.3851C9.34483 17.773 8.88506 17.931 8.82759 17.9454C8.64081 18.0029 8.43966 17.9023 8.36782 17.7155C8.31035 17.5287 8.41092 17.3276 8.5977 17.2558C8.75575 17.1983 12.5058 15.9052 13.5115 11.0201C13.5546 10.819 13.7414 10.704 13.9425 10.7471C14.1293 10.7902 14.2586 10.977 14.2155 11.1782C13.4684 14.7845 11.3276 16.5805 9.97701 17.3851H9.99138Z"
      fill="currentColor"
    />
  </Svg>
)
