// Primary colors
const aubergine = '#392d6e'
const broccoli = '#008050'
const black = '#000'
const pepper = '#1e1e28'
const salt = '#fcf9ed'
const white = '#fff'
const lemon = '#f3e16c'
const tomato = '#c8412f'
const meat6 = '#8D2336'
const blueberry = '#2694fa'
const pumpkin = '#f08c16'
const transparent = 'transparent'

// Branding colors
// These don't use food names and align with the Brand Team.
// @see https://www.figma.com/file/oNuRgseOos4VdkxPdKiSLW/MC-2.2-Design-System?node-id=2850:31128
const brand = {
  meat1: '#EFD7B7',
  meat2: '#E6B376',
  meat3: '#c98d4e',
  meat4: '#C86868',
  meat5: '#BD4751',
  meat6: '#8D2336',
  veg1: '#D15B99',
  veg2: '#c53f81',
  veg3: '#A7305D',
  veg4: '#FF9776',
  veg5: '#FA7C57',
  veg6: '#E85D39',
  veg7: '#F77569',
  veg8: '#EA5244',
  veg9: '#D44033',
  veg10: '#FFA343',
  veg11: '#F08C16',
  veg12: '#E16F00',
  veg13: '#F3E16C',
  veg14: '#F0C938',
  veg15: '#e1aa00',
  veg16: '#c2aF5d',
  veg17: '#a39450',
  veg18: '#7A7026',
  veg19: '#63AD50',
  veg20: '#68a25b',
  veg21: '#2d821a',
  veg22: '#4DB382',
  veg23: '#3C976B',
}

export const colors = {
  inherit: 'inherit',
  white,
  black,
  salt,
  'salt-600': '#f6ecc7',
  blueberry,
  transparent,
  meat6,
  ...brand,

  aubergine,
  'aubergine-50': '#eeecf7',
  'aubergine-100': '#d0cce0',
  'aubergine-200': '#b2acc9',
  'aubergine-300': '#948db3',
  'aubergine-400': '#756d9c',
  'aubergine-500': '#574d85',
  'aubergine-600': aubergine,
  'aubergine-700': '#30265d',
  'aubergine-800': '#271f4c',
  'aubergine-900': '#1e183b',

  broccoli,
  'broccoli-50': '#f5fffb',
  'broccoli-100': '#cee8de',
  'broccoli-200': '#a6d2c1',
  'broccoli-300': '#7ebda5',
  'broccoli-400': '#54a888',
  'broccoli-500': '#29956d',
  'broccoli-600': broccoli,
  'broccoli-700': '#00633e',
  'broccoli-800': '#00462b',
  'broccoli-900': '#002919',

  lemon,
  'lemon-50': '#fcf6d5',
  'lemon-100': '#faf2c0',
  'lemon-200': '#f8eeab',
  'lemon-300': '#f7e996',
  'lemon-400': '#f5e581',
  'lemon-500': lemon,
  'lemon-600': '#c1b352',
  'lemon-700': '#908438',
  'lemon-800': '#5e561e',
  'lemon-900': '#2c2704',

  tomato,
  'tomato-50': '#f3d0cc',
  'tomato-100': '#eab3ad',
  'tomato-200': '#e2978d',
  'tomato-300': '#d97a6e',
  'tomato-400': '#d15e4e',
  'tomato-500': tomato,
  'tomato-550': '#e59593',
  'tomato-600': '#a43527',
  'tomato-700': '#802a1e',
  'tomato-800': '#5b1e16',
  'tomato-900': '#37120d',

  pepper,
  'pepper-50': '#ebebed',
  'pepper-100': '#d3d3d3',
  'pepper-200': '#b9b9b9',
  'pepper-300': '#a0a0a0',
  'pepper-400': '#888888',
  'pepper-500': '#6d6d70',
  'pepper-600': '#525259',
  'pepper-700': '#373741',
  'pepper-800': pepper,
  'pepper-900': '#191922',

  pumpkin,
  'pumpkin-50': '#fef6ee',
  'pumpkin-100': '#fce4ca',
  'pumpkin-200': '#f9d3a6',
  'pumpkin-300': '#f7c182',
  'pumpkin-400': '#f5af5e',
  'pumpkin-500': '#f29e3a',
  'pumpkin-600': pumpkin,
  'pumpkin-700': '#b56910',
  'pumpkin-800': '#7b470a',
  'pumpkin-900': '#402404',

  // BBC Good Food
  'bbc-good-food-black': '#12100b',
  'bbc-good-food-gray': '#f0f0f0',
  'bbc-good-food-pink': '#bc1e49',
  'bbc-good-food-pink-disabled': '#dc98ab',
  'bbc-good-food-pink-hover': '#c55876',

  //Vegerarian Icon
  'vege-green': '#3f8f27',
  'vegan-green': '#84C778',

  // Badges
  gold: '#9A8E5F',
} as const

export type Colors = typeof colors
export type ColorToken = keyof Colors

export type HexToken = `#${string}`
