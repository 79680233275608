export const breakpoints = {
  base: 0, // This must be called 'base'
  sm: 640,
  md: 1024,
  lg: 1280,
  xl: 1536,
} as const

export type Breakpoints = typeof breakpoints
export type Breakpoint = keyof Breakpoints

export const breakpointsArray = Object.keys(breakpoints) as Breakpoint[]

export const createMinWidth = (pixels: number): string =>
  `(min-width: ${pixels}px)`

export const minWidths = {
  base: createMinWidth(breakpoints.base),
  sm: createMinWidth(breakpoints.sm),
  md: createMinWidth(breakpoints.md),
  lg: createMinWidth(breakpoints.lg),
  xl: createMinWidth(breakpoints.xl),
}

export const createMaxWidthMinusOnePixel = (pixels: number): string =>
  `(max-width: ${pixels - 1}px)`

export const maxWidths = {
  base: 0,
  sm: createMaxWidthMinusOnePixel(breakpoints.sm),
  md: createMaxWidthMinusOnePixel(breakpoints.md),
  lg: createMaxWidthMinusOnePixel(breakpoints.lg),
  xl: createMaxWidthMinusOnePixel(breakpoints.xl),
}

export const createMediaQuery = (pixels: number): string =>
  `@media screen and (min-width: ${pixels}px)`

export const media = {
  sm: createMediaQuery(breakpoints.sm),
  md: createMediaQuery(breakpoints.md),
  lg: createMediaQuery(breakpoints.lg),
  xl: createMediaQuery(breakpoints.xl),
}
