import * as CookieConsent from 'vanilla-cookieconsent'

import { mapValues } from '@mindfulchefuk/utils/mapValues'
import {
  CookieCategory,
  ConsentCategory,
} from '@mindfulchefuk/features/Cookies/types'

type GoogleSpecificConsentCategory =
  | 'ad_storage'
  | 'analytics_storage'
  | 'ad_user_data'
  | 'ad_personalization'
type CookieConsentChange = 'granted' | 'denied'
type GoogleConsentCategory = ConsentCategory | GoogleSpecificConsentCategory

type GoogleConsents = Record<GoogleConsentCategory, CookieConsentChange>
type GoogleConsentChanges = Partial<GoogleConsents>

const tagConsentCategories: Record<GoogleConsentCategory, ConsentCategory> = {
  diagnostic: 'diagnostic',
  marketing: 'marketing',
  ad_storage: 'marketing',
  ad_user_data: 'marketing',
  ad_personalization: 'marketing',
  analytics: 'analytics',
  analytics_storage: 'analytics',
}

function gtag(..._args: ['event', 'consent_change']): void
function gtag(..._args: ['consent', 'default', GoogleConsents]): void
function gtag(..._args: ['consent', 'update', GoogleConsentChanges]): void
function gtag() {
  if (typeof window !== 'undefined') {
    // We need this function before gtag.js has loaded and initialized
    window.dataLayer = window.dataLayer || []

    // The official snippet pushes an 'arguments' object and doesn't work with a
    // plain array. It also does not work with a convoluted attempt to replicate
    // an arguments object by Object.assign with length and [Symbol.iterator]:
    // Since we specifically need an actual argments object we'll bypass eslint.
    window.dataLayer.push(arguments) // eslint-disable-line prefer-rest-params
  }
}

function currentTagConsents(): GoogleConsents {
  return mapValues(tagConsentCategories, (category) => {
    return CookieConsent.acceptedCategory(category) ? 'granted' : 'denied'
  })
}

export function setTagManagerDefaultConsents() {
  if (CookieConsent.validConsent()) {
    gtag('consent', 'default', currentTagConsents())
  } else {
    gtag(
      'consent',
      'default',
      mapValues(tagConsentCategories, () => 'denied')
    )
  }
}

export function updateGoogleTagConsents(
  changedCategories: CookieCategory[] = []
) {
  if (!changedCategories.length) return

  const consentChanges = mapValues(tagConsentCategories, (category) => {
    if (!changedCategories.includes(category)) {
      return // Filter out any categories that haven't changed
    }
    return CookieConsent.acceptedCategory(category) ? 'granted' : 'denied'
  })

  gtag('consent', 'update', consentChanges)
  gtag('event', 'consent_change')
}
