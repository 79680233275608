import styled from '@emotion/styled'
import { FieldsetHTMLAttributes } from 'react'

import { resetFormControl } from '@mindfulchefuk/design-system/Forms/form.styles'

export const Fieldset = styled.fieldset<
  FieldsetHTMLAttributes<HTMLFieldSetElement>
>`
  ${resetFormControl}
`
