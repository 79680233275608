/* eslint-disable no-console */
import amplitudeSDK, { AmplitudeClient, Identify } from 'amplitude-js'

export type IdentifyObj = Identify & {
  // digging into hidden properties not exposed by Typescript but exist on the object
  // see: https://github.com/amplitude/Amplitude-JavaScript/blob/main/src/identify.js#L32
  userPropertiesOperations: { [key: string]: unknown }
}

export type MockAmplitudeClient = Pick<
  AmplitudeClient,
  'setUserId' | 'getUserId' | 'init' | 'identify'
> & {
  logEvent: (
    eventName: string,
    eventProperties: { [key: string]: string | number | boolean | string[] }
  ) => void
}

let instance: MockAmplitudeClient
let customerId: string

function noopLog<Type>(arg: Type): Type {
  return arg
}

const quietMode = process.env.NODE_ENV === 'test'
const log = quietMode ? noopLog : console.log

export const mockAmplitude = {
  getInstance: function getInstance(): MockAmplitudeClient {
    if (!instance) {
      instance = {
        init: (apiKey, userId, options) => {
          customerId = userId

          log(
            `Mock Amplitude: using API KEY ${apiKey} and setting userId to ${userId}\n\n`,
            `${
              options
                ? `Mock Amplitude using the following options${JSON.stringify(
                    options,
                    null,
                    2
                  )}`
                : ''
            }`
          )
        },

        setUserId: (id: string | null) => {
          customerId = id

          log(`Mock Amplitude: setting customerId to ${customerId}`)
        },

        logEvent: (eventName, eventProperties) => {
          log(
            `Mock Amplitude: logging event \n ${JSON.stringify(
              {
                'event name': eventName,
                'user id': customerId,
                debuggingValuesNotSent: {
                  timeStamp: Date.now(),
                },
                ...eventProperties,
              },
              null,
              2
            )} \n at ${new Date()}`
          )
        },

        identify: (identifyObj: Identify) => {
          const userProps = JSON.stringify(
            (identifyObj as IdentifyObj).userPropertiesOperations,
            null,
            2
          )

          log(`Mock Amplitude: identify ${userProps}`)
        },

        getUserId: () => {
          log(`Mock Amplitude: getting customerId ${customerId}`)
          return customerId
        },
      }
    }

    return instance
  },
  Identify: amplitudeSDK.Identify,
}
