import React, { VoidFunctionComponent } from 'react'
import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg'

export const KnifeAndForkIcon: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 20 20" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M0 0H993V749H0z" transform="translate(-635 -469)" />
      <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
        <path d="M4.066 1.946c-.13.518-.034 1.555-.005 2.012.095 1.512.113 3.13.458 3.456 1 .944 1.547.41 1.547 4.134 0 1.62-.146 3.61-.232 5.229-.016.299.001.739.081 1.027.143.516.088.632 1.031.634.812.002 1-.592 1.012-1.36.026-1.707.046-3.548.046-5.255 0-3.507.603-3.626 1.421-4.243.611-.461.537-1.298.591-2.302.071-1.348-.04-1.458.076-2.803.036-.425-.31-1-.755-1.167-.605-.23-.56.689-.578 1.038l-.07 1.412c-.022.428.003.877-.173 1.276-.128.292-.475.405-.656.1-.187-.315-.182-.71-.175-1.064.012-.554.023-1.107.033-1.661.006-.26.014-.521.024-.78.006-.16.015-.434-.162-.515-.2-.09-.499-.037-.706.002-.097.019-.245.027-.31.112-.086.11-.046.335-.046.462 0 1.22.018 1.597.018 2.94 0 .247-.06.574-.303.703-.218.115-.522.017-.648-.192-.14-1.218-.082-2.431-.062-3.653.004-.243-.715-.385-1.063-.121-.324.246-.385.544-.394.579zM13.994 11.651l-1.589-.423c-.278-.074.337-5.174.392-5.598.228-1.79 1.841-4.308 3.9-4.63-.013.369-.2 13.939-.145 15.171.083 1.906.392 1.919-2.172 2.223.218-.066-.159-4.818-.195-5.304" />
      </g>
    </g>
  </Svg>
)
