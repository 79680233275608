import isPropValid from '@emotion/is-prop-valid'
import each from 'lodash/forEach'

import {
  CSSMapperFunction,
  CSSMapperResult,
  CSSPropMapper,
  Responsive,
  ShouldForwardProp,
} from '../types'
import { parseToken } from './parseToken'
import { media, breakpointsArray, Breakpoint } from '../theme/breakpoints'
import { createStylesByMediaQuery } from './createStylesByMediaQuery'
import { isResponsiveProp } from './isResponsiveProp'

/**
 * Returns a function that converts props to a CSS object
 * e.g. ({ margin: 16, px: 16 }) => ({ margin: '16px', paddingLeft: '16px', paddingRight: '16px' })
 * Also returns a shouldForwardProp function that can be passed to emotion
 * e.g. Avoids <div color="aubergine"> showing up in the HTML
 * For simplicity (and performance - #1530) we loop over ResponsiveArray and ResponsiveObject with lodash/each
 *
 * Also returns a 'shouldForwardProp' function for emotion
 * */
export const createCSSMapper = <P>(
  mapper: CSSPropMapper<P>
): CSSMapperResult<P> => {
  const shouldForwardProp: ShouldForwardProp = (prop) =>
    isPropValid(prop) && !(prop in mapper)

  const mappingFunction: CSSMapperFunction<P> = (props) => {
    const stylesByMediaQuery = createStylesByMediaQuery()

    each(props, (raw: Responsive<string | number>, key) => {
      const mapperConfig = mapper[key as keyof CSSPropMapper<P>]

      if (!mapperConfig) return

      if (!isResponsiveProp(raw)) {
        stylesByMediaQuery.base = Object.assign(
          stylesByMediaQuery.base,
          parseToken({
            value: raw as string,
            config: mapperConfig,
            props,
            breakpoint: 'base',
          })
        )

        return
      }

      each(raw, (token, indexOrKey) => {
        const breakpoint =
          typeof indexOrKey === 'number'
            ? breakpointsArray[indexOrKey]
            : (indexOrKey as Breakpoint)

        const target = breakpoint === 'base' ? breakpoint : media[breakpoint]

        stylesByMediaQuery[target] = Object.assign(
          stylesByMediaQuery[target],
          parseToken({
            value: token,
            config: mapperConfig,
            props,
            breakpoint,
          })
        )
      })
    })

    const { base: baseStyles } = stylesByMediaQuery
    delete stylesByMediaQuery.base

    each(stylesByMediaQuery, (mqStyles, mq) => {
      if (!Object.keys(mqStyles).length) delete stylesByMediaQuery[mq]
    })

    return Object.assign(baseStyles, stylesByMediaQuery)
  }

  return [mappingFunction, shouldForwardProp]
}
