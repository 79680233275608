import { Property } from 'csstype'
import { ShadowToken } from '../theme/shadows'
import { CSSPropMapper, ResponsiveProps } from '../types'

export type VisibilityProps = ResponsiveProps<{
  opacity?: Property.Opacity
  display?: Property.Display
  visibility?: Property.Visibility
  overflow?: Property.Overflow
  overflowX?: Property.OverflowX
  overflowY?: Property.OverflowY
  listStyleType?: Property.ListStyleType
  whiteSpace?: Property.WhiteSpace
  textDecoration?: Property.TextDecoration
  textUnderlineOffset?: Property.TextUnderlineOffset
  boxShadow?: ShadowToken | Property.BoxShadow
  cursor?: Property.Cursor
  outline?: Property.Outline
  strokeWidth?: Property.StrokeWidth
}>

export const visibility: CSSPropMapper<VisibilityProps> = {
  opacity: { property: 'opacity' },
  display: { property: 'display' },
  visibility: { property: 'visibility' },
  overflow: { property: 'overflow' },
  overflowX: { property: 'overflowX' },
  overflowY: { property: 'overflowY' },
  listStyleType: { property: 'listStyleType' },
  whiteSpace: { property: 'whiteSpace' },
  textDecoration: { property: 'textDecoration' },
  textUnderlineOffset: { property: 'textUnderlineOffset' },
  boxShadow: { property: 'boxShadow', themeKey: 'shadows' },
  cursor: { property: 'cursor' },
  outline: { property: 'outline' },
  strokeWidth: { property: 'strokeWidth' },
}
