import { Breakpoint, breakpointsArray } from '../theme/breakpoints'
import { Responsive } from '../types'
import { isResponsiveArray, isResponsiveObject } from './isResponsiveProp'

/**
 * For a given breakpoint key, extract the value from a possibly responsive value
 */
export const getValueAtBreakpoint = (
  token: Responsive<string | number>,
  breakpoint: Breakpoint
): string | number => {
  if (token == null) return undefined

  if (isResponsiveArray(token)) {
    const index = breakpointsArray.findIndex((bp) => bp === breakpoint)

    return token[index]
  }

  if (isResponsiveObject(token)) {
    return token[breakpoint]
  }

  return token
}
