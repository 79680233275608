import React, { VoidFunctionComponent, ReactElement } from 'react'
import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg'

export const BoxIcon: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 21 20" {...props}>
    <g stroke="currentColor" fill="none">
      <path
        transform="translate(.33333 3)"
        d="M2.94 4.734c.945-.815 1.89-2.1 2.836-2.925.945 0 6.125-.056 8.366 0l.983 3.075-12.204-.15-.227 8.485c-.022.223.021.447.123.647.245.252.594.377.945.337 4.052.173 8.11.126 12.157-.14V8.436M3.006 4.575c-.766-.413-1.55-.938-2.24-1.36-.161-.103-.34-.234-.35-.402C.406 2.643 3.034.478 3.649.777c.614.3.945.544 1.89 1.013"
      />
      <path
        transform="translate(.33333 3)"
        d="M13.84 1.875L16.145.572c.996.78 1.943 1.619 2.836 2.512-1.248.525-2.486 1.097-3.696 1.716"
      />
    </g>
  </Svg>
)

export const BoxIconDelivered = (props: BoxProps): ReactElement => (
  <Svg viewBox="0 0 20 20" {...props} fill="none">
    <path
      d="M2.91547 7.73418C3.85297 6.91855 4.79047 5.63418 5.72797 4.80918C6.66547 4.80918 11.803 4.75293 14.0248 4.80918L14.9998 7.88418L2.89672 7.73418L2.67172 16.2186C2.65023 16.4414 2.69249 16.6657 2.79359 16.8654C3.03652 17.1177 3.38309 17.2425 3.73109 17.2029C7.7494 17.3757 11.7742 17.3287 15.7873 17.0623V11.4373"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.98113 7.57486C2.22175 7.16236 1.44363 6.63736 0.759254 6.21549C0.599879 6.11236 0.421754 5.98111 0.412379 5.81236C0.403004 5.64361 3.00925 3.47799 3.61863 3.77799C4.228 4.07799 4.55613 4.32174 5.49363 4.79049"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.7249 4.8749L16.0124 3.57178C16.9999 4.35155 17.9391 5.1906 18.8249 6.08428C17.5874 6.60928 16.3592 7.18115 15.1592 7.7999"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.5489 4.58955C19.4053 3.40132 18.8883 2.39046 18.0259 1.55739C17.5322 1.08054 16.955 0.742652 16.2884 0.561905C15.8569 0.444897 15.4184 0.431432 14.9754 0.468578C14.2509 0.529326 13.5604 0.710782 12.9261 1.06696C11.7689 1.71678 11.0636 2.66578 11.0042 4.02402C10.9997 4.12845 10.9987 4.23278 11.0019 4.33689C11.0032 4.37853 11.0051 4.42012 11.0077 4.46168C11.0114 4.51998 11.0166 4.57817 11.0232 4.63626C11.0243 4.64547 11.0254 4.65469 11.0265 4.66386C11.032 4.70908 11.0384 4.75421 11.0458 4.79925C11.0507 4.82945 11.056 4.85957 11.0618 4.88967C11.0622 4.89171 11.0625 4.89374 11.0629 4.89579C11.0709 4.93689 11.0798 4.97791 11.0895 5.01884C11.0925 5.04504 11.0959 5.07114 11.0998 5.09711C11.1078 5.15016 11.1183 5.20262 11.1335 5.25396C11.1401 5.27632 11.1476 5.29846 11.1562 5.32033C11.1604 5.3309 11.1646 5.34148 11.1693 5.35193C11.1697 5.35563 11.1699 5.35935 11.17 5.36311C11.1705 5.37159 11.1707 5.38008 11.1718 5.38837C11.1723 5.39236 11.1731 5.39628 11.1741 5.40014C11.3422 6.01691 11.6239 6.57696 12.0268 7.07484C12.0518 7.10578 12.0766 7.1371 12.1017 7.16814C12.1211 7.19202 12.1408 7.21556 12.1609 7.23883C12.2191 7.30627 12.2815 7.36999 12.3554 7.42255C12.3576 7.42726 12.3593 7.43243 12.3612 7.43751C12.3627 7.44171 12.3643 7.44584 12.3664 7.44948C12.3679 7.45216 12.3696 7.45458 12.3718 7.45661C12.6734 7.74354 13.0031 7.99084 13.3854 8.16334C13.402 8.17401 13.419 8.18369 13.4364 8.19259C13.4884 8.2193 13.5436 8.23893 13.5996 8.25666C13.7001 8.30485 13.8032 8.34515 13.91 8.37434C13.9146 8.37556 13.919 8.37694 13.9235 8.37814C13.9608 8.38794 13.9985 8.39637 14.0367 8.40324C14.043 8.40755 14.0495 8.41064 14.0562 8.41332C14.0874 8.42566 14.1223 8.42336 14.1541 8.4332C14.3091 8.47597 14.467 8.50154 14.6263 8.51373C14.6794 8.51778 14.7327 8.52034 14.786 8.52154C15.2484 8.53206 15.7085 8.50582 16.1633 8.41552C16.414 8.36578 16.6657 8.31969 16.9048 8.22536C17.2937 8.11566 17.6581 7.9495 17.9994 7.73631C19.168 7.00643 19.7153 5.96643 19.5489 4.58955Z"
      fill="currentColor"
    />
    <path
      d="M19.5489 4.58955C19.4053 3.40132 18.8883 2.39046 18.0259 1.55739C17.5322 1.08054 16.955 0.742652 16.2884 0.561905C15.8569 0.444897 15.4184 0.431432 14.9754 0.468578C14.2509 0.529326 13.5604 0.710782 12.9261 1.06696C11.7689 1.71678 11.0636 2.66578 11.0042 4.02402C10.9997 4.12845 10.9987 4.23278 11.0019 4.33689C11.0032 4.37853 11.0051 4.42012 11.0077 4.46168C11.0114 4.51998 11.0166 4.57817 11.0232 4.63626C11.0243 4.64547 11.0254 4.65469 11.0265 4.66386C11.032 4.70908 11.0384 4.75421 11.0458 4.79925C11.0507 4.82945 11.056 4.85957 11.0618 4.88967C11.0622 4.89171 11.0625 4.89374 11.0629 4.89579C11.0709 4.93689 11.0798 4.97791 11.0895 5.01884C11.0925 5.04504 11.0959 5.07114 11.0998 5.09711C11.1078 5.15016 11.1183 5.20262 11.1335 5.25396C11.1401 5.27632 11.1476 5.29846 11.1562 5.32033C11.1604 5.3309 11.1646 5.34148 11.1693 5.35193C11.1697 5.35563 11.1699 5.35935 11.17 5.36311C11.1705 5.37159 11.1707 5.38008 11.1718 5.38837C11.1723 5.39236 11.1731 5.39628 11.1741 5.40014C11.3422 6.01691 11.6239 6.57696 12.0268 7.07484C12.0518 7.10578 12.0766 7.1371 12.1017 7.16814C12.1211 7.19202 12.1408 7.21556 12.1609 7.23883C12.2191 7.30627 12.2815 7.36999 12.3554 7.42255C12.3576 7.42726 12.3593 7.43243 12.3612 7.43751C12.3627 7.44171 12.3643 7.44584 12.3664 7.44948C12.3679 7.45216 12.3696 7.45458 12.3718 7.45661C12.6734 7.74353 13.0031 7.99084 13.3854 8.16334C13.402 8.17401 13.419 8.18369 13.4364 8.19259C13.4884 8.2193 13.5436 8.23893 13.5996 8.25666C13.7001 8.30485 13.8032 8.34515 13.91 8.37434C13.9146 8.37556 13.919 8.37694 13.9235 8.37814C13.9608 8.38794 13.9985 8.39637 14.0367 8.40324C14.043 8.40755 14.0495 8.41064 14.0562 8.41332C14.0874 8.42566 14.1223 8.42336 14.1541 8.4332C14.3091 8.47597 14.467 8.50154 14.6263 8.51373C14.6794 8.51778 14.7327 8.52034 14.786 8.52154C15.2484 8.53206 15.7085 8.50582 16.1633 8.41552C16.414 8.36578 16.6657 8.31969 16.9048 8.22536C17.2937 8.11566 17.6581 7.9495 17.9994 7.73631C19.168 7.00643 19.7153 5.96643 19.5489 4.58955"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9731 5.17382C13.0686 5.05922 13.2389 5.04373 13.3535 5.13924L14.5592 6.14399C14.6738 6.23949 14.6893 6.40982 14.5938 6.52443C14.4983 6.63904 14.328 6.65452 14.2134 6.55902L13.0077 5.55426C12.893 5.45876 12.8776 5.28843 12.9731 5.17382Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2523 6.53781C14.1357 6.44475 14.1166 6.27479 14.2097 6.15818L17.0735 2.56963C17.1666 2.45303 17.3366 2.43394 17.4532 2.52699C17.5698 2.62005 17.5889 2.79001 17.4958 2.90662L14.6319 6.49517C14.5389 6.61177 14.3689 6.63086 14.2523 6.53781Z"
      fill="white"
    />
  </Svg>
)
