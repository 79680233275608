import React, { VoidFunctionComponent } from 'react'

import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg/Svg'

export const FullChevronIcon: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 8 15" {...props}>
    <path
      d="M0 0c2.334 1.72 4.491 3.776 6.429 6.124.34.335.58.797.685 1.317.097.856-.546 1.554-1.135 2.094L.032 15"
      fill="currentColor"
      fillRule="nonzero"
    />
  </Svg>
)
