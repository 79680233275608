import Router from 'next/router'

import routesConfig from '@mindfulchefuk/config/routesConfig'
import errorHandler from '@mindfulchefuk/utils/errorHandler'

export type SpraypaintError = { title?: string; status?: string }

interface ResponseError {
  json: { errors?: SpraypaintError[] }
}

const spraypaintError = (response: ResponseError): void => {
  const [{ title = '', status = '' } = {}] = response?.json?.errors || []

  let errorMessage = title

  if (!title) {
    switch (status) {
      case '400':
        errorMessage = 'Bad Request'
        break
      case '401':
        errorMessage = 'Unauthorized'
        break
      case '403':
        errorMessage = 'Forbidden'
        break
      case '404':
        errorMessage = 'Not Found'
        break
      case '405':
        errorMessage = 'Method Not Allowed'
        break
      case '500':
        errorMessage = 'Internal Server Error'
        break
      case '501':
        errorMessage = 'Not Implemented'
        break
      case '503':
        errorMessage = 'Service Unavailable'
        break
      default:
        errorMessage = 'Unknown Server Error'
    }
  }

  errorHandler(new Error(errorMessage), { suppress: true })

  // Automatically redirect to the 404 page only
  if (status === '404') {
    Router.push(routesConfig.custom404.pathname, routesConfig.custom404.as)
  }
}

export default spraypaintError
