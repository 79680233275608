import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { Products } from '@mindfulchefuk/query/Products/interfaces'
import { STALE_1_HOUR } from '@mindfulchefuk/constants'
import { getCustomerProductsWithFeedbacks } from '@mindfulchefuk/query/Products/services'

export const useQueryCustomerProductsWithFeedbacks = <TData = Products>(
  customerId: string,
  options: UseQueryOptions<Products, unknown, TData> = {}
) => {
  return useQuery({
    queryKey: ['customer-products'],
    queryFn: () => getCustomerProductsWithFeedbacks({ customerId }),
    staleTime: STALE_1_HOUR,
    ...options,
  })
}
