import React from 'react'
import ErrorPage from 'next/error'
import PropTypes from 'prop-types'
import Bugsnag from '@mindfulchefuk/lib/bugsnag'

export default class Page extends React.Component {
  static async getInitialProps(ctx) {
    if (ctx.err) Bugsnag.notify(ctx.err)
    return ErrorPage.getInitialProps(ctx)
  }

  render() {
    const { statusCode } = this.props
    return <ErrorPage statusCode={statusCode} />
  }
}

Page.propTypes = {
  statusCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

Page.defaultProps = {
  statusCode: '¯\\_(ツ)_/¯',
}
