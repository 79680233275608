import React, { VoidFunctionComponent } from 'react'
import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '@mindfulchefuk/design-system/Svg/Svg'

const defaultStyles = {
  display: 'block',
  fill: 'none',
  preserveAspectRatio: 'none',
  width: '100%',
}

export const WaveT1: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 2390 149" maxHeight="65px" {...defaultStyles} {...props}>
    <path
      fill="currentColor"
      d="M0 0c249 35 501 49 753 42l442-12 675-22c174-6 348 1 520 22v119H0V0Z"
    />
  </Svg>
)

export const WaveT2: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 2390 101" maxHeight="50px" {...defaultStyles} {...props}>
    <path
      fill="currentColor"
      d="m0 22 174-12C409-6 645-2 879 21c84 8 168 10 251 6l203-10 351-10 415-6h291v100H0V22Z"
    />
  </Svg>
)

export const WaveT3: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 2390 80" maxHeight="45px" {...defaultStyles} {...props}>
    <path
      fill="currentColor"
      d="M2390 80V17l-896 37c-241 10-482 2-721-24l-47-5C485-1 242-6 0 9v71h2390Z"
    />
  </Svg>
)

export const WaveT4: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 2390 97" maxHeight="50px" {...defaultStyles} {...props}>
    <path
      fill="currentColor"
      d="M0 19 1296 1c109-1 217 5 325 19 199 28 400 37 600 26l169-9v60H0V19Z"
    />
  </Svg>
)

export const WaveT5: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 2390 126" maxHeight="50px" {...defaultStyles} {...props}>
    <path fill="currentColor" d="m0 0 23 2c788 65 1579 65 2367-2v126H0V0Z" />
  </Svg>
)

export const WaveB1: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 2390 165" maxHeight="65px" {...defaultStyles} {...props}>
    <path
      fill="currentColor"
      d="M0 0h2390v126c-205 29-412 42-618 37L0 126V0Z"
    />
  </Svg>
)

export const WaveB2: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 2390 131" maxHeight="65px" {...defaultStyles} {...props}>
    <path
      fill="currentColor"
      d="M0 0h2390v77l-65 16c-119 30-242 42-365 36l-611-33c-234-13-469-7-702 17-70 7-141 8-212 2L0 77V0Z"
    />
  </Svg>
)

export const WaveB3: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 2390 98" maxHeight="45px" {...defaultStyles} {...props}>
    <path
      fill="currentColor"
      d="M2390 75V0H0v67c237-21 476-24 714-7l384 28c185 13 371 12 555-2l348-28c40-3 80-3 120-1l269 18Z"
    />
  </Svg>
)

export const WaveB4: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 2390 78" maxHeight="30px" {...defaultStyles} {...props}>
    <path fill="currentColor" d="M0 0h2390v19C1869 73 1344 90 820 68L0 34V0Z" />
  </Svg>
)

export const WaveB5: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 2390 128" maxHeight="30px" {...defaultStyles} {...props}>
    <path
      fill="currentColor"
      d="M0 0h2390v91l-45-3c-204-14-409-10-613 10l-243 24c-63 6-127 7-190 1l-262-22C692 72 346 68 0 91V0Z"
    />
  </Svg>
)
