import React, { ReactElement, useState, useEffect } from 'react'
import { Swiper, SwiperProps } from 'swiper/react'
import { Swiper as SwiperType } from 'swiper'

import 'swiper/swiper.min.css'
import { CarouselButton } from './CarouselButton'
import { Flex } from '../layout'
import {
  CarouselProvider,
  useCarouselContext,
} from './context/useCarouselContext'

export type CarouselProps = {
  slides: ReactElement[]
  onPreviousButtonClick?: () => void
  onNextButtonClick?: () => void
  showButtonsOnHover?: boolean
  showButtonsOnIndexViewed?: boolean
  testIds?: {
    nextSlideButton: string
    previousSlideButton: string
    carouselContainer?: string
  }
}

export const Carousel = ({
  slides,
  onPreviousButtonClick = () => null,
  onNextButtonClick = () => null,
  showButtonsOnHover = false,
  showButtonsOnIndexViewed = false,
  testIds,
  ...props
}: CarouselProps &
  Omit<
    SwiperProps,
    'onSlideChangeTransitionEnd' | 'onSwiper'
  >): ReactElement => {
  const [showingPreviousSlideButton, setShowingPreviousSlideButton] =
    useState(false)
  const [showingNextSlideButton, setShowingNextSlideButton] = useState(false)

  const { setSwiperInstance, swiperInstance } = useCarouselContext()

  const handleSlideChange = (swiper: SwiperType) => {
    setShowingPreviousSlideButton(swiper.realIndex > 0)
    setShowingNextSlideButton(swiper.realIndex < slides.length - 1)
  }

  const defaultSwiperProps: Pick<
    SwiperProps,
    'keyboard' | 'slidesPerView' | 'speed'
  > = {
    keyboard: true,
    slidesPerView: 'auto',
    speed: 400,
  }

  useEffect(() => {
    if (showButtonsOnIndexViewed && slides.length >= 1) {
      setShowingNextSlideButton(true)
    }
  }, [showButtonsOnIndexViewed, slides?.length])

  return (
    <CarouselProvider>
      <Flex
        alignItems="center"
        position="relative"
        onMouseEnter={() => {
          if (showButtonsOnHover) {
            setShowingNextSlideButton(true)
            setShowingPreviousSlideButton(true)
          }
        }}
        onMouseLeave={() => {
          if (showButtonsOnHover) {
            setShowingNextSlideButton(false)
            setShowingPreviousSlideButton(false)
          }
        }}
      >
        <CarouselButton
          visible={showingPreviousSlideButton}
          direction="previous"
          onClick={() => {
            swiperInstance.slidePrev()
            onPreviousButtonClick()
          }}
          aria-label="Show previous slide"
          data-testid={testIds ? testIds.previousSlideButton : null}
        />
        <Swiper
          {...defaultSwiperProps}
          {...props}
          onSlideChange={(swiper) =>
            showButtonsOnIndexViewed && handleSlideChange(swiper)
          }
          onSwiper={setSwiperInstance}
          data-testid={testIds?.carouselContainer || 'carousel'}
        >
          {slides}
        </Swiper>
        <CarouselButton
          visible={showingNextSlideButton}
          direction="next"
          onClick={() => {
            swiperInstance.slideNext()
            onNextButtonClick()
          }}
          aria-label="Show next slide"
          data-testid={testIds ? testIds.nextSlideButton : null}
        />
      </Flex>
    </CarouselProvider>
  )
}
