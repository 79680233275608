import React, { VoidFunctionComponent } from 'react'

import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg'

export const FilterIcon: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 21 20" {...props}>
    <g stroke="currentColor" fill="none">
      <path
        transform="translate(2.35 3)"
        d="M.907 2.19L4.033 2.19M8.46 2.09L15.589 2.09M1.008 7.39L9.68 7.39M1.008 12.42L1.583 12.42M13.895 7.39L15.589 7.39M5.556 12.42L5.828 12.37 15.73 12.37M5.385 12.19c-.059-.66-.466-1.238-1.07-1.52-.608-.264-1.312-.184-1.845.21-.467.367-.732.932-.714 1.523.017.59.316 1.139.805 1.477.583.386 1.33.432 1.956.12.608-.334.992-.961 1.009-1.65M13.683 7.26c-.061-.653-.476-1.221-1.082-1.483-.606-.263-1.308-.177-1.832.223-.468.367-.733.932-.715 1.523.018.59.317 1.139.806 1.477.575.423 1.342.489 1.981.17.64-.32 1.044-.97 1.044-1.68M8.067 2C8.04 1.337 7.655.74 7.058.44 6.451.169 5.743.25 5.213.65c-.602.477-.853 1.267-.635 2 .121.402.373.754.716 1 .583.38 1.323.432 1.956.14.614-.332.999-.967 1.008-1.66"
      />
    </g>
  </Svg>
)
