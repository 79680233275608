import { datadogRum } from '@datadog/browser-rum'
import { getVersion } from '@mindfulchefuk/utils/getVersion'
import { isObjTrue } from '@mindfulchefuk/utils/isObjTrue'

export const init = () => {
  if (isObjTrue(process.env.NEXT_PUBLIC_ENABLE_DATADOG_RUM)) {
    datadogRum.init({
      clientToken: process.env.NEXT_PUBLIC_DATADOG_APP_KEY,
      applicationId: process.env.NEXT_PUBLIC_DATADOG_APP_ID,
      site: 'datadoghq.eu',
      trackInteractions: false,
      sampleRate: 10,
      service: `frontend-${process.env.NEXT_PUBLIC_DD_ENV}`,
      env: process.env.NEXT_PUBLIC_DD_ENV,
      version: getVersion(),
      allowedTracingOrigins: [/https:\/\/api(\.[a-z]+)?\.mindfulchef\.com/],
      replaySampleRate: 0,
    })
  }
}

export const trackAction = <T extends object>(name: string, context?: T) => {
  if (isObjTrue(process.env.NEXT_PUBLIC_ENABLE_DATADOG_RUM)) {
    datadogRum.addAction(name, context)
  }
}
