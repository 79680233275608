import { Property } from 'csstype'
import { CSSPropMapper, ResponsiveProps } from '../types'

export type FlexChildProps = ResponsiveProps<{
  flexBasis?: Property.FlexBasis
  flexGrow?: Property.FlexGrow
  flexShrink?: Property.FlexShrink
  flex?: Property.Flex
  order?: Property.Order
  alignSelf?: Property.AlignSelf
}>

export const flexChild: CSSPropMapper<FlexChildProps> = {
  flexBasis: { property: 'flexBasis' },
  flexGrow: { property: 'flexGrow' },
  flexShrink: { property: 'flexShrink' },
  flex: { property: 'flex' },
  order: { property: 'order' },
  alignSelf: { property: 'alignSelf' },
}
