import { SpaceToken } from '../theme/space'
import { CSSPropMapper, ResponsiveProps } from '../types'

export type GapProps = ResponsiveProps<{
  gap?: SpaceToken
  columnGap?: SpaceToken
  rowGap?: SpaceToken
}>

export const gap: CSSPropMapper<GapProps> = {
  gap: { property: 'gap', themeKey: 'space' },
  columnGap: { property: 'columnGap', themeKey: 'space' },
  rowGap: { property: 'rowGap', themeKey: 'space' },
}
