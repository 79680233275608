import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ProductCartState } from '@mindfulchefuk/features/Cart/interfaces'
import { Product } from '@mindfulchefuk/query'

export const initialState = {
  initialProducts: [], //  used to track changes made to the cart
  selectedProducts: [],
  lastAddedProductId: '',
} as ProductCartState

const productCartSlice = createSlice({
  name: 'productCart',
  initialState,
  reducers: {
    updateProductCart: (state, action) => {
      const {
        payload: { products },
      } = action
      return {
        ...state,
        selectedProducts: products,
        initialProducts: products,
        lastAddedProductId: '',
      }
    },
    addProduct: (state, action: PayloadAction<Product>) => {
      const {
        payload: { id, price },
      } = action

      const updatedProducts = []
      let updateProducts = false
      const matchedProducts = state.selectedProducts.map((product) => {
        if (product.id === action.payload.id) {
          updateProducts = true
          return { id, price, quantity: product.quantity + 1 }
        }
        return product
      })

      if (updateProducts) {
        updatedProducts.push(...matchedProducts)
      } else {
        updatedProducts.push(...state.selectedProducts, {
          id,
          price,
          quantity: 1,
        })
      }

      return {
        ...state,
        selectedProducts: updatedProducts,
        lastAddedProductId: action.payload.id,
      }
    },

    decrementProduct: (state, action: PayloadAction<Product>) => {
      const updatedProducts = state.selectedProducts
        .map((product) => {
          if (product.id === action.payload.id) {
            return { ...product, quantity: product.quantity - 1 }
          }
          return product
        })
        .filter(({ quantity }) => quantity !== 0)

      return {
        ...state,
        selectedProducts: updatedProducts,
        lastAddedProductId: '',
      }
    },

    resetProductCart: () => ({
      ...initialState,
    }),
  },
})

const { actions, reducer } = productCartSlice

export const {
  updateProductCart,
  addProduct,
  decrementProduct,
  resetProductCart,
} = actions

export default reducer
