import { CSSPropMapperPropertyConfig } from '@mindfulchefuk/design-system/system/types'
import { ObjectInterpolation } from 'emotion'
import { getThemeToken } from './getThemeToken'

export const parsePropertyConfig = (
  value: string | number,
  config: CSSPropMapperPropertyConfig
): ObjectInterpolation<undefined> => {
  const properties = Array.isArray(config.property)
    ? config.property
    : [config.property]

  const styles: Record<string, string | number> = {}

  if (value == null) return styles

  properties.forEach((property) => {
    if (config.themeKey === undefined) {
      styles[property] = value
      return
    }

    styles[property] = getThemeToken(value, config)
  })

  return styles
}
