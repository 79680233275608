import React, { ReactElement } from 'react'

import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg/Svg'

export const RoundelVeggieIcon = (props: BoxProps): ReactElement => (
  <Svg
    viewBox="0 0 29 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.33763 21.4282L3.86948 28.4735H2.57093L0.102783 21.4282H1.25397L3.18797 27.0644H3.27086L5.20486 21.4282H6.33763Z"
      fill="currentColor"
    />
    <path
      d="M11.053 26.2172H7.33233C7.37838 27.1105 7.92174 27.7367 8.81506 27.7367C9.45052 27.7367 9.91099 27.4789 10.0768 26.9631H11.0622C10.8504 27.967 10.0031 28.5748 8.81506 28.5748C7.36917 28.5748 6.32849 27.5618 6.32849 26.0054C6.32849 24.3661 7.406 23.3346 8.77822 23.3346C10.0031 23.3346 11.0806 24.145 11.0806 25.8764C11.0806 25.9777 11.0714 26.079 11.0622 26.208L11.053 26.2172ZM7.34154 25.4896H10.0768C10.0676 24.716 9.54261 24.1727 8.76901 24.1727C7.99541 24.1727 7.44284 24.7529 7.34154 25.4896Z"
      fill="currentColor"
    />
    <path
      d="M15.584 24.099C15.7958 24.3661 15.9063 24.7068 15.9063 25.066C15.9063 26.1343 15.0406 26.779 13.9263 26.779C13.5947 26.779 13.2908 26.7329 13.033 26.6316C12.8396 26.7329 12.7383 26.8618 12.7383 27.046C12.7383 27.3407 13.033 27.4789 13.4382 27.4789H14.4328C15.584 27.4789 16.2102 28.0315 16.2102 28.9156C16.2102 29.9839 15.2985 30.5825 13.9539 30.5825C12.4251 30.5825 11.7068 29.7997 11.6239 28.8695L12.4251 28.1788C12.0383 28.0222 11.7989 27.6815 11.7989 27.2579C11.7989 26.8342 12.1028 26.4935 12.4528 26.3001C12.112 26.0054 11.9094 25.5817 11.9094 25.0752C11.9094 24.0898 12.6738 23.3438 13.871 23.3438C14.3131 23.3438 14.7091 23.4451 15.0314 23.6385C15.3445 23.482 15.6669 23.1965 15.8695 22.9294L16.4129 23.5096C16.2563 23.703 15.934 23.9332 15.5748 24.099H15.584ZM14.3131 28.2801H13.4013L12.6277 28.934C12.7475 29.3668 13.1251 29.7905 13.9631 29.7905C14.6354 29.7905 15.234 29.5326 15.234 28.9524C15.234 28.4275 14.7275 28.2801 14.3223 28.2801H14.3131ZM14.9209 25.0568C14.9209 24.4858 14.5341 24.0898 13.9263 24.0898C13.3185 24.0898 12.904 24.4858 12.904 25.0568C12.904 25.5909 13.3092 25.9962 13.9263 25.9962C14.5433 25.9962 14.9209 25.5909 14.9209 25.0568Z"
      fill="currentColor"
    />
    <path
      d="M20.6309 24.099C20.8427 24.3661 20.9532 24.7068 20.9532 25.066C20.9532 26.1343 20.0875 26.779 18.9732 26.779C18.6416 26.779 18.3377 26.7329 18.0798 26.6316C17.8864 26.7329 17.7851 26.8618 17.7851 27.046C17.7851 27.3407 18.0798 27.4789 18.4851 27.4789H19.4797C20.6309 27.4789 21.2571 28.0315 21.2571 28.9156C21.2571 29.9839 20.3454 30.5825 19.0008 30.5825C17.472 30.5825 16.7537 29.7997 16.6708 28.8695L17.472 28.1788C17.0852 28.0222 16.8458 27.6815 16.8458 27.2579C16.8458 26.8342 17.1497 26.4935 17.4996 26.3001C17.1589 26.0054 16.9563 25.5817 16.9563 25.0752C16.9563 24.0898 17.7207 23.3438 18.9179 23.3438C19.36 23.3438 19.756 23.4451 20.0783 23.6385C20.3914 23.482 20.7138 23.1965 20.9164 22.9294L21.4597 23.5096C21.3032 23.703 20.9808 23.9332 20.6217 24.099H20.6309ZM19.36 28.2801H18.4482L17.6746 28.934C17.7943 29.3668 18.1719 29.7905 19.01 29.7905C19.6823 29.7905 20.2809 29.5326 20.2809 28.9524C20.2809 28.4275 19.7744 28.2801 19.3692 28.2801H19.36ZM19.9678 25.0568C19.9678 24.4858 19.581 24.0898 18.9732 24.0898C18.3653 24.0898 17.9509 24.4858 17.9509 25.0568C17.9509 25.5909 18.3561 25.9962 18.9732 25.9962C19.5902 25.9962 19.9678 25.5909 19.9678 25.0568Z"
      fill="currentColor"
    />
    <path
      d="M21.957 22.0453C21.957 21.6861 22.2609 21.4006 22.6201 21.4006C22.9793 21.4006 23.2648 21.6861 23.2648 22.0453C23.2648 22.4045 22.9701 22.6992 22.6201 22.6992C22.2702 22.6992 21.957 22.3953 21.957 22.0453ZM22.1044 23.4451H23.1266V28.4735H22.1044V23.4451Z"
      fill="currentColor"
    />
    <path
      d="M28.6432 26.2172H24.9225C24.9686 27.1105 25.5119 27.7367 26.4053 27.7367C27.0407 27.7367 27.5012 27.4789 27.667 26.9631H28.6524C28.4406 27.967 27.5933 28.5748 26.4053 28.5748C24.9594 28.5748 23.9187 27.5618 23.9187 26.0054C23.9187 24.3661 24.9962 23.3346 26.3592 23.3346C27.5841 23.3346 28.6616 24.145 28.6616 25.8764C28.6616 25.9777 28.6524 26.079 28.6432 26.208V26.2172ZM24.9317 25.4896H27.667C27.6578 24.716 27.1328 24.1727 26.3592 24.1727C25.5856 24.1727 25.0331 24.7529 24.9317 25.4896Z"
      fill="currentColor"
    />
    <path
      d="M13.2264 17.6616C12.8765 17.6616 12.6462 17.5142 12.4989 17.1643L7.19421 3.64469C6.84425 2.71453 6.46666 2.34615 5.91409 2.04223L5.49045 1.82121C5.16812 1.64622 5.03918 1.49887 5.03918 1.19496V0.771321C5.03918 0.375311 5.28784 0.145074 5.66543 0.145074H11.2004C11.5779 0.145074 11.8266 0.366102 11.8266 0.771321V1.19496C11.8266 1.49887 11.6977 1.67385 11.3753 1.82121L10.9517 1.99619C10.2978 2.27247 10.2241 2.54876 10.4728 3.19342L14.2027 13.1029C14.304 13.3791 14.5066 13.3791 14.6079 13.1029L18.2088 3.2671C18.4574 2.5948 18.2825 2.24484 17.5825 1.9133L17.1589 1.73832C16.8366 1.59097 16.7076 1.41599 16.7076 1.11207V0.762112C16.7076 0.366102 16.9287 0.135864 17.3339 0.135864H21.9386C22.3162 0.135864 22.5373 0.356893 22.5373 0.762112V1.16733C22.5373 1.48966 22.436 1.66464 22.1136 1.82121L21.7176 2.02382C21.0177 2.34615 20.7138 2.69611 20.4375 3.42366L15.2525 17.1643C15.1236 17.4866 14.8749 17.6616 14.525 17.6616H13.2264Z"
      fill="currentColor"
    />
  </Svg>
)
