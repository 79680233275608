import { Customer } from '@mindfulchefuk/query'

export const selectEmail = (customer: Customer) => customer.email

export const selectFirstName = (customer: Customer) => customer.firstName

export const selectPostcode = (customer: Customer) => customer.address.postcode

export const selectDeliveryAddressId = (customer: Customer) =>
  customer.address.id
