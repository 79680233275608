import { LinkIcon } from './LinkIcon'
import { GiftBoxIcon, GiftTickedIcon } from './GiftBoxIcon'
import { LightningIcon } from './LightningIcon'
import { AddIcon } from './AddIcon'
import { ArrowLeftIcon, ArrowRightIcon } from './ArrowIcon'
import { ArrowCurvedIcon } from './ArrowCurvedIcon'
import { ArrowSlightlyCurvedIcon } from './ArrowSlightlyCurvedIcon'
import { BoxIcon, BoxIconDelivered } from './BoxIcon'
import { CalendarIcon } from './CalendarIcon'
import { CaloriesIcon } from './CaloriesIcon'
import { CartIcon } from './CartIcon'
import { ChatIcon } from './ChatIcon'
import { CheckboxIcon } from './CheckBoxIcon'
import { CheckboxTickedIcon } from './CheckBoxTickedIcon'
import { ChefIcon } from './ChefIcon'
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from './ChevronIcon'
import { ChilliIcon } from './ChilliIcon'
import { ClockIcon } from './ClockIcon'
import { QuoteCloseIcon, QuoteOpenIcon } from './QuoteIcon'
import { CowIcon } from './CowIcon'
import { CrossIcon, ThickCrossIcon } from './CrossIcon'
import { DeliveryIcon } from './DeliveryIcon'
import { DuckIcon } from './DuckIcon'
import { FacebookIcon } from './FacebookIcon'
import { FilterIcon } from './FilterIcon'
import { FishIcon } from './FishIcon'
import { FreezeIcon } from './FreezeIcon'
import { FullChevronIcon } from './FullChevronIcon'
import { HeartIcon } from './HeartIcon'
import { InstagramIcon } from './InstagramIcon'
import { GlobeIcon } from './GlobeIcon'
import { GlutenFreeIcon } from './GlutenFreeIcon'
import { KnifeAndForkIcon } from './KnifeAndForkIcon'
import { LambIcon } from './LambIcon'
import { MenuIcon } from './MenuIcon'
import { MinusIcon } from './MinusIcon'
import { NewIcon } from './NewIcon'
import { PadlockIcon } from './PadlockIcon'
import { PeopleIcon } from './PeopleIcon'
import { PersonIcon } from './PersonIcon'
import { PlusIcon } from './PlusIcon'
import { PorkIcon } from './PorkIcon'
import { PoultryIcon } from './PoultryIcon'
import { PoundIcon } from './PoundIcon'
import { RecipesIcon } from './RecipesIcon'
import { RecycleIcon } from './RecycleIcon'
import { RoundelBBCGoodFoodIcon } from './RoundelBBCGoodFoodIcon'
import { RoundelInfoIcon } from './RoundelInfoIcon'
import { RoundelCrossIcon } from './RoundelCrossIcon'
import { RoundelFamilyClassicsIcon } from './RoundelFamilyClassicsIcon'
import { RoundelTickIcon, RoundelTickIconFilled } from './RoundelTickIcon'
import { RoundelVeggieIcon } from './RoundelVeggieIcon'
import { ShellfishIcon } from './ShellfishIcon'
import { SparkleIcon } from './SparkleIcon'
import { StarIcon, StarIconFilled } from './StarIcon'
import { StarIconRating } from './StarIconRating'
import { SoilIcon } from './SoilIcon'
import { SortIcon } from './SortIcon'
import { TickIcon } from './TickIcon'
import { TimerIcon } from './TimerIcon'
import { TurkeyIcon } from './TurkeyIcon'
import { TwitterIcon } from './TwitterIcon'
import { VenisonIcon } from './VenisonIcon'
import { WarningIcon, WarningIconFilled } from './WarningIcon'
import { SearchIcon } from './SearchIcon'
import { DiscountIcon } from './DiscountIcon'
import { RoundelVeganIcon } from './RoundelVeganIcon'
import { VeganIcon } from './VeganIcon'
import { VeggieIcon } from './VeggieIcon'
import { MeatballMenuIcon } from './MeatballMenuIcon'

export const iconSvgs = {
  add: AddIcon,
  'arrow-slightly-curved': ArrowSlightlyCurvedIcon,
  'arrow-curved': ArrowCurvedIcon,
  'arrow-left': ArrowLeftIcon,
  'arrow-right': ArrowRightIcon,
  box: BoxIcon,
  'box-delivered': BoxIconDelivered,
  calendar: CalendarIcon,
  calories: CaloriesIcon,
  cart: CartIcon,
  chat: ChatIcon,
  checkbox: CheckboxIcon,
  'checkbox-ticked': CheckboxTickedIcon,
  chef: ChefIcon,
  'chevron-left': ChevronLeftIcon,
  'chevron-right': ChevronRightIcon,
  'chevron-up': ChevronUpIcon,
  'chevron-down': ChevronDownIcon,
  chilli: ChilliIcon,
  clock: ClockIcon,
  cow: CowIcon,
  cross: CrossIcon,
  'thick-cross': ThickCrossIcon,
  discount: DiscountIcon,
  duck: DuckIcon,
  facebook: FacebookIcon,
  filter: FilterIcon,
  fish: FishIcon,
  freeze: FreezeIcon,
  'full-chevron': FullChevronIcon,
  'gift-box': GiftBoxIcon,
  'gift-ticked': GiftTickedIcon,
  globe: GlobeIcon,
  'gluten-free': GlutenFreeIcon,
  heart: HeartIcon,
  instagram: InstagramIcon,
  'knife-and-fork': KnifeAndForkIcon,
  lamb: LambIcon,
  lightning: LightningIcon,
  link: LinkIcon,
  'meatball-menu': MeatballMenuIcon,
  menu: MenuIcon,
  minus: MinusIcon,
  new: NewIcon,
  padlock: PadlockIcon,
  people: PeopleIcon,
  person: PersonIcon,
  plus: PlusIcon,
  pork: PorkIcon,
  poultry: PoultryIcon,
  pound: PoundIcon,
  'quote-open': QuoteOpenIcon,
  'quote-close': QuoteCloseIcon,
  recycle: RecycleIcon,
  recipes: RecipesIcon,
  'roundel-bbc-good-food': RoundelBBCGoodFoodIcon,
  'roundel-cross': RoundelCrossIcon,
  'roundel-family-classics': RoundelFamilyClassicsIcon,
  'roundel-info': RoundelInfoIcon,
  'roundel-tick': RoundelTickIcon,
  'roundel-tick-filled': RoundelTickIconFilled,
  'roundel-vegan': RoundelVeganIcon,
  'roundel-vege': RoundelVeggieIcon,
  search: SearchIcon,
  shellfish: ShellfishIcon,
  sparkle: SparkleIcon,
  star: StarIcon,
  'star-rating': StarIconRating,
  'star-filled': StarIconFilled,
  soil: SoilIcon,
  sort: SortIcon,
  tick: TickIcon,
  timer: TimerIcon,
  turkey: TurkeyIcon,
  twitter: TwitterIcon,
  vegan: VeganIcon,
  venison: VenisonIcon,
  delivery: DeliveryIcon,
  warning: WarningIcon,
  'warning-filled': WarningIconFilled,
  vege: VeggieIcon,
}

export type IconType = keyof typeof iconSvgs
