import styled, { StyledComponent } from '@emotion/styled'
import { ReactDOM } from 'react'
import {
  background,
  BackgroundProps,
  border,
  BorderProps,
  color,
  ColorProps,
  flexChild,
  FlexChildProps,
  gridChild,
  GridChildProps,
  margin,
  MarginProps,
  padding,
  PaddingProps,
  position,
  PositionProps,
  size,
  SizeProps,
  VisibilityProps,
  visibility,
  flexParent,
  gridParent,
  FlexParentProps,
  GridParentProps,
  alignment,
  AlignmentProps,
} from '@mindfulchefuk/design-system/system'
import { createCSSMapper } from '../system/utils/createCSSMapper'

/**
 * The "as" prop is strangely not part of emotion's types but it works ¯\_(ツ)_/¯
 */
interface AsProp {
  as?: keyof ReactDOM
}

export type BoxProps = AsProp &
  MarginProps &
  PaddingProps &
  SizeProps &
  ColorProps &
  GridChildProps &
  FlexChildProps &
  PositionProps &
  BorderProps &
  VisibilityProps &
  BackgroundProps &
  FlexParentProps &
  GridParentProps &
  AlignmentProps

// Note: workaround for e.g. "Types of property 'color' are incompatible"
// ResponsiveToken (object / array) clashes with the DOM type for these values (string) so we make the DOM 'forget' it takes these props and we handle them
export type WithoutStyleConflicts<
  El extends keyof JSX.IntrinsicElements = 'div'
> = Omit<JSX.IntrinsicElements[El], keyof BoxProps>

export type BoxComponent<
  El extends keyof JSX.IntrinsicElements = 'div',
  P = BoxProps
> = StyledComponent<WithoutStyleConflicts<El>, P, undefined>

const [boxMapper, shouldForwardProp] = createCSSMapper({
  ...margin,
  ...padding,
  ...size,
  ...color,
  ...gridChild,
  ...flexChild,
  ...position,
  ...visibility,
  ...border,
  ...background,
  ...flexParent,
  ...gridParent,
  ...alignment,
})

export const Box = styled('div', { shouldForwardProp })`
  // Default border means we don't have to define borderStyle="solid" every time
  border: 0 solid;
  // We don't want to inherit any default margins for h1, h2 etc.
  margin: 0;
  ${boxMapper};
` as BoxComponent

// This wouldn't need to exist if emotion just had the correct polymorphic types for the "as" prop :(
// For example TS won't (but should!) let you do `Box as="button" type="submit" />`
export const ButtonBox = styled('button')`
  ${boxMapper}
` as BoxComponent<'button'>
