const strictEqual = (a, b) => a === b

const arrayRemoveLastMatch = (
  arr,
  searchEl,
  equalityFunction = strictEqual
) => {
  const matchIndexes = arr.reduce((a, el, index) => {
    if (equalityFunction(el, searchEl)) {
      a.push(index)
    }
    return a
  }, [])

  // Return the last index
  const searchElIndex = matchIndexes.pop()

  if (typeof searchElIndex === 'undefined') {
    return arr
  }

  const arrayStart = arr.slice(0, searchElIndex)
  const arrayEnd = arr.slice(searchElIndex + 1, arr.length)

  return [...arrayStart, ...arrayEnd]
}

export default arrayRemoveLastMatch
