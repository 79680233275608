import React, { VoidFunctionComponent } from 'react'

import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg/Svg'

export const PadlockIcon: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 20 21" fill="none" {...props}>
    <path
      d="M2.6555 19.82C2.57623 17.9976 2.6302 16.1719 2.817 14.3575L3.273 8.41048C7.87401 8.19021 12.4851 8.31723 17.067 8.79048C16.7045 12.532 16.641 16.2964 16.877 20.048L4.527 19.8865"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.1825 8.13496C4.87178 6.36769 5.37828 4.55478 6.56 3.20446C7.72966 1.91069 9.45502 1.2659 11.1865 1.47546C12.5219 1.63911 13.7105 2.40118 14.4165 3.54646C15.243 4.97146 15.11 6.76696 14.9485 8.41996"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.90652 14.4148C8.29897 14.0511 7.99273 13.339 8.14652 12.6478C8.37138 11.8204 9.12258 11.2461 9.98002 11.2461C10.8375 11.2461 11.5887 11.8204 11.8135 12.6478C11.9829 13.5167 11.5298 14.3874 10.721 14.7473L12.013 17.1508L7.91852 17.2838L8.86852 15.6593"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)
