import { useEffect } from 'react'
import Cookies from 'universal-cookie'
import * as logger from '@mindfulchefuk/lib/logger'
import * as rum from '@mindfulchefuk/lib/rum'
import Router from 'next/router'
import { ACCESS_TOKEN_KEY } from '@mindfulchefuk/constants'

interface RouteChangeFrom {
  url: string
  route?: string
  query?: Record<string, string | string[]>
}

export const useDatadogLogging = () => {
  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') return

    const cookies = new Cookies()
    cookies.remove(ACCESS_TOKEN_KEY)

    rum.init()
    logger.init()

    // Track the previous route
    let from: RouteChangeFrom = { url: document.referrer }

    Router.events.on('routeChangeComplete', () => {
      const { token, ...query } = Router.query

      logger.info(Router.pathname, {
        http: {
          method: 'GET',
          status_category: 'OK',
          status_code: 200,
          url: Router.asPath,
          query,
          route: Router.pathname,
          from,
          url_details: { path: Router.asPath },
        },
      })
      from = { url: Router.asPath, route: Router.pathname, query }
    })
  }, [])
}
