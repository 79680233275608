import styled from '@emotion/styled'

import { Box } from './Box'
import { stackParent, StackParentProps } from '../system/config/stack'
import { createCSSMapper } from '../system/utils/createCSSMapper'
import { alignment, AlignmentProps } from '../system'

const [stackMapper, shouldForwardProp] = createCSSMapper({
  ...stackParent,
  ...alignment,
})

export const Stack = styled(Box, { shouldForwardProp })<
  StackParentProps & AlignmentProps
>`
  ${stackMapper};
`

Stack.defaultProps = {
  display: 'flex',
  direction: 'column',
}
