import type { Recipe } from '@mindfulchefuk/features/Recipes/interfaces'

export type RecipeSortId =
  | 'customerFav'
  | 'carbohydrates'
  | 'price'
  | 'calories'
  | 'cookingTime'

export interface RecipeSortOption {
  id: RecipeSortId
  title: string
  getValue: (recipe: Recipe) => boolean | number | string
}

const sortOptionsConfig: RecipeSortOption[] = [
  {
    id: 'customerFav',
    title: 'Customer Favourites',
    getValue: (recipe) => recipe.badges?.includes('promotional'),
  },
  {
    id: 'carbohydrates',
    title: 'Lowest Carbs',
    getValue: (recipe) => recipe.macros.macrosPerPerson.carbohydrates,
  },
  {
    id: 'price',
    title: 'Lowest Price',
    getValue: (recipe) => recipe.price,
  },
  {
    id: 'calories',
    title: 'Lowest Calories',
    getValue: (recipe) => recipe.macros.macrosPerPerson.calories,
  },
  {
    id: 'cookingTime',
    title: 'Shortest Cooking Time',
    getValue: (recipe) => recipe.cookingTime,
  },
]

export default sortOptionsConfig
