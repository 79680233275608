import { CustomersCustomer } from '@mindfulchefuk/api-client'
import { transformCustomer } from '@mindfulchefuk/query/Customer/transforms/transformCustomer'

export const getCustomer = async (customerId: string) => {
  const { data: customer } = await CustomersCustomer.includes([
    'contact_preferences',
    'experiment_groups',
    'default_delivery_address',
  ]).find(customerId)

  return transformCustomer(customer)
}
