import React, { VoidFunctionComponent } from 'react'
import { BoxProps, WithoutStyleConflicts } from '../layout/Box'
import { IconType as SVGIconType, iconSvgs } from './svgs'

export type IconType = SVGIconType

export type SvgBoxProps = BoxProps & WithoutStyleConflicts<'svg'>

export type IconProps = SvgBoxProps & {
  type: IconType
}

export const Icon: VoidFunctionComponent<IconProps> = (props) => {
  const { type, size = 20, ...rest } = props
  const Component: VoidFunctionComponent<SvgBoxProps> = iconSvgs[type]

  if (!Component) return null

  return <Component {...rest} size={size} />
}
