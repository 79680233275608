import { SpaceToken } from '../theme/space'
import { CSSMapperTransform, CSSPropMapper, ResponsiveProps } from '../types'
import { getValueAtBreakpoint } from '../utils/getValueAtBreakpoint'

type StackDirection = 'row' | 'column' | 'column-reverse' | 'row-reverse'
export type StackParentProps = ResponsiveProps<{
  /** Flex direction, column by default */
  direction?: StackDirection
  /** The amount of margin given to give all but the last child, changes direction depending on direction */
  spacing?: SpaceToken
}>

/**
 * Uses the modified lobotomized owl selector that Chakra use in their Stack
 * @see https://alistapart.com/article/axiomatic-css-and-lobotomized-owls/
 * @see https://chakra-ui.com/docs/layout/stack
 */
const selector = '& > :not(style) ~ :not(style)'

const getSpacingForDirection: CSSMapperTransform = ({
  value,
  props,
  breakpoint,
}) => {
  const directionStyles = {
    column: {
      marginTop: value,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
    row: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: value,
    },
    'column-reverse': {
      marginTop: 0,
      marginRight: 0,
      marginBottom: value,
      marginLeft: 0,
    },
    'row-reverse': {
      marginTop: 0,
      marginRight: value,
      marginBottom: 0,
      marginLeft: 0,
    },
  }

  const direction: StackDirection = getValueAtBreakpoint(
    props.direction,
    breakpoint
  ) as StackDirection

  return {
    [selector]: directionStyles[direction],
  }
}
export const stackParent: CSSPropMapper<StackParentProps> = {
  direction: { property: 'flexDirection' },
  spacing: {
    themeKey: 'space',
    transform: getSpacingForDirection,
  },
}
