import {
  QueryClient,
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import { updateDeliveryDate } from '@mindfulchefuk/query/Deliveries/services/updateDeliveryDate'

interface ReschedulableDelivery {
  deliveryId: string
  deliveryDate: string
}

type DeliveriesQueryTuple = [QueryKey, ReschedulableDelivery[]]

export const useMutationRescheduleDeliveryDate = (
  mutateOptions: UseMutationOptions<
    ReschedulableDelivery,
    unknown,
    ReschedulableDelivery
  > = {}
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateDeliveryDate,
    onSuccess: (rescheduledDelivery) =>
      onSuccess(rescheduledDelivery, queryClient),
    ...mutateOptions,
  })
}

export const onSuccess = (
  { deliveryId, deliveryDate }: ReschedulableDelivery,
  queryClient: QueryClient
) => {
  const queryData = queryClient.getQueriesData([
    'deliveries',
  ]) as DeliveriesQueryTuple[]

  if (queryData.length) {
    const populatedDeliveryCache = queryData.find(
      ([, data]) => data !== undefined
    )
    if (!populatedDeliveryCache) return
    const [cacheKeys, deliveries] = populatedDeliveryCache

    if (deliveries.length) {
      queryClient.setQueryData(cacheKeys, [
        ...deliveries.map((previousDelivery) =>
          previousDelivery.deliveryId === deliveryId
            ? { ...previousDelivery, deliveryDate }
            : previousDelivery
        ),
      ])
    }
  }
}
