import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import jwtDecode from 'jwt-decode'
import { isBefore } from 'date-fns'
import { ApplicationRecord } from '@mindfulchefuk/api-client'
import { AUTH_TOKEN } from '@mindfulchefuk/constants'
import { isObjTrue } from '@mindfulchefuk/utils/isObjTrue'
import spraypaintError from '@mindfulchefuk/utils/spraypaintError'
import { initialiseAmplitude } from '@mindfulchefuk/utils/analytics/amplitude/amplitudeAdapter'
import { useReferrer } from '@mindfulchefuk/hooks/useReferrer'
import { useOptimizelyWithAmplitude } from '@mindfulchefuk/utils/analytics/optimizely/hooks/useOptimizelyWithAmplitude'

const GlobalContainer = ({ children }) => {
  const amplitudeApiKey = process.env.NEXT_PUBLIC_AMPLITUDE_KEY
  const amplitudeEnabled = isObjTrue(process.env.NEXT_PUBLIC_AMPLITUDE)

  // Sets up and tracks referrer paths
  useReferrer()
  useOptimizelyWithAmplitude()

  if (typeof window !== 'undefined') {
    // JWT Access Token
    const token = window.localStorage.getItem(AUTH_TOKEN)
      ? jwtDecode(window.localStorage.getItem(AUTH_TOKEN))
      : null
    // Convert unix timestamp to milliseconds
    const expiredDate = token ? new Date(token.exp * 1000) : null
    const todaysDate = new Date()
    const isAuthTokenExpired = isBefore(expiredDate, todaysDate)

    // Api-client will look for global jwt with key of 'auth_token'
    ApplicationRecord.jwtStorage = AUTH_TOKEN

    // Remove authToken if it is expired
    if (isAuthTokenExpired) {
      window.localStorage.removeItem(AUTH_TOKEN)
    }

    // Initialise Amplitude
    if (isAuthTokenExpired) {
      initialiseAmplitude(null, amplitudeApiKey, amplitudeEnabled)
    } else {
      const customerId = token?.customer_id || null
      initialiseAmplitude(customerId, amplitudeApiKey, amplitudeEnabled)
    }

    // Remove deprecated token
    window.localStorage.removeItem('jwt')
  }

  useEffect(() => {
    if (typeof document !== 'undefined') {
      document.addEventListener('spraypaint.error', spraypaintError)
    }

    return () => {
      if (typeof document !== 'undefined') {
        document.removeEventListener('spraypaint.error', spraypaintError)
      }
    }
  }, [])

  return <>{children}</>
}

GlobalContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default GlobalContainer
