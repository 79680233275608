const sortBy = (
  a: number | string | boolean,
  b: number | string | boolean,
  { asc = true } = {}
): 0 | 1 | -1 => {
  if (a < b) {
    return asc ? -1 : 1
  }

  if (a > b) {
    return asc ? 1 : -1
  }

  return 0
}

export default sortBy
