import React, { VoidFunctionComponent } from 'react'
import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg'

export const PersonIcon: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 20 20" {...props}>
    <g fill="none">
      <path
        d="M5.16994 16.6902C5.16519 14.6058 6.0578 12.6202 7.61994 11.2402"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.45 8.27005C12.9088 7.36065 12.9597 6.29917 12.59 5.35005C12.196 4.40222 11.3117 3.74864 10.29 3.65005C9.39947 3.64114 8.5546 4.04329 7.99998 4.74005C7.40126 5.42559 7.08027 6.31008 7.09998 7.22005C7.10966 8.62663 8.22438 9.7766 9.62998 9.83005C11.63 10.21 15.43 10.78 15.32 16.76"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </Svg>
)
