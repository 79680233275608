import React, { FunctionComponent } from 'react'
import { InputProps } from '@mindfulchefuk/design-system/Forms/types'
import { Box } from '@mindfulchefuk/design-system/layout/Box'
import { CheckboxLabel } from './Checkbox.styles'
import { CheckboxControl } from './CheckboxControl'

export const Checkbox: FunctionComponent<InputProps> = ({
  children,
  ...inputProps
}) => (
  <CheckboxLabel isDisabled={inputProps.disabled}>
    <CheckboxControl {...inputProps} />
    <Box as="span" mt={4}>
      {children}
    </Box>
  </CheckboxLabel>
)
