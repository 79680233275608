import React, { ReactElement } from 'react'
import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg'

export const SparkleIcon = (props: BoxProps): ReactElement => (
  <Svg viewBox="0 0 21 21" {...props}>
    <path
      d="M14.3601 8.31004C14.0186 8.31004 13.8964 7.99126 13.7805 7.67248C13.4671 6.85641 12.781 4.94692 10.5344 4.94692C10.441 4.94685 10.3499 4.9181 10.2728 4.86445C10.1958 4.8108 10.1365 4.73473 10.1026 4.64621C10.0688 4.55769 10.0619 4.46083 10.0831 4.36831C10.1042 4.27579 10.1523 4.19191 10.2211 4.12766C10.5243 3.90187 10.865 3.73343 11.2269 3.63036C12.3266 3.22551 13.6959 2.72184 13.7993 0.442568C13.8052 0.333901 13.8481 0.230728 13.9207 0.150757C13.9933 0.0707852 14.0909 0.019003 14.1969 0.00429794C14.3029 -0.0104071 14.4106 0.012882 14.5016 0.0701681C14.5925 0.127454 14.661 0.215164 14.6954 0.318245C15.0087 1.36065 16.1398 4.05434 17.7315 3.97464H17.813H17.9822C18.1027 3.97959 18.2167 4.0318 18.3002 4.12035C18.3837 4.20889 18.4303 4.3269 18.4302 4.44962C18.4302 4.79072 18.1796 4.88316 17.9634 4.90867H17.8882C17.3625 4.9611 16.8501 5.10788 16.3748 5.34221C15.953 5.5317 15.5926 5.83887 15.3353 6.22814C15.078 6.61742 14.9344 7.07288 14.921 7.54178C14.921 7.54178 14.921 7.56729 14.921 7.58004C14.8865 7.82869 14.8332 8.24947 14.4322 8.2941L14.3601 8.31004ZM12.0885 4.2743C13.0265 4.68254 13.787 5.42337 14.2285 6.35911C14.5633 5.52185 15.2048 4.84974 16.0176 4.48469L16.1649 4.41456C15.4002 3.79497 14.794 2.99664 14.3977 2.08747C14.1864 2.59719 13.8713 3.0556 13.4733 3.43252C13.0753 3.80944 12.6033 4.09632 12.0885 4.2743Z"
      fill="currentColor"
    />
    <path
      d="M5.67764 18.345C5.5765 18.3457 5.47798 18.3123 5.39748 18.25C5.31698 18.1877 5.25903 18.1 5.23271 18.0007C5.16065 17.7265 5.10738 17.4141 5.05098 17.0858C4.73766 15.3134 4.37107 13.1074 0.470161 12.9257C0.348836 12.9291 0.23116 12.8833 0.14302 12.7984C0.0548796 12.7135 0.00349582 12.5965 0.000171855 12.473C-0.00315211 12.3496 0.0418561 12.2299 0.125295 12.1402C0.208734 12.0505 0.32377 11.9983 0.445095 11.9949C1.30126 11.8701 2.12695 11.5836 2.87964 11.1501C4.42747 10.2352 5.20765 8.81665 5.19825 6.93904C5.19138 6.82651 5.19138 6.71366 5.19825 6.60113C5.20147 6.47932 5.2509 6.36351 5.33617 6.27794C5.42144 6.19237 5.53595 6.14369 5.6557 6.14209C5.96903 6.14209 6.07869 6.36842 6.11003 6.62345C6.11324 6.67333 6.11324 6.72338 6.11003 6.77327C6.18209 7.57022 6.71161 11.2649 10.1833 11.453H10.2177C10.5937 11.504 11.023 11.5613 11.07 11.9917C11.117 12.422 10.7566 12.5464 10.2773 12.7313C9.02396 13.2031 6.13196 14.306 6.14136 17.8764C6.14231 17.99 6.10237 18.1 6.02907 18.1858C5.95577 18.2715 5.85417 18.327 5.74343 18.3418L5.67764 18.345ZM2.58511 12.3328C3.32428 12.5398 3.99674 12.9415 4.53472 13.4972C5.0727 14.053 5.45719 14.7432 5.64944 15.4983C6.38575 13.678 7.92418 12.7313 9.05842 12.218C7.29126 11.7462 6.29802 10.3978 5.76223 9.12268C5.47613 9.85008 5.04663 10.5102 4.50021 11.0623C3.9538 11.6144 3.30204 12.0467 2.58511 12.3328Z"
      fill="currentColor"
    />
    <path
      d="M17.3744 20.0001C17.061 20.0001 16.9295 19.6813 16.8574 19.4901C16.5817 18.7441 16.1555 17.9791 15.084 17.9791C14.9898 17.9817 14.8971 17.9547 14.8185 17.9018C14.74 17.8488 14.6794 17.7725 14.6451 17.6832C14.6108 17.5939 14.6045 17.496 14.627 17.4029C14.6494 17.3098 14.6996 17.226 14.7706 17.163C14.9682 17.0091 15.1925 16.8945 15.4318 16.8251C16.0584 16.5924 16.7979 16.3246 16.8511 15.0877C16.8557 14.9767 16.8989 14.8709 16.973 14.7893C17.0471 14.7077 17.1473 14.6555 17.2557 14.6421C17.3642 14.6287 17.4738 14.6549 17.5649 14.7161C17.6561 14.7773 17.7229 14.8695 17.7535 14.9762C17.9133 15.5245 18.5587 17.0705 19.3859 17.0355H19.4361C19.4747 17.033 19.5134 17.033 19.552 17.0355C19.6728 17.0396 19.7872 17.0916 19.8709 17.1803C19.9546 17.269 20.0009 17.3875 20 17.5105C20 17.6667 19.9342 17.9185 19.5426 17.9695C19.5426 17.9695 17.976 18.0364 17.8945 19.3721C17.896 19.3848 17.896 19.3977 17.8945 19.4104C17.8757 19.5602 17.8224 19.9555 17.4402 20.0001H17.3744ZM16.4344 17.4053C16.7748 17.6194 17.063 17.9093 17.2772 18.2532C17.4808 17.9158 17.7669 17.638 18.1076 17.4467C17.8224 17.1673 17.5814 16.8447 17.3932 16.4904C17.1645 16.8788 16.8349 17.1954 16.4407 17.4053H16.4344Z"
      fill="currentColor"
    />
  </Svg>
)
