module.exports.getVersion = function getVersion() {
  const isNode = typeof window === 'undefined'
  if (isNode) {
    // GIT_SHA is available at runtime & SOURCE_VERSION at build time
    // We need the latter for static pages.
    return process.env.GIT_SHA || process.env.SOURCE_VERSION
  }

  return window.mc.build.version
}
