import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { getLoginCredentials } from '@mindfulchefuk/query/LoginCredentials/services'
import { STALE_1_HOUR } from '@mindfulchefuk/constants'

export const useQueryLoginCredentials = (
  customerId: string,
  options?: UseQueryOptions<string>
) => {
  return useQuery({
    queryKey: ['login-credentials', { customerId }],
    queryFn: () => getLoginCredentials(customerId),
    staleTime: STALE_1_HOUR,
    ...options,
  })
}
