/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  RecipeCartItem,
  RecipeCartState,
} from '@mindfulchefuk/features/Cart/interfaces'
import { Recipe } from '@mindfulchefuk/features/Recipes/interfaces'

export const initialState = {
  // Initial cart recipes, used to track changes made to the cart
  initialRecipes: [],
  // Initial cart recipes, used to track changes made to the cart

  allPortionSelectedRecipes: [],

  // Used to track the last added recipe id
  lastAddedRecipeId: '',
} as RecipeCartState

const recipeCartSlice = createSlice({
  name: 'recipeCart',
  initialState,
  reducers: {
    updateRecipeCart: (state, action) => {
      const {
        payload: { recipeIds, portionCount },
      } = action

      const initialRecipes = recipeIds.reduce(
        (recipesArray: RecipeCartItem[], id: string) => {
          let updateRecipe = false

          const recipes = recipesArray.map((recipe) => {
            if (recipe.id === id) {
              updateRecipe = true
              return { ...recipe, quantity: recipe.quantity + 1 }
            }

            return recipe
          })

          if (updateRecipe) {
            return recipes
          }

          return [...recipesArray, { id, quantity: 1, portions: portionCount }]
        },
        []
      )

      state.allPortionSelectedRecipes = initialRecipes
      state.initialRecipes = initialRecipes
    },
    addRecipe: (state, action: PayloadAction<Recipe>) => {
      let recipePortionExists = false

      const updatedPortionRecipes = state.allPortionSelectedRecipes.map(
        (recipe) => {
          if (
            recipe.id === action.payload.id &&
            recipe.portions === action.payload.portions
          ) {
            recipePortionExists = true
            return { ...recipe, quantity: recipe.quantity + 1 }
          }

          return recipe
        }
      )

      if (!recipePortionExists) {
        updatedPortionRecipes.push({
          id: action.payload.id,
          portions: action.payload.portions,
          quantity: 1,
        })
      }

      state.allPortionSelectedRecipes = updatedPortionRecipes
      state.lastAddedRecipeId = action.payload.id
    },
    incrementRecipe: (state, action: PayloadAction<Recipe>) => {
      const updatedPortionRecipes = state.allPortionSelectedRecipes.map(
        (recipe) => {
          if (
            recipe.id === action.payload.id &&
            recipe.portions === action.payload.portions
          ) {
            return { ...recipe, quantity: recipe.quantity + 1 }
          }

          return recipe
        }
      )

      state.allPortionSelectedRecipes = updatedPortionRecipes
      state.lastAddedRecipeId = action.payload.id
    },
    decrementRecipe: (state, action: PayloadAction<Recipe>) => {
      const updatedPortionRecipes = state.allPortionSelectedRecipes
        .map((recipe) => {
          if (
            recipe.id === action.payload.id &&
            recipe.portions === action.payload.portions
          ) {
            return { ...recipe, quantity: recipe.quantity - 1 }
          }

          return recipe
        })
        .filter(({ quantity }) => quantity !== 0)

      state.allPortionSelectedRecipes = updatedPortionRecipes
      state.lastAddedRecipeId = ''
    },
    resetRecipeCart: (state) => {
      state.allPortionSelectedRecipes = []
      state.lastAddedRecipeId = ''
      state.initialRecipes = []
    },
  },
})

const { actions, reducer } = recipeCartSlice

export const {
  updateRecipeCart,
  addRecipe,
  incrementRecipe,
  decrementRecipe,
  resetRecipeCart,
} = actions

export default reducer
