import React, { VoidFunctionComponent } from 'react'
import Illustration from '@mindfulchefuk/design-system/Illustrations/Illustrations'
import { Box } from '@mindfulchefuk/design-system/layout/Box'

// Layout for my-account/calendar page
export const SectionBackground16: VoidFunctionComponent = () => (
  <Box display={{ base: 'none', sm: 'block' }}>
    <Illustration
      type="cross-section-runner-bean-2"
      left={{
        base: '-10vw',
        md: '-15vw',
        lg: '-30%',
      }}
      transform="rotate(180deg) scaleX(-1) scale(0.75)"
    />
    <Illustration
      type="cross-section-turnip"
      bottom={0}
      left={{
        base: '-17vw',
        md: '-10vw',
        lg: '-20%',
      }}
      transform="scale(0.7)"
    />
    <Illustration
      type="cross-section-squash-one"
      top={0}
      right={{
        base: '-17vw',
        md: '-12vw',
        lg: '-20%',
      }}
      transform="scale(0.65)"
    />
    <Illustration
      type="cross-section-kale"
      top={256}
      right={{
        base: '-20vw',
        md: '-16vw',
        lg: '-40%',
      }}
      transform="scale(0.76)"
    />
  </Box>
)
