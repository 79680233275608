import { DeliveriesDelivery } from '@mindfulchefuk/api-client'
import errorHandler from '@mindfulchefuk/utils/errorHandler'
import { ApiError } from '@mindfulchefuk/utils/spraypaintUtils'

interface RescheduleDeliveryDateParams {
  deliveryId: string
  deliveryDate: string
}

export const updateDeliveryDate = async ({
  deliveryDate,
  deliveryId,
}: RescheduleDeliveryDateParams) => {
  try {
    const delivery = new DeliveriesDelivery({ id: deliveryId })
    delivery.isPersisted = true
    delivery.delivery_date = deliveryDate

    const result = await delivery.save()

    if (!result) {
      throw new ApiError(delivery)
    }

    return { deliveryId, deliveryDate }
  } catch (error) {
    errorHandler(error)
  }
}
