import configureStore from '@mindfulchefuk/configure-store'
import { useMemo } from 'react'
import { persistStore } from 'redux-persist'

export const useReduxStore = () =>
  useMemo(() => {
    const store = configureStore()
    const persistor = persistStore(store)

    return { store, persistor }
  }, [])
