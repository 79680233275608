export const cookieConfig = {
  autoClear: {
    analytics: [
      { name: /^_fbp/ }, // Facebook Pixel
      { name: /^amp_/ }, // Amplitude
      { name: /^(_ga|_gid|_gat)/ }, // Google Analytics
    ],
    marketing: [
      { name: /^_gcl_au/ }, // Google Adsense
      { name: /^__adal_/ }, // Adalyser
      { name: /^_omapp/ }, // OptinMonster
      { name: /^om(Seen|-success)?-/ }, // OptinMonster
      { name: /^_pin/ }, // Pinterest
      { name: /^_tq_id.TV/ }, // TV Squared
      { name: /^(_ttp|_tt_enable_cookie)/ }, // TikTok
    ],
    diagnostic: [
      { name: /^_dd_/ }, // DataDog
    ],
  },
}
