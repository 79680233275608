import { Responsive, ResponsiveArray, ResponsiveObject } from '../types'

export const isResponsiveProp = (
  raw: Responsive<string | number>
): raw is
  | ResponsiveObject<string | number>
  | ResponsiveArray<string | number> =>
  isResponsiveArray(raw) || isResponsiveObject(raw)

export const isResponsiveArray = (
  raw: Responsive<string | number>
): raw is ResponsiveArray<string | number> => Array.isArray(raw)

export const isResponsiveObject = (
  raw: Responsive<string | number>
): raw is ResponsiveObject<string | number> =>
  typeof raw === 'object' && raw !== null
