import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { getCompletedOrderByDate } from '@mindfulchefuk/query/ShopOrders/services'
import { ShopOrder } from '@mindfulchefuk/query/ShopOrders/interfaces'

interface Props {
  customerId: string
  deliveryDate: string
}

export const useQueryCompletedOrderByDate = <TData = ShopOrder>(
  { customerId, deliveryDate }: Props,
  options?: UseQueryOptions<ShopOrder, unknown, TData>
) =>
  useQuery({
    queryKey: ['shop-orders', 'completed', { customerId, deliveryDate }],
    queryFn: () => getCompletedOrderByDate({ customerId, deliveryDate }),
    enabled: !!customerId && !!deliveryDate,
    ...options,
  })
