import { css } from '@emotion/react'

import { createMediaQuery, theme } from '@mindfulchefuk/design-system/system'

export const calendarStyles = css`
  width: 100%;
  /*
   * Navigation
   */
  .react-calendar__navigation {
    align-items: center;
    display: flex;
    margin: ${theme.space[12]} ${theme.space[12]} ${theme.space[4]};

    &__label {
      ${theme.typography['body-lg-500-caps']}
      background: none;
      border: 0 none;
      padding: 0;
      color: ${theme.colors.aubergine};
      text-transform: uppercase;
    }

    &__arrow {
      background: none;
      border: 0 none;
      display: flex;
      padding: 0;

      &:enabled:hover {
        cursor: pointer;
      }
    }

    &__next-button,
    &__prev-button {
      color: ${theme.colors.aubergine};
      &:disabled {
        opacity: 0;
      }
    }
  }

  /**
   * Titles ex Mon, Tues, Wed...
   */
  .react-calendar__month-view {
    padding: ${theme.space[8]};
    &__weekdays {
      text-align: center;
      padding: 0 0 ${theme.space[12]};

      &__weekday abbr {
        ${theme.typography['body-lg-500']};
        color: ${theme.colors.aubergine};
        cursor: default;
        text-decoration: none;
      }
    }
  }

  /**
   * Calendar 'tile' buttons
   */
  .react-calendar__tile {
    abbr {
      ${theme.typography['body-md']}
      align-items: center;
      display: flex;
      justify-content: center;
      border-radius: 50%;

      height: 36px;
      width: 36px;

      // Custom media query because tiles are too large and react-calendar created DOM is too hard to add padding to
      ${createMediaQuery(360)} {
        height: 44px;
        width: 44px;
      }
    }

    background: none;
    border: 0;
    color: ${theme.colors.aubergine};
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    text-align: center;

    &--now abbr {
      padding-bottom: ${theme.space[2]};
      position: relative;
      &::after {
        background: currentColor;
        bottom: 20%;
        border-radius: ${theme.space[4]};
        content: '';
        height: ${theme.space[2]};
        position: absolute;
        left: ${theme.space[12]};
        right: ${theme.space[12]};
      }
    }

    &:hover abbr {
      background-color: ${theme.colors['pepper-50']};
    }

    &:disabled abbr {
      background: none;
      color: ${theme.colors['aubergine-100']};
      cursor: not-allowed;
    }
  }
`
