import {
  ColorToken,
  HexToken,
} from '@mindfulchefuk/design-system/system/theme/colors'
import {
  CSSPropMapper,
  ResponsiveProps,
} from '@mindfulchefuk/design-system/system/types'

export type ColorProps = ResponsiveProps<{
  color?: ColorToken | HexToken
}>

export const color: CSSPropMapper<ColorProps> = {
  color: { property: 'color', themeKey: 'colors' },
}
