import { dataAttr } from '@mindfulchefuk/utils/dataAttr'
import React, { FunctionComponent } from 'react'
import { InputProps } from '@mindfulchefuk/design-system/Forms/types'
import { HiddenInput } from '@mindfulchefuk/design-system/Forms/form.styles'
import { SelectionBox } from '@mindfulchefuk/design-system/Forms/SelectionBox'

export const RadioBox: FunctionComponent<InputProps> = ({
  children,
  'data-testid': testId,
  checked,
  ...input
}) => {
  const dataChecked = dataAttr(checked)

  return (
    <SelectionBox data-checked={dataChecked} data-testid={testId}>
      <HiddenInput {...input} type="radio" checked={checked} />

      {children}
    </SelectionBox>
  )
}
