import styled from '@emotion/styled'
import {
  alignment,
  AlignmentProps,
  gap,
  GapProps,
  gridParent,
  GridParentProps,
} from '@mindfulchefuk/design-system/system'
import { Box } from './Box'

import { createCSSMapper } from '../system/utils/createCSSMapper'

export type GridProps = GapProps & AlignmentProps & GridParentProps

const [gridMapper, shouldForwardProp] = createCSSMapper({
  ...alignment,
  ...gap,
  ...gridParent,
})

export const Grid = styled(Box, { shouldForwardProp })<GridProps>`
  ${gridMapper};
`

Grid.defaultProps = {
  display: 'grid',
}
