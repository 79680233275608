import React, { VoidFunctionComponent } from 'react'
import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg'

export const StarIconRating: VoidFunctionComponent<
  BoxProps & { rating: number }
> = ({ rating, ...props }) => (
  <Svg viewBox="0 0 48 48" data-testid="customer-favourite-icon" {...props}>
    <path
      fill="#9a8e5f"
      d="M21.087 48C40.97 48 48 37.272 48 24.046 48 10.821 40.231 0 22.751 0 9.526 0 0 7.214 0 20.44 0 33.664 7.861 48 21.087 48Z"
    />
    <path
      fill="#ffffff"
      d="M42.543 18.127c-.092-.277-.37-.462-.647-.555-1.942-.37-5.734-.37-8.416-.37h-2.59c-.37-.555-.832-1.85-1.295-3.144-1.11-2.96-2.59-7.03-3.791-9.064-.093-.185-.37-.37-.74-.37-.278 0-.555.185-.74.463L18.22 17.295l-11.931.74c-.37 0-.647.277-.74.555-.092.37 0 .647.185.925 1.295 1.294 3.052 2.681 4.717 4.069 1.48 1.202 2.96 2.404 4.254 3.607-.462 2.22-.832 4.347-1.295 6.566-.462 2.035-.832 4.162-1.387 6.474-.092.278.093.648.278.833.184.092.37.185.554.185.093 0 .278 0 .37-.093 2.498-1.11 4.902-2.867 7.4-4.624 1.387-1.017 2.681-1.942 3.976-2.775a73.168 73.168 0 0 1 4.255 2.96c2.127 1.48 4.254 2.96 6.381 4.254a.84.84 0 0 0 .925 0 .88.88 0 0 0 .37-.925 153.65 153.65 0 0 0-1.757-7.121c-.555-2.312-1.11-4.624-1.48-6.012.74-.74 2.035-1.85 3.422-2.96 2.127-1.849 4.44-3.699 5.549-4.994.277-.184.37-.462.277-.832Z"
    />
    {rating === 1 && (
      <path
        fill="#9a8e5f"
        d="M22.104 30.983c-.277 0-.462-.185-.462-.463v-.277c0-.278.092-.463.37-.463l1.11-.185c.37-.092.462-.277.462-.647v-7.306c0-.278-.185-.37-.463-.278l-1.11.463c-.277.092-.462.092-.647-.185l-.185-.37c-.185-.278-.092-.463.185-.648l2.682-1.665c.185-.092.37-.184.463-.184h.74c.277 0 .462.184.462.462v9.711c0 .37.093.555.462.647l.925.185c.278.093.37.185.37.463v.277c0 .278-.185.463-.462.463h-4.902Z"
      />
    )}
    {rating === 2 && (
      <path
        fill="#9a8e5f"
        d="M27.653 31.353c-.185 0-.277-.093-.462-.185a1.433 1.433 0 0 0-.74-.185h-5.734c-.278 0-.463-.185-.463-.463v-.462a.84.84 0 0 1 .278-.648c4.07-3.422 5.641-5.549 5.641-7.49 0-1.203-.647-1.943-1.757-1.943-1.017 0-1.85.74-3.052 2.682-.185.277-.277.277-.555.185l-.277-.093c-.278-.092-.37-.277-.278-.555l.833-2.59c.092-.277.277-.369.462-.462.832-.277 2.127-.554 3.052-.554 2.405 0 3.792 1.202 3.792 3.33 0 2.496-2.312 4.53-5.55 7.12-.184.093-.092.278.093.278h1.665c1.665 0 2.312-.463 3.237-1.85.185-.277.278-.277.555-.185l.277.093c.278.092.278.185.278.555l-.74 3.144c-.092.185-.185.37-.462.37h-.093v-.092Z"
      />
    )}
    {rating === 3 && (
      <path
        fill="#9a8e5f"
        d="M20.07 29.04c-.186-.37-.093-.647.184-.832.278-.185.555-.092.833.185.647.648 1.387 1.11 2.682 1.11 1.572 0 2.404-.925 2.404-2.405 0-1.294-.74-2.22-2.034-2.59-.185-.092-.278 0-.37.093l-.555.463c-.185.185-.37.185-.555-.093l-.277-.277a.447.447 0 0 1 0-.648l3.421-3.514c.093-.093.093-.278-.092-.278h-1.85c-1.294 0-1.665.278-2.59 1.573-.184.277-.37.277-.647.185l-.185-.093c-.185-.092-.37-.277-.185-.555l1.018-2.774c.092-.185.185-.37.462-.37h.185c.185 0 .277.092.462.185.186.092.37.185.74.185h4.902c.278 0 .462.185.462.462v.555c0 .185-.092.37-.277.555l-2.774 2.867c-.093.092-.093.185.092.277 2.035.463 3.052 1.85 3.052 3.792 0 2.405-1.665 3.885-4.44 3.885-2.311.185-3.606-.925-4.069-1.942Z"
      />
    )}
    {rating === 4 && (
      <path
        fill="#9a8e5f"
        d="M23.084 30.55c-.259 0-.435-.157-.435-.434v-.296c0-.26.12-.389.37-.472l.454-.176c.351-.12.472-.296.472-.666v-1.314a.204.204 0 0 0-.213-.213h-4.774c-.278 0-.435-.139-.435-.407v-.453c0-.195.037-.333.157-.49l5.33-7.07c.156-.212.295-.277.545-.277h2.387c.26 0 .426.157.426.435v.296c0 .26-.12.389-.352.472l-.425.176c-.352.12-.472.277-.472.666v5.098c0 .12.083.213.212.213h1.37c.277 0 .435.175.435.434v.472c0 .26-.158.426-.435.426h-1.37a.204.204 0 0 0-.212.213v1.314c0 .37.12.545.472.666l.453.175c.24.084.37.213.37.472v.296c0 .278-.176.435-.435.435h-3.895v.01Zm.648-4.912c.12 0 .213-.084.213-.213v-4.099c0-.194-.14-.231-.26-.074l-3.071 4.117c-.102.14-.019.26.139.26h2.979v.009Z"
      />
    )}
    {rating === 5 && (
      <path
        fill="#9a8e5f"
        d="M20.207 29.126c-.176-.389-.084-.704.212-.898.278-.157.546-.12.805.157.611.63 1.509 1.157 2.683 1.157 1.61 0 2.545-.962 2.545-2.526 0-1.563-1.055-2.59-2.702-2.59-.472 0-.962.055-1.526.277-.14.056-.195.12-.232.26l-.102.35c-.073.278-.259.37-.508.315l-.546-.101c-.26-.056-.407-.213-.352-.49l1.175-5.931c.056-.278.213-.408.472-.408h4.173c.425 0 .61-.073.786-.194.176-.12.315-.176.49-.176h.195c.231 0 .351.12.314.37l-.212 1.453c-.037.26-.195.407-.472.407h-4.293c-.12 0-.194.056-.231.195l-.472 2.276c-.037.175.083.25.277.194a6.631 6.631 0 0 1 1.767-.232c2.702 0 4.275 1.647 4.275 3.96 0 2.665-1.758 4.21-4.404 4.21-2.193 0-3.627-.962-4.136-2.054l.019.019Z"
      />
    )}
  </Svg>
)
