import { Property } from 'csstype'
import { ColorToken, SpaceToken } from '../theme'
import { CSSPropMapper, ResponsiveProps } from '../types'

export type BorderProps = ResponsiveProps<{
  border?: Property.Border
  borderColor?: ColorToken
  borderStyle?: Property.BorderStyle
  borderWidth?: SpaceToken
  borderTopWidth?: SpaceToken
  borderBottomWidth?: SpaceToken
  borderRightWidth?: SpaceToken
  borderLeftWidth?: SpaceToken
  borderRadius?: SpaceToken
  borderBottom?: Property.BorderBottom
}>

export const border: CSSPropMapper<BorderProps> = {
  border: { property: 'border' },
  borderStyle: { property: 'borderStyle' },
  borderWidth: { property: 'borderWidth', themeKey: 'space' },
  borderTopWidth: { property: 'borderTopWidth', themeKey: 'space' },
  borderBottomWidth: { property: 'borderBottomWidth', themeKey: 'space' },
  borderRightWidth: { property: 'borderRightWidth', themeKey: 'space' },
  borderLeftWidth: { property: 'borderLeftWidth', themeKey: 'space' },
  borderRadius: { property: 'borderRadius', themeKey: 'space' },
  borderColor: { property: 'borderColor', themeKey: 'colors' },
  borderBottom: { property: 'borderBottom', themeKey: 'space' },
}
