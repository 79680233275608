import { AuthenticationLoginCredential } from '@mindfulchefuk/api-client'

export const getLoginCredentials = async (
  customerId: string
): Promise<string> => {
  const { data } = await AuthenticationLoginCredential.where({
    customer_id: customerId,
  }).first()

  return data.id
}
