import React, { VoidFunctionComponent } from 'react'
import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg'

export const ShellfishIcon: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 20 20" {...props}>
    <g
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M4.87,2.88A11.66,11.66,0,0,1,11.59,2a10.85,10.85,0,0,0,4,.44c.76-.15,1.46-.55,2.23-.67a1.75,1.75,0,0,1,2,.88,1.51,1.51,0,0,1-.56,1.61,3.58,3.58,0,0,1-1.69.63c-2.77.48-5.62,0-8.43.07s-5.88.9-7.48,3.22A5.31,5.31,0,0,0,1,13a4.51,4.51,0,0,0,3.84,2.85,1.18,1.18,0,0,0-.41,1.62,2.49,2.49,0,0,0,1.53,1,5.71,5.71,0,0,0,3.56-.16A5.48,5.48,0,0,0,15,19c.51-.24,1-.73.9-1.27s-.76-.68-1.28-.74a17.44,17.44,0,0,0-5.49.24,1.73,1.73,0,0,0-.45-1.66,1.75,1.75,0,0,0-1.66-.42,2.26,2.26,0,0,0-.48-2.81,2.33,2.33,0,0,0,2-1.87,15.16,15.16,0,0,0,6.83-.24A5.4,5.4,0,0,0,17.8,9a2.41,2.41,0,0,0,.71-2.49" />
      <path d="M8.34,5.41a4.44,4.44,0,0,0,.54,4.94" />
      <path d="M1.26,9.43a12.26,12.26,0,0,0,5.93,2.85" />
      <path d="M4.31,16a7.26,7.26,0,0,0,2.92-1.07" />
      <path d="M9,18.29l.2-1" />
      <path d="M7.88,12.29a3.53,3.53,0,0,1,2,1.89" />
      <path d="M9.06,11.24a3.53,3.53,0,0,1,2,1.88" />
      <path d="M11.37,10.83a4.12,4.12,0,0,1,1.21,1.62" />
    </g>
  </Svg>
)
