import { ShopOrder } from '@mindfulchefuk/api-client'
import { ISODate } from '@mindfulchefuk/lib/toISODateFormat'
import errorHandler from '@mindfulchefuk/utils/errorHandler'

import {
  ShopOrder as ShopOrderType,
  ShopOrderStatus,
} from '@mindfulchefuk/query/ShopOrders/interfaces'
import { transformShopOrder } from '@mindfulchefuk/query/ShopOrders/transforms'
import { LINE_ITEMS } from '@mindfulchefuk/features/ShopOrder/constants'

interface ShopOrderByDateParams {
  customerId: string | number
  deliveryDate: ISODate
}

export const getCompletedOrderByDate = async ({
  customerId,
  deliveryDate,
}: ShopOrderByDateParams): Promise<ShopOrderType> => {
  try {
    const { data } = await ShopOrder.includes(LINE_ITEMS)
      .where({
        customer_id: { eq: customerId },
        delivery_date: { eq: deliveryDate },
        status: { eq: ShopOrderStatus.COMPLETED },
      })
      .extraParams({ pagination_links: false })
      .first()

    return data ? transformShopOrder(data) : null
  } catch (error) {
    errorHandler(error)
    throw error
  }
}
