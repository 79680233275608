const increment = 10
type ZIndex =
  | 'loaderOverlay'
  | 'notification'
  | 'header'
  | 'nav'
  | 'banner'
  | 'drawer'
  | 'modal'

// use this for anything below notifications to solve overlapping issues
type ZIndexNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 'auto'

const order = [
  'loaderOverlay',
  'notification',
  'header',
  'nav',
  'banner',
  'drawer',
  'modal',
] as ZIndex[]

export const zIndices = order.reduce((indices, zIndex, position) => {
  return {
    ...indices,
    [zIndex]: increment * (position + 1),
  }
}, {}) as ZIndices

export type ZIndices = Record<ZIndex, number>
export type ZIndexToken = ZIndex | ZIndexNumber
