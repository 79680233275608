import styled from '@emotion/styled'
import { Box, BoxProps, BoxComponent } from '../layout/Box'

export type SvgProps = BoxProps & {
  viewBox: string
}

export const Svg = styled(Box)`` as unknown as BoxComponent<'svg', SvgProps>

Svg.defaultProps = {
  as: 'svg',
  preserveAspectRatio: 'xMidYMid meet',
  shapeRendering: 'geometricPrecision',
  xmlns: 'http://www.w3.org/2000/svg',
}
