import React, { ReactElement } from 'react'
import styled from '@emotion/styled'
import { Icon } from '../Icon'
import { theme } from '../system'

type CarouselButtonProps = {
  direction: 'previous' | 'next'
  onClick: () => void
  visible: boolean
}

const SlideButton = styled.button<{
  direction: CarouselButtonProps['direction']
  visible: boolean
}>`
  background-color: rgba(135, 135, 135, 0.5);
  padding: 10px;
  position: absolute;
  right: ${({ direction }) => direction === 'next' && '8px'};
  left: ${({ direction }) => direction === 'previous' && '8px'};
  border-radius: ${theme.space[20]};
  z-index: 10;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
`

export const CarouselButton = ({
  direction,
  onClick,
  visible,
  ...props
}: CarouselButtonProps): ReactElement => {
  return (
    <SlideButton
      direction={direction}
      onClick={onClick}
      visible={visible}
      {...props}
    >
      <Icon
        type={direction === 'next' ? 'chevron-right' : 'chevron-left'}
        color="white"
      />
    </SlideButton>
  )
}
