import { theme } from '../theme'
import { CSSPropMapperConfig } from '../types'

/** Convert something to a theme value,
 * e.g. colors themeKey - aubergine => #392d6e
 * e.g. space themeKey  - 8 => 8px
 * */
export const getThemeToken = (
  value: string | number,
  config: CSSPropMapperConfig
): string | number => {
  if (!config.themeKey) return value

  const themeKeyArray = Array.isArray(config.themeKey)
    ? config.themeKey
    : [config.themeKey]

  const foundInTheme = themeKeyArray.find((key) => value in theme[key])

  if (!foundInTheme) return value

  const themeSection = theme[foundInTheme]

  return themeSection[value as keyof typeof themeSection]
}
