import React, { VoidFunctionComponent } from 'react'

import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg'

export const CheckboxIcon: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 30 30" data-testid="checkbox-icon" fill="none" {...props}>
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.72266 13.1697C2.53808 18.2725 2.72266 19.9679 2.72266 24.8048C2.70535 26.0597 3.10722 27.2661 3.83009 28.1291C4.67507 28.8447 5.69103 29.1678 6.70414 29.0433L24.7658 29.4588C25.5129 29.5948 26.2721 29.3495 26.8752 28.7773C27.5081 27.9795 27.4026 25.9184 27.429 25.0707C27.5344 21.3309 27.6531 18.9041 27.7585 15.0978L27.9563 8.44922C28.0975 7.32563 27.8897 6.17668 27.3762 5.24127C26.6405 4.47383 25.6878 4.11947 24.7395 4.2606L7.40288 4.22735C6.43813 4.12109 5.46657 4.27987 4.55519 4.69276C2.9336 5.59032 2.72266 10.3576 2.72266 13.1697Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </Svg>
)
