import React, { VoidFunctionComponent } from 'react'

import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg'

export const FishIcon: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 21 20" {...props} fill="none">
    <path
      d="M17.56 9.7185C18.0913 10.4745 18.3197 11.4023 18.2 12.3185C18.09 13.8285 17.4 14.1485 17.4 14.1485C16.9711 14.0268 16.5903 13.7752 16.31 13.4285C15.8935 12.8856 15.5317 12.3027 15.23 11.6885C14.2158 12.8637 12.8816 13.7184 11.39 14.1485C9.99553 14.5428 8.54829 14.7182 7.1 14.6685C7.22181 15.4068 7.22181 16.1602 7.1 16.8985C6.96494 17.6978 6.75055 18.4817 6.46 19.2385L5.21 15.6185L1 13.9685C1.76331 13.5705 2.60104 13.3355 3.46 13.2785C4.27139 13.2887 5.07934 13.386 5.87 13.5685C5.9316 12.3248 6.18109 11.0975 6.61 9.9285C7.16348 8.74068 7.84055 7.61446 8.63 6.5685L5.69 5.2485C6.37404 4.41448 7.32996 3.84801 8.39 3.6485C9.15273 3.49888 9.93727 3.49888 10.7 3.6485C12.1612 2.6004 13.8014 1.82774 15.54 1.3685C16.7852 0.979235 18.1061 0.896895 19.39 1.1285C19.6624 1.99739 19.7543 2.9128 19.66 3.8185C19.48 5.4485 19.22 8.0685 15.54 8.1685C14.5848 8.25005 13.6393 7.92573 12.9353 7.27498C12.2313 6.62424 11.8337 5.70719 11.84 4.7485"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)
