import React, { FunctionComponent } from 'react'
import { Box, BoxProps } from '@mindfulchefuk/design-system/layout/Box'

export const ModalMobileCTA: FunctionComponent<BoxProps> = (props) => (
  <Box
    position={['fixed', 'static']}
    zIndex="modal"
    background={['white', 'inherit']}
    borderTopWidth={[1, 0]}
    borderColor="pepper-50"
    px={[24, 0]}
    py={[12, 0]}
    bottom={0}
    left={0}
    right={0}
    {...props}
  />
)
