import React, { ReactElement } from 'react'

import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg/Svg'

export const VeggieIcon = (props: BoxProps): ReactElement => (
  <Svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.3623 17.5H9.2669C8.81727 17.5 8.4956 17.2824 8.31025 16.8507L3.83915 5.46305C3.57368 4.75991 3.31658 4.51477 2.93392 4.3067L2.57996 4.11896C2.29178 3.96351 2 3.74109 2 3.26038V2.90283C2.0012 2.37189 2.37189 2.0012 2.90403 2.0012H7.56049C8.09142 2.0012 8.46332 2.37189 8.46332 2.90283V3.26038C8.46332 3.79251 8.12251 4.01015 7.86542 4.12853L7.49352 4.28159C7.1587 4.42389 7.15152 4.42628 7.2998 4.81133L10.2546 12.6642L13.1078 4.87949C13.237 4.53032 13.2214 4.41911 12.7754 4.21224L12.4322 4.06994C12.1619 3.94557 11.8199 3.72794 11.8199 3.197V2.90164C11.8199 2.35396 12.1751 2 12.7228 2H16.5996C17.1269 2 17.4809 2.36113 17.4809 2.90164V3.23885C17.4809 3.66934 17.3027 3.94318 16.9033 4.12733L16.5768 4.29116C16.0794 4.52195 15.8893 4.73121 15.686 5.26813L13.9461 9.88273C13.8731 10.0777 13.6579 10.1769 13.463 10.1004C13.2681 10.0274 13.1712 9.81099 13.2441 9.61607L14.984 5.00147C15.2555 4.28398 15.5879 3.92166 16.2516 3.61314L16.5792 3.44931C16.7299 3.37996 16.7299 3.3668 16.7299 3.23766V2.90044C16.7299 2.74977 16.6869 2.74977 16.5996 2.74977H12.7228C12.5924 2.74977 12.5721 2.77129 12.5721 2.90044V3.1958C12.5721 3.27831 12.5721 3.30701 12.7323 3.38115L13.0767 3.52345C13.8683 3.89056 14.0908 4.38682 13.8121 5.13898L10.7784 13.4199C10.5871 13.9413 9.92818 13.9437 9.73565 13.4199L6.59786 5.07919C6.30728 4.32584 6.48307 3.89296 7.20414 3.58922L7.56407 3.44094C7.71116 3.37278 7.71116 3.34408 7.71116 3.26038V2.90283C7.71116 2.78445 7.67887 2.75216 7.56049 2.75216H2.90403C2.78564 2.75216 2.75336 2.78564 2.75336 2.90283V3.26038C2.75336 3.3381 2.75336 3.35963 2.93631 3.45649L3.29147 3.64543C3.7949 3.91926 4.19908 4.28638 4.54108 5.1916L9.00621 16.5649C9.07676 16.7299 9.14134 16.749 9.26809 16.749H10.3634C10.501 16.749 10.5739 16.6976 10.6241 16.5685L12.4585 11.7027C12.5326 11.509 12.7491 11.411 12.9416 11.4851C13.1365 11.5581 13.2334 11.7745 13.1604 11.9694L11.3261 16.8363C11.1634 17.2596 10.8131 17.5 10.3634 17.5H10.3623Z"
      fill="currentColor"
    />
  </Svg>
)
