export const cssDixaSelector = '.dixa-messenger-namespace'

export function displayDixaMessenger(showDixaMessanger: boolean) {
  if (typeof document === 'undefined') return

  const { body } = document
  if (!body) return

  const isNotDisabledDixaRoute = !body.classList.contains('dixa-disabled-route')

  if (isNotDisabledDixaRoute) {
    if (showDixaMessanger && body.classList.contains('dixa-hide')) {
      body.classList.remove('dixa-hide')
      return
    }

    if (!showDixaMessanger && !body.classList.contains('dixa-hide')) {
      body.classList.add('dixa-hide')
    }
  }
}
