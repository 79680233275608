import {
  GrowthBook,
  LocalStorageStickyBucketService,
} from '@growthbook/growthbook-react'
import { trackingCallback } from '@mindfulchefuk/context/GrowthBookProvider/trackingCallback'

export const configureGrowthBook = () => {
  return new GrowthBook({
    apiHost: process.env.NEXT_PUBLIC_GROWTHBOOK_API_HOST,
    clientKey: process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY,
    enableDevMode: process.env.NEXT_PUBLIC_GROWTHBOOK_DEV_MODE,
    stickyBucketService: new LocalStorageStickyBucketService(),
    trackingCallback,
  })
}
