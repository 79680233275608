import { SpaceToken } from '../theme/space'
import { CSSPropMapper, ResponsiveProps } from '../types'

export type PaddingProps = ResponsiveProps<{
  padding?: SpaceToken
  paddingLeft?: SpaceToken
  paddingRight?: SpaceToken
  paddingTop?: SpaceToken
  paddingBottom?: SpaceToken
  paddingY?: SpaceToken
  paddingX?: SpaceToken

  p?: SpaceToken
  pl?: SpaceToken
  pr?: SpaceToken
  pt?: SpaceToken
  pb?: SpaceToken
  py?: SpaceToken
  px?: SpaceToken
}>

const mapper: CSSPropMapper<PaddingProps> = {
  padding: { property: 'padding', themeKey: 'space' },
  paddingTop: { property: 'paddingTop', themeKey: 'space' },
  paddingRight: { property: 'paddingRight', themeKey: 'space' },
  paddingBottom: { property: 'paddingBottom', themeKey: 'space' },
  paddingLeft: { property: 'paddingLeft', themeKey: 'space' },
  paddingY: { property: ['paddingTop', 'paddingBottom'], themeKey: 'space' },
  paddingX: { property: ['paddingLeft', 'paddingRight'], themeKey: 'space' },
}

export const padding: CSSPropMapper<PaddingProps> = {
  ...mapper,
  pt: mapper.paddingTop,
  pr: mapper.paddingRight,
  pb: mapper.paddingBottom,
  pl: mapper.paddingLeft,
  p: mapper.padding,
  py: mapper.paddingY,
  px: mapper.paddingX,
}
