import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import { Customer } from '@mindfulchefuk/query/Customer/interfaces'
import {
  updateCustomer,
  UpdateCustomerPayload,
} from '@mindfulchefuk/query/Customer/services'

export const useMutationUpdateCustomer = (
  customer: Customer,
  mutateOptions: UseMutationOptions<
    Customer,
    unknown,
    UpdateCustomerPayload
  > = {}
) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (payload) => updateCustomer(payload, customer),
    onError: () => queryClient.invalidateQueries({ queryKey: ['customer'] }),
    /** Optimistically update the cache straight away */
    onMutate: async (payload) => {
      const { customerId } = customer
      await queryClient.cancelQueries({
        queryKey: ['customer', { customerId }],
      })

      queryClient.setQueryData<Customer>(
        ['customer', { customerId }],
        (previous) => {
          if (!previous) return

          return { ...previous, ...payload.data }
        }
      )
    },
    onSuccess: async (data) => {
      const { customerId } = customer
      queryClient.setQueryData<Customer>(['customer', { customerId }], data)
      queryClient.invalidateQueries(['subscription'])
    },
    ...mutateOptions,
  })
}
