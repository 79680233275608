import React, { VoidFunctionComponent } from 'react'

import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg'

export const GlutenFreeIcon: VoidFunctionComponent<BoxProps> = (props) => (
  <Svg viewBox="0 0 22 20" {...props}>
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g stroke="currentColor">
        <line x1="3.05" y1="18.79" x2="19.49" y2="2.81" />
        <line x1="2.83" y1="2.42" x2="19.7" y2="19.18" />
        <path d="M16.21,15.68c-1-.78-1.06-2.49-.89-3.45a4.57,4.57,0,0,1,1.95-3.07c1.13.61,3.46,3,1.13,6.86" />
        <path d="M17.27,17.12a4.66,4.66,0,0,1-3.54,1.67A5.49,5.49,0,0,1,10,16.73a4.72,4.72,0,0,1,3.05-2.05" />
        <path d="M7.44,7c-1-1.12-.92-4.59,1.29-6.26A5.06,5.06,0,0,1,10,7.7" />
        <path d="M8.32,8.15a3.79,3.79,0,0,1-3.6,1.9A5.72,5.72,0,0,1,.8,8.32,3.63,3.63,0,0,1,4.43,5.57" />
      </g>
    </g>
  </Svg>
)
